import {InventoryItem} from "../../pages/Admin/common";
import {Col, InputNumber, Row, Typography} from "antd";
import React, {useEffect, useState} from "react";


const { Text, Link } = Typography;

interface P {
    title: string,
    defaultValue: InventoryItem,
    onChange(newValue:InventoryItem): void,
    style?: React.CSSProperties
}

/**
 * 表格展示对象
 * @param defaultValue
 * @param onChange
 */
export default function ({title, defaultValue, onChange, style}:P) {

    // 控制是否展示错误提示
    const [errorShow, setErrorShow] = useState(false);

    // 模块值
    const [moduleValue, setModuleValue] = useState(defaultValue);

    // 计算是否展示错误提示
    function calErrorShow(newValue: InventoryItem) : boolean {
        if (newValue.beforeNum !== undefined
            && newValue.duringAdditionNum !== undefined
            && newValue.beforeCloseNum !== undefined
            && newValue.afterNum !== undefined
        ) {
            if (newValue.beforeNum + newValue.duringAdditionNum + newValue.beforeCloseNum !== newValue.afterNum)  {
                return true
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    // 模块内部值发生改变
    const moduleValueChange = (partValue:Partial<InventoryItem>) => {
        let newModuleValue = {
            ...moduleValue,
            ...partValue
        }
        // 周知父组件改变
        onChange(newModuleValue);
        setModuleValue(newModuleValue);
        // 控制是否展示错误提示
        setErrorShow(calErrorShow(newModuleValue));
    }

    return (
        <>
            <Row
                style={{...style}}
                justify={'start'}
                align={'middle'}>
                <Col
                    span={4}
                >
                    <Text>{title}</Text>
                </Col>
                <Col span={5}>
                    <InputNumber
                        min={0}
                        style={{width: "80%"}}
                        defaultValue={moduleValue.beforeNum}
                        changeOnWheel
                        onChange={v => moduleValueChange({beforeNum: v === null ? 0 : v})}
                    />
                </Col>
                <Col span={5}>
                    <InputNumber
                        min={0}
                        style={{width: "80%"}}
                        disabled={moduleValue.beforeNum === undefined || moduleValue.beforeNum === null}
                        changeOnWheel
                        defaultValue={moduleValue.duringAdditionNum}
                        onChange={v => moduleValueChange({duringAdditionNum: v === null ? 0 : v})}
                    />
                </Col>
                <Col span={5}>
                    <InputNumber
                        min={0}
                        style={{width: "80%"}}
                        disabled={moduleValue.beforeNum === undefined || moduleValue.beforeNum === null}
                        defaultValue={moduleValue.beforeCloseNum}
                        changeOnWheel
                        onChange={v => moduleValueChange({beforeCloseNum: v === null ? 0 : v})}
                    />
                </Col>
                <Col span={5}>
                    <InputNumber
                        min={0}
                        style={{width: "80%"}}
                        changeOnWheel
                        disabled={moduleValue.beforeNum === undefined || moduleValue.beforeNum === null}
                        status={errorShow ? "error" : undefined}
                        defaultValue={moduleValue.afterNum}
                        onChange={v => moduleValueChange({afterNum: v === null ? 0 : v})}
                    />
                </Col>
            </Row>
        </>
    )
}