import {StrategySteps} from "../../pages/Admin/common";
import {Col, Row, Select} from "antd";
import React, {useState} from "react";
import TextArea from "antd/es/input/TextArea";

interface P {
    defaultValue?:StrategySteps,
    options: StrategySteps[],
    onChange(newSteps: StrategySteps): void,
}

export default function({defaultValue, options, onChange}:P) {

    // 模块值
    const [moduleValue, setModuleValue] = useState<StrategySteps | undefined>(defaultValue);

    return (
        <>
            <Row align={'middle'} justify={'center'} >
                <Col span={17}>
                    {moduleValue !== undefined
                        && moduleValue !== null
                        && moduleValue.steps.map((e, index) => {
                            return (
                                <TextArea
                                    style={{marginTop: "10px", width: "80%"}}
                                    defaultValue={e}
                                    value={e}
                                    rows={1}
                                    autoSize
                                    onChange={(v) => {
                                        let newSteps = {...moduleValue};
                                        newSteps.steps[index] = v.target.value;
                                        setModuleValue(newSteps);
                                        onChange(newSteps);
                                    }}
                                />
                            )
                        })
                    }
                </Col>
                <Col span={7} >
                    <Select
                        style={{width:"100%"}}
                        options={options.map(e => ({
                            label: e.name,
                            value: e.name
                        }))}
                        onChange={(v) => {
                            let newSteps: StrategySteps = options.find((item) => {
                                return item.name === v
                            })!;
                            setModuleValue(newSteps);
                            onChange(newSteps)
                        }}
                    />
                </Col>
            </Row>
        </>
    )
}