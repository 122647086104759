import {CONFIGS, ConsumableDetailConfig} from "../../common";
import DetailConfig from "../../../../componts/DetailConfig";
import {Card, Col, ConfigProvider, Divider, FloatButton, Row, Typography} from "antd";
import Key from "../Key";
import React from "react";
const {Paragraph} = Typography;
interface P {
    defaultConfig?: ConsumableDetailConfig,
    onChange(newValue: Partial<ConsumableDetailConfig>):void,
    saveAll():void,
}

export default function ({defaultConfig, onChange, saveAll} : P) {

    const spanFullWidth = 24;

    return (
        <>
            <Row
                style={{
                    backgroundColor: "#f0f0f0"
                }}
                gutter={[16, 16]}>
                <Divider>手套部分</Divider>
                <Col span={spanFullWidth}>
                    <DetailConfig
                        title={"7.5#手套配置"}
                        config={defaultConfig?.glovesSevenPointFiveArray}
                        onChange={v => onChange({
                            glovesSevenPointFiveArray: v
                        })}
                    />
                </Col>
                <Col span={spanFullWidth}>
                    <DetailConfig
                        title={"7#手套配置"}
                        config={defaultConfig?.glovesSevenArray}
                        onChange={v => onChange({
                            glovesSevenArray: v
                        })}
                    />
                </Col>
                <Col span={spanFullWidth}>
                    <DetailConfig
                        title={"6.5#手套配置"}
                        config={defaultConfig?.glovesSixPointFiveArray}
                        onChange={v => onChange({
                            glovesSixPointFiveArray: v
                        })}
                    />
                </Col>
                <Divider >输液器部分</Divider>
                <Col span={spanFullWidth}>
                    <DetailConfig
                        title={"输液器配置"}
                        config={defaultConfig?.syringeArray}
                        onChange={v => onChange({
                            syringeArray: v
                        })}
                    />
                </Col>
                <Divider >刀片部分配置</Divider>
                <Col span={spanFullWidth}>
                    <DetailConfig
                        title={"刀片11#配置"}
                        config={defaultConfig?.bladeElevenArray}
                        onChange={v => onChange({
                            bladeElevenArray: v
                        })}
                    />
                </Col>
                <Col span={spanFullWidth}>
                    <DetailConfig
                        title={"刀片15#配置"}
                        config={defaultConfig?.bladeFifteenArray}
                        onChange={v => onChange({
                            bladeFifteenArray: v
                        })}
                    />
                </Col>
                <Divider >注射器部分配置</Divider>
                <Col span={spanFullWidth}>
                    <DetailConfig
                        title={"注射器1ml配置"}
                        config={defaultConfig?.syringeOneMArray}
                        onChange={v => onChange({
                            syringeOneMArray: v
                        })}
                    />
                </Col>
                <Col span={spanFullWidth}>
                    <DetailConfig
                        title={"注射器2.5ml配置"}
                        config={defaultConfig?.syringeTwoPointFiveMArray}
                        onChange={v => onChange({
                            syringeTwoPointFiveMArray: v
                        })}
                    />
                </Col>
                <Col span={spanFullWidth}>
                    <DetailConfig
                        title={"注射器5ml配置"}
                        config={defaultConfig?.syringeFiveMArray}
                        onChange={v => onChange({
                            syringeFiveMArray: v
                        })}
                    />
                </Col>
                <Col span={spanFullWidth}>
                    <DetailConfig
                        title={"注射器20ml配置"}
                        config={defaultConfig?.syringeTwentyMArray}
                        onChange={v => onChange({
                            syringeTwentyMArray: v
                        })}
                    />
                </Col>
                <Col span={spanFullWidth}>
                    <DetailConfig
                        title={"注射器50ml配置"}
                        config={defaultConfig?.syringeFiftyMArray}
                        onChange={v => onChange({
                            syringeFiftyMArray: v
                        })}
                    />
                </Col>
                <Divider >螺旋注射器</Divider>
                <Col span={spanFullWidth}>
                    <DetailConfig
                        title={"螺旋注射器1ml配置"}
                        config={defaultConfig?.lueLockSyringeOneArray}
                        onChange={v => onChange({
                            lueLockSyringeOneArray: v
                        })}
                    />
                </Col>
                <Col span={spanFullWidth}>
                    <DetailConfig
                        title={"螺旋注射器20ml配置"}
                        config={defaultConfig?.lueLockSyringeTwentyArray}
                        onChange={v => onChange({
                            lueLockSyringeTwentyArray: v
                        })}
                    />
                </Col>
                <Col span={spanFullWidth}>
                    <DetailConfig
                        title={"螺旋注射器50ml配置"}
                        config={defaultConfig?.lueLockSyringeFiftyArray}
                        onChange={v => onChange({
                            lueLockSyringeFiftyArray: v
                        })}
                    />
                </Col>
                <Divider >针头</Divider>
                <Col span={spanFullWidth}>
                    <DetailConfig
                        title={"针头20ml配置"}
                        config={defaultConfig?.needleTwentyArray}
                        onChange={v => onChange({
                            needleTwentyArray: v
                        })}
                    />
                </Col>
                <Divider >氧气管配置</Divider>
                <Col span={spanFullWidth}>
                    <DetailConfig
                        title={"氧气管配置"}
                        config={defaultConfig?.oxygenTubeArray}
                        onChange={v => onChange({
                            oxygenTubeArray: v
                        })}
                    />
                </Col>
                <Divider >线材配置</Divider>
                <Col span={spanFullWidth}>
                    <DetailConfig
                        title={"线材配置"}
                        config={defaultConfig?.surgicalSutureArray}
                        onChange={v => onChange({
                            surgicalSutureArray: v
                        })}
                    />
                </Col>
                <Col span={spanFullWidth}>
                    <Card title="动态行物品名称选项">
                        <Key
                            values={defaultConfig!.goodNamesArray!}
                            fatherChange={v => onChange({
                                goodNamesArray: v
                            })}
                            saveAll={saveAll}
                        />
                    </Card>
                </Col>
                <Col span={spanFullWidth}>
                    <Card title="动态行规格选项">
                        <Key
                            values={defaultConfig!.specificationNameArray!}
                            fatherChange={v => onChange({
                                specificationNameArray: v
                            })}
                            saveAll={saveAll}
                        />
                    </Card>
                </Col>
                <Col span={spanFullWidth}>
                    <DetailConfig
                        title={"动态行配置"}
                        config={defaultConfig?.dynamicRowArray}
                        onChange={v => onChange({
                            dynamicRowArray: v
                        })}
                    />
                </Col>
            </Row>
            <FloatButton
                type={'primary'}
                tooltip={"保存所有配置"}
                onClick={saveAll}
            />
        </>
    )
}