import {EditOptions, OutPatientProfile} from "../../common";
import {Row, Col, Button, Input, DatePicker, Typography, Select, AutoComplete, Switch, InputNumber} from 'antd';
import React, {useState} from "react";
import {NationList, outPatientDownLoad, recipesDownload, YMDFormat} from "../common";
import SelectAdd from "../ProfileView/SelectAdd";

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Text, Link } = Typography


interface P {
    totalProfiles: OutPatientProfile
    options: EditOptions
    fatherChange: (newValue: OutPatientProfile) => void
    saveDraft: () => void
}

export default function ({totalProfiles, options, fatherChange, saveDraft} : P) {

    /**
     * 是否设置处方可用
     */
    const [isAllowRecipes, setIsAllowRecipes] = useState(false);

    /**
     * 翻转处方是否可用
     * @param currentStatus
     */
    function triggerIsAllowRecipes() {
        setIsAllowRecipes(!isAllowRecipes);
    }

    return (
        <>
            <Row className="rowClass" justify="start" align="middle">
                <Col span={1}>
                    <div>
                        姓名：
                    </div>
                </Col>
                <Col span={3}>
                    <Input
                        style={{maxWidth:"10vw"}}
                        defaultValue={totalProfiles.name}
                        value={totalProfiles.name}
                        onChange={(e) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.name = e.target.value;
                            fatherChange(newTotalProfile)
                        }}
                    />
                </Col>
                <Col span={1} >
                    性别
                </Col>
                <Col span={3} >
                    <Input
                        style={{maxWidth:"10vw"}}
                        defaultValue={totalProfiles.gender}
                        onChange={(e) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.gender = e.target.value;
                            fatherChange(newTotalProfile)
                        }}
                    />
                </Col>
                <Col span={1} >
                    年龄
                </Col>
                <Col span={3} >
                    <InputNumber
                        changeOnWheel
                        value={totalProfiles.age}
                        style={{maxWidth:"10vw"}}
                        // defaultValue={calAge(totalProfiles.birthday, new Date())}
                        onChange={(e:number | null) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.age = e === null ? 0 : e
                            fatherChange(newTotalProfile)
                        }}
                    />
                </Col>
                <Col span={1} >
                    病历号
                </Col>
                <Col span={3} >
                    <Input
                        style={{maxWidth:"10vw"}}
                        defaultValue={totalProfiles.profileId}
                        onChange={(e) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.profileId = e.target.value;
                            fatherChange(newTotalProfile)
                        }}
                    />
                </Col>
                <Col span={1}>
                    民族
                </Col>
                <Col span={3}>
                    <AutoComplete
                        options={NationList.map((item) => ({value: item}))}
                        defaultValue={totalProfiles.nation}
                        style={{width:"100%"}}
                        onSelect={(e) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.nation = e;
                            fatherChange(newTotalProfile);
                        }}
                        onSearch={(value) => {
                            fatherChange({...totalProfiles, nation: value})
                        }}
                    />
                </Col>
            </Row>
            <Row className="rowClass" justify="start" align="middle">
                <Col span={1} >
                    临床诊断
                </Col>
                <Col span={10} >
                    <AutoComplete
                        options={options.beforeDiagnosis.map(item => ({value: item}))}
                        style={{width: "100%"}}
                        onSelect={(value: string) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.clinicalDiagnosis = value;
                            fatherChange(newTotalProfile)
                        }}
                        value={totalProfiles.clinicalDiagnosis}
                    >
                        <TextArea
                            autoSize
                            // placeholder={totalProfiles.clinicalDiagnosis}
                            // defaultValue={totalProfiles.clinicalDiagnosis}
                            // value={totalProfiles.clinicalDiagnosis}
                            onChange={(v) => {
                                let newTotalProfile = totalProfiles;
                                newTotalProfile.clinicalDiagnosis = v.target.value;
                                fatherChange(newTotalProfile)
                            }}
                        />
                    </AutoComplete>
                </Col>
            </Row>
            <Row className="rowClass" justify="start" align="middle">
                <Col span={1} >
                    主诉
                </Col>
                <Col span={10} >
                    <AutoComplete
                        options={options.principalComplaint.map(item => ({value: item}))}
                        style={{width: "100%"}}
                        value={totalProfiles.principalComplaint}
                        onSelect={(value: string) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.principalComplaint = value;
                            fatherChange(newTotalProfile)
                        }}
                    >
                        <TextArea
                            autoSize
                            placeholder={totalProfiles.principalComplaint}
                            defaultValue={totalProfiles.principalComplaint}
                            onChange={(v) => {
                                let newTotalProfile = totalProfiles;
                                newTotalProfile.principalComplaint = v.target.value;
                                fatherChange(newTotalProfile)
                            }}
                        />
                    </AutoComplete>
                </Col>
            </Row>
            <Row className="rowClass" justify="start" align="middle">
                <Col span={1} >
                    现病史
                </Col>
                <Col span={10} >
                    <AutoComplete
                        options={options.presentDiseaseHistory.map(item => ({value: item}))}
                        style={{width: "100%"}}
                        value={totalProfiles.presentDiseaseHistory}
                        onSelect={(value: string) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.presentDiseaseHistory = value;
                            fatherChange(newTotalProfile)
                        }}
                    >
                        <TextArea
                            autoSize
                            placeholder={totalProfiles.presentDiseaseHistory}
                            defaultValue={totalProfiles.presentDiseaseHistory}
                            onChange={(v) => {
                                let newTotalProfile = totalProfiles;
                                newTotalProfile.presentDiseaseHistory = v.target.value;
                                fatherChange(newTotalProfile)
                            }}
                        />
                    </AutoComplete>
                </Col>
            </Row>
            <Row className="rowClass" justify="start" align="middle">
                <Col span={1} >
                    既往史
                </Col>
                <Col span={10} >
                    <AutoComplete
                        options={options.pastDiseaseHistory.map(item => ({value: item}))}
                        style={{width: "100%"}}
                        value={totalProfiles.pastDiseaseHistory}
                        onSelect={(value: string) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.pastDiseaseHistory = value;
                            fatherChange(newTotalProfile)
                        }}
                    >
                        <TextArea
                            autoSize
                            placeholder={totalProfiles.pastDiseaseHistory}
                            defaultValue={totalProfiles.pastDiseaseHistory}
                            onChange={(v) => {
                                let newTotalProfile = totalProfiles;
                                newTotalProfile.pastDiseaseHistory= v.target.value;
                                fatherChange(newTotalProfile)
                            }}
                        />
                    </AutoComplete>
                </Col>
            </Row>
            <Row className="rowClass" justify="start" align="middle">
                <Col span={1} >
                    过敏史
                </Col>
                <Col span={10} >
                    <AutoComplete
                        options={options.allergicHistory.map(item => ({value: item}))}
                        style={{width: "100%"}}
                        value={totalProfiles.allergicHistory}
                        onSelect={(value: string) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.allergicHistory = value;
                            fatherChange(newTotalProfile)
                        }}
                    >
                        <TextArea
                            autoSize
                            placeholder={totalProfiles.allergicHistory}
                            defaultValue={totalProfiles.allergicHistory}
                            onChange={(v) => {
                                let newTotalProfile = totalProfiles;
                                newTotalProfile.allergicHistory = v.target.value;
                                fatherChange(newTotalProfile)
                            }}
                        />
                    </AutoComplete>
                </Col>
            </Row>
            <Row className="rowClass" justify="start" align="middle">
                <Col span={1} >
                    个人史
                </Col>
                <Col span={10} >
                    <AutoComplete
                        options={options.personHistory.map(item => ({value: item}))}
                        style={{width: "100%"}}
                        value={totalProfiles.personHistory}
                        onSelect={(value: string) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.personHistory = value;
                            fatherChange(newTotalProfile)
                        }}
                    >
                        <TextArea
                            autoSize
                            placeholder={totalProfiles.personHistory}
                            defaultValue={totalProfiles.personHistory}
                            onChange={(v) => {
                                let newTotalProfile = totalProfiles;
                                newTotalProfile.personHistory = v.target.value;
                                fatherChange(newTotalProfile)
                            }}
                        />
                    </AutoComplete>
                </Col>
            </Row>
            <Row className="rowClass" justify="start" align="middle">
                <Col span={1} >
                    家族史
                </Col>
                <Col span={10} >
                    <AutoComplete
                        value={totalProfiles.familyHistory}
                        options={options.familyHistory.map(item => ({value: item}))}
                        style={{width: "100%"}}
                        onSelect={(value: string) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.familyHistory = value;
                            fatherChange(newTotalProfile)
                        }}
                    >
                        <TextArea
                            autoSize
                            placeholder={totalProfiles.familyHistory}
                            defaultValue={totalProfiles.familyHistory}
                            onChange={(v) => {
                                let newTotalProfile = totalProfiles;
                                newTotalProfile.familyHistory = v.target.value;
                                fatherChange(newTotalProfile)
                            }}
                        />
                    </AutoComplete>
                </Col>
            </Row>
            <Row className="rowClass" justify="start" align="middle">
                <Col span={1} >
                    体格检查
                </Col>
                <Col span={10} >
                    <AutoComplete
                        value={totalProfiles.physicalExamination}
                        options={options.physicalExamination.map(item => ({value: item}))}
                        style={{width: "100%"}}
                        onSelect={(value: string) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.physicalExamination = value;
                            fatherChange(newTotalProfile)
                        }}
                    >
                        <TextArea
                            autoSize
                            placeholder={totalProfiles.physicalExamination}
                            defaultValue={totalProfiles.physicalExamination}
                            onChange={(v) => {
                                let newTotalProfile = totalProfiles;
                                newTotalProfile.physicalExamination = v.target.value;
                                fatherChange(newTotalProfile)
                            }}
                        />
                    </AutoComplete>
                </Col>
            </Row>
            <Row className="rowClass" justify="start" align="middle">
                <Col span={1} >
                    治疗意见
                </Col>
                <Col span={10} >
                    <AutoComplete
                        value={totalProfiles.treatmentAdvice}
                        options={options.treatmentAdvice.map(item => ({value: item}))}
                        style={{width: "100%"}}
                        onSelect={(value: string) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.treatmentAdvice = value;
                            fatherChange(newTotalProfile)
                        }}
                    >
                        <TextArea
                            autoSize
                            placeholder={totalProfiles.treatmentAdvice}
                            defaultValue={totalProfiles.treatmentAdvice}
                            onChange={(v) => {
                                let newTotalProfile = totalProfiles;
                                newTotalProfile.treatmentAdvice = v.target.value;
                                fatherChange(newTotalProfile)
                            }}
                        />
                    </AutoComplete>
                </Col>
            </Row>
            <Row className="rowClass" justify="start" align="middle">
                <Col span={1} >
                    医师
                </Col>
                <Col span={3} >
                    <SelectAdd
                        options={options.operator}
                        defaultValue={totalProfiles.doctor}
                        value={totalProfiles.doctor}
                        fatherChange={(v) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.doctor = v;
                            fatherChange(newTotalProfile)
                        }}
                    />
                </Col>
                <Col span={1}>
                    记录时间
                </Col>
                <Col span={3}>
                    <Text >
                        {totalProfiles.createAt}
                    </Text>
                </Col>
                <Col span={3}>
                    <DatePicker
                        style={{width:"100%"}}
                        showTime={{ format: 'HH:mm' }}
                        onChange={(value, dateStr) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.createAt = YMDFormat(dateStr);
                            let newDate;
                            console.log(dateStr)
                            debugger
                            if (typeof dateStr === 'string') {
                                newDate = dateStr;
                            } else {
                                newDate = dateStr[0]
                            }
                            // newTotalProfile.age = CalAge(totalProfiles.birthday, new Date(newDate));
                            fatherChange(newTotalProfile);
                        }}
                    />
                </Col>
            </Row>
            <Row
                className="rowClass"
                align="middle"
                justify="start"
            >
                <Col span={2}>
                    <span>
                        处方笺<Switch checked={!isAllowRecipes} onChange={triggerIsAllowRecipes}/>
                    </span>
                </Col>
                <Col span={10} >
                    {totalProfiles.recipes.steps.map((e, index) => {
                        return (
                            <TextArea
                                disabled={isAllowRecipes}
                                style={{marginTop: "10px"}}
                                defaultValue={e}
                                value={e}
                                rows={1}
                                onChange={(v) => {
                                    let newTotalProfile = totalProfiles;
                                    newTotalProfile.recipes.steps[index] = v.target.value;
                                    fatherChange(newTotalProfile)
                                }}
                            />
                        )
                    })}
                </Col>
                <Col span={1} >
                    <Select
                        disabled={isAllowRecipes}
                        style={{width:"200px"}}
                        options={options.recipes.map(e => ({
                            label: e.name,
                            value: e.name
                        }))}
                        onChange={(v) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.recipes = options.recipes.find((item) => {
                                return item.name === v
                            })!;
                            fatherChange(newTotalProfile)
                        }}
                    />
                </Col>
            </Row>
            <Row className="rowClass" justify="center" align="middle">
                <Col span={12}>
                    <Button
                        type={"primary"}
                        onClick={() => {
                            outPatientDownLoad(totalProfiles)
                        }}
                    >
                        下载门诊病历
                    </Button>
                    <Button
                        style={{
                            marginLeft:"5px",
                            backgroundColor: "#00ff37"
                        }}
                        onClick={saveDraft}
                    >
                        保存草稿-{totalProfiles.name}
                    </Button>
                </Col>
                <Col span={12}>
                    <Button
                        disabled={isAllowRecipes}
                        type={"primary"}
                        onClick={() => {
                            recipesDownload(totalProfiles)
                        }}
                    >
                        下载处方笺
                    </Button>
                </Col>
            </Row>
        </>
    )
}
