
import {DATE_FORMAT} from "../common";
import dayjs from "dayjs";

export const spanGood = 3;
export const spanFormat = 3;
export const spanNum = 2;
export const spanManufacturer = 4;
export const spanProductionBatchNumStr = 4;
export const spanProductionDateStr = 4;
export const spanExpirationDateStr = 4;

/**
 * 综合处理时间数据
 * @param dateStr
 */
export function formatDateStrInput(dateStr: string | undefined | null, FORMAT? : string) : undefined | dayjs.Dayjs {
    if ("" === dateStr || dateStr === null || dateStr === undefined) {
        return undefined;
    } else {
        if (FORMAT === undefined) {
            return dayjs(dateStr, DATE_FORMAT);
        } else {
            return dayjs(dateStr, FORMAT)
        }
    }
}