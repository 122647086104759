import {Col,  Row, Typography} from "antd";
import React from "react";
interface P{
    style?:React.CSSProperties
}

const { Text, Link } = Typography;

/**
 * 手术用品清点表头
 * @param style
 */
export default function ({style}:P) {
    return (<>
        <Row
            style={{...style}}
            justify={'start'}
            align={'middle'}>
            <Col
                span={4}
            >
                <Text >品名</Text>
            </Col>
            <Col span={5}>
                <Text >术前清点</Text>
            </Col>
            <Col span={5}>
                <Text >术中添加</Text>

            </Col>
            <Col span={5}>
                <Text >关前清点</Text>
            </Col>
            <Col span={5}>
                <Text >术毕清点</Text>
            </Col>
        </Row>

    </>)
}