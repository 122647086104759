import React, {useState} from "react";
import { Layout, Menu, theme, Form, Input, Button, Divider, Typography, Row, Col, Card, message} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import handleAxios from "../../Http/request";
import "./index.css"
import {BasicResponse} from "../../Http/common";

const { Title, Paragraph, Text, Link } = Typography;
const { Header, Footer, Content } = Layout;
export default () => {

    const [messageApi, contextHolder] = message.useMessage();

    const [userLogin, setUserLogin] = useState({
        username:"",
        password:"",
    });

    /**
     * 提交密码登录
     */
    const onFinish = async () => {
        if (userLogin.username === "" || userLogin.password === "") {
            messageApi.error("请输入用户名和密码")
            return;
        }
        let ret: Promise<BasicResponse> = handleAxios.post("/api/loginUp", {
            "name": userLogin.username,
            "password": userLogin.password,
        })
        ret.then((res) => {
            if (res.code === 0) {
                messageApi.open({
                    type: "success",
                    content: res.data
                })
                window.location.href="/admin"
            } else {
                messageApi.open({
                    type: "error",
                    content:res.data
                })
            }
        })
    };
    return (
        <Layout className="layout">
            <Content className="body">
                {contextHolder}
                <div
                    className="loginText"
                >
                    <Title style={{color:"#159e85"}}>
                        电子化病历系统
                    </Title>
                </div>
                <div className="loginBody" onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                        onFinish()
                    }
                }}>
                    <Card
                        bodyStyle={{
                            // maxWidth:"600px",
                            // width:"600px",
                            backgroundColor:"#fafafa"
                        }}
                        style={{
                            // position:"float",
                            float:"right",
                            width:"600px",
                            marginTop: "30vh",
                            marginRight: "15vw",
                        // width: "20wv"
                    }}>
                        <Row justify="center" align="middle" style={{marginTop: "20px"}}>
                            <Col span={4}>
                                    用户名：
                            </Col>
                            <Col span={20}>
                                <Input
                                    prefix={<UserOutlined />}
                                    size="large"
                                    style={{minHeight:"100%"}}
                                    onChange={e=> {
                                        setUserLogin({
                                            ...userLogin,
                                            username: e.target.value
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row justify="center" align="middle" style={{marginTop: "20px"}}>
                            <Col span={4}>
                                    密码：
                            </Col>
                            <Col span={20}>
                                <Input.Password
                                    size="large"
                                    style={{minHeight:"100%"}}
                                    onChange={e => {
                                        setUserLogin({
                                            ...userLogin,
                                            password: e.target.value
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row justify="center" align="middle" style={{marginTop: "20px"}}>
                            <Col>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        onFinish()
                                    }}
                                >
                                    登 录
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </div>
            </Content>

        </Layout>

    )
}
