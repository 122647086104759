import React, {useEffect, useState} from 'react'
import {Tabs, Card, Typography, message} from 'antd';
import type {TabsProps} from 'antd';
import {FrownTwoTone, SmileTwoTone, ProfileTwoTone,
UserOutlined, UserAddOutlined
} from '@ant-design/icons'
import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

import "./index.css"
import {Inject} from "./common";
import Key from "./Key";
import CardList from "./CardList";
import {EditOptions} from "../common";
import {BasicDataResponse, BasicResponse} from "../../../Http/common";
import handleAxios from "../../../Http/request";
import ConsumableDetailConfig from "./ConsumableDetailConfig";

const {Paragraph} = Typography;

const InjectIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={Inject} {...props} />
);

export default function () {

    /**
     * 对话框提醒消息
     */
    const [messageApi, contextHolder] = message.useMessage();

    /**
     * 设置加载中
     */
    const [loading, setLoading] = useState(true);

    /**
     * 选项列表
     */
    const [originOptions, setOriginOptions] = useState<EditOptions>({
        beforeDiagnosis: [],
        afterDiagnosis: [],
        anesthesiologist: [],
        anestheticMode: [],
        firstAssistant: [],
        handWashingNurse: [],
        operator: [],
        rovingNurse: [],
        steps: [],
        strategyName: [],
        allergicHistory: [],
        familyHistory: [],
        pastDiseaseHistory: [],
        personHistory: [],
        physicalExamination: [],
        presentDiseaseHistory: [],
        principalComplaint: [],
        treatmentAdvice: [],
        recipes: [],    //处方笺
        consumableDetails: {
            bladeElevenArray: [],
            bladeFifteenArray: [],
            glovesSevenArray: [],
            glovesSevenPointFiveArray: [],
            glovesSixPointFiveArray: [],
            lueLockSyringeFiftyArray: [],
            lueLockSyringeOneArray: [],
            lueLockSyringeTwentyArray: [],
            needleTwentyArray: [],
            oxygenTubeArray: [],
            surgicalSutureArray: [],
            syringeArray: [],
            syringeTwentyMArray: [],
            syringeFiftyMArray: [],
            syringeFiveMArray: [],
            syringeOneMArray: [],
            syringeTwoPointFiveMArray: [],
            dynamicRowArray: [],
            goodNamesArray: [],
            specificationNameArray: []

        }
    });


    /**
     * 拉取所有的配置选项
     */
    const getAllConfigs = async () => {
        setLoading(true);
        let ret: Promise<BasicDataResponse<EditOptions>> = handleAxios.get("/api/getAllConfigs");
        ret.then((res) => {
            if (res.code === 0) {
                setOriginOptions(res.data);
                setLoading(false);
            } else {
                messageApi.open({
                    type:"error",
                    content: "拉取配置选项出错！"
                })
            }
        }).catch((error) => {
            // 只要发生异常，就返回重定向页面，大概率是session过期了
            messageApi.error("用户信息失效，请重新登陆");
            window.location.href="/login";
        })
    }
    useEffect(() => {
        getAllConfigs();
    }, [])
    /**
     * 页面初始化拉取配置数据
     */
    // const noUse = getAllConfigs();

    /**
     * 存储所有的配置信息
     */
    const saveAll = async () => {
        let ret: Promise<BasicResponse> = handleAxios.post("/api/updateConfigs",
            {
                ...originOptions,
                id:1
            }
        )
        ret.then((res) => {
            if (res.code === 0) {
                messageApi.open({
                    type: "success",
                    content: res.data
                })
            } else {
                messageApi.open({
                    type: "error",
                    content:res.data
                })
            }
        })
    };

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: (
                <>
                    <Paragraph >
                        <FrownTwoTone
                            className="iconSize"
                        />术前诊断
                    </Paragraph>
                </>),
            children:
                <Key
                    values={originOptions.beforeDiagnosis}
                    fatherChange={(newValues) => {
                        setOriginOptions({
                        ...originOptions,
                        beforeDiagnosis: newValues,
                    })}}
                    saveAll={saveAll}
                />,
        },
        {
            key: '2',
            label: (
                <>
                    <Paragraph >
                        <SmileTwoTone
                            className="iconSize"
                        />
                        术后诊断
                    </Paragraph>
                </>
            ),
            children:
                <Key
                    values={originOptions.afterDiagnosis}
                    fatherChange={(newValues) => {
                        setOriginOptions({
                            ...originOptions,
                            afterDiagnosis: newValues,
                        })}}
                    saveAll={saveAll}
                />,
        },
        {
            key: '3',
            label: (
                <>
                    <Paragraph >
                        手术名称
                    </Paragraph>
                </>
            ),
            children:
                <Key
                    values={originOptions.strategyName}
                    fatherChange={(newValues) => {
                        setOriginOptions({
                            ...originOptions,
                            strategyName: newValues,
                        })}}
                    saveAll={saveAll}
                />,
        },
        {
            key: '4',
            label: (
                <>
                    <Paragraph >
                        <Icon
                            className="iconSize"
                            component={InjectIcon as React.ForwardRefExoticComponent<any>}
                        />
                        麻醉方式
                    </Paragraph>
                </>
            ),
            children:
                <Key
                    values={originOptions.anestheticMode}
                    fatherChange={(newValues) => {
                        setOriginOptions({
                            ...originOptions,
                            anestheticMode: newValues,
                        })}}
                    saveAll={saveAll}
                />,
        },
        {
            key: '5',
            label: (
                <>
                    <Paragraph >
                        <UserOutlined
                            className="iconSize"
                        />
                        手术医师
                    </Paragraph>
                </>
            ),
            children:
                <Key
                    values={originOptions.operator}
                    fatherChange={(newValues) => {
                        setOriginOptions({
                            ...originOptions,
                            operator: newValues,
                        })}}
                    saveAll={saveAll}
                />,
        },
        {
            key: '6',
            label: (
                <>
                    <Paragraph >
                        <UserAddOutlined
                            className="iconSize"
                        />
                        一助
                    </Paragraph>
                </>
            ),
            children:
                <Key
                    values={originOptions.firstAssistant}
                    fatherChange={(newValues) => {
                        setOriginOptions({
                            ...originOptions,
                            firstAssistant: newValues,
                        })}}
                    saveAll={saveAll}
                />,
        },
        {
            key: '7',
            label: (
                <>
                    <Paragraph>
                        麻醉医师
                    </Paragraph>
                </>
            ),
            children:
                <Key
                    values={originOptions.anesthesiologist}
                    fatherChange={(newValues) => {
                        setOriginOptions({
                            ...originOptions,
                            anesthesiologist: newValues,
                        })}}
                    saveAll={saveAll}
                />,
        },
        {
            key: '8',
            label: (
                <>
                    <Paragraph>
                        洗手护士
                    </Paragraph>
                </>
            ),
            children:
                <Key
                    values={originOptions.handWashingNurse}
                    fatherChange={(newValues) => {
                        setOriginOptions({
                            ...originOptions,
                            handWashingNurse: newValues,
                        })}}
                    saveAll={saveAll}
                />,
        },
        {
            key: '9',
            label: (
                <>
                    <Paragraph>
                        巡回护士
                    </Paragraph>
                </>
            ),
            children:
                <Key
                    values={originOptions.rovingNurse}
                    fatherChange={(newValues) => {
                        setOriginOptions({
                            ...originOptions,
                            rovingNurse: newValues,
                        })}}
                    saveAll={saveAll}
                />,
        },
        {
            key: '10',
            label: (
                <>
                    <Paragraph >
                        <ProfileTwoTone
                            className="iconSize"
                        />
                        手术步骤
                    </Paragraph>
                </>
            ),
            children:
                <CardList
                    values={originOptions.steps}
                    fatherChange={(newValues) => {
                        setOriginOptions({
                            ...originOptions,
                            steps: newValues,
                        })}}
                    cardName={'手术'}
                    saveAll={saveAll}
                />,
        },
        {
            key: '11',
            label: (
                <>
                    <Paragraph>
                        主诉
                    </Paragraph>
                </>
            ),
            children:
                <Key
                    values={originOptions.principalComplaint}
                    fatherChange={(newValues) => {
                        setOriginOptions({
                            ...originOptions,
                            principalComplaint: newValues,
                        })}}
                    saveAll={saveAll}
                />,
        },
        {
            key: '12',
            label: (
                <>
                    <Paragraph>
                        现病史
                    </Paragraph>
                </>
            ),
            children:
                <Key
                    values={originOptions.presentDiseaseHistory}
                    fatherChange={(newValues) => {
                        setOriginOptions({
                            ...originOptions,
                            presentDiseaseHistory: newValues,
                        })}}
                    saveAll={saveAll}
                />,
        },
        {
            key: '13',
            label: (
                <>
                    <Paragraph>
                        既往史
                    </Paragraph>
                </>
            ),
            children:
                <Key
                    values={originOptions.pastDiseaseHistory}
                    fatherChange={(newValues) => {
                        setOriginOptions({
                            ...originOptions,
                            pastDiseaseHistory: newValues,
                        })}}
                    saveAll={saveAll}
                />,
        },
        {
            key: '14',
            label: (
                <>
                    <Paragraph>
                        过敏史
                    </Paragraph>
                </>
            ),
            children:
                <Key
                    values={originOptions.allergicHistory}
                    fatherChange={(newValues) => {
                        setOriginOptions({
                            ...originOptions,
                            allergicHistory: newValues,
                        })}}
                    saveAll={saveAll}
                />,
        },
        {
            key: '15',
            label: (
                <>
                    <Paragraph>
                        个人史
                    </Paragraph>
                </>
            ),
            children:
                <Key
                    values={originOptions.personHistory}
                    fatherChange={(newValues) => {
                        setOriginOptions({
                            ...originOptions,
                            personHistory: newValues,
                        })}}
                    saveAll={saveAll}
                />,
        },
        {
            key: '16',
            label: (
                <>
                    <Paragraph>
                        家族史
                    </Paragraph>
                </>
            ),
            children:
                <Key
                    values={originOptions.familyHistory}
                    fatherChange={(newValues) => {
                        setOriginOptions({
                            ...originOptions,
                            familyHistory: newValues,
                        })}}
                    saveAll={saveAll}
                />,
        },
        {
            key: '17',
            label: (
                <>
                    <Paragraph>
                        体格检查
                    </Paragraph>
                </>
            ),
            children:
                <Key
                    values={originOptions.physicalExamination}
                    fatherChange={(newValues) => {
                        setOriginOptions({
                            ...originOptions,
                            physicalExamination: newValues,
                        })}}
                    saveAll={saveAll}
                />,
        },
        {
            key: '18',
            label: (
                <>
                    <Paragraph>
                        治疗意见
                    </Paragraph>
                </>
            ),
            children:
                <Key
                    values={originOptions.treatmentAdvice}
                    fatherChange={(newValues) => {
                        setOriginOptions({
                            ...originOptions,
                            treatmentAdvice: newValues,
                        })}}
                    saveAll={saveAll}
                />,
        },
        {
            key: '19',
            label: (
                <>
                    <Paragraph >
                        <ProfileTwoTone
                            className="iconSize"
                        />
                        处方笺
                    </Paragraph>
                </>
            ),
            children:
                <CardList
                    values={originOptions.recipes}
                    fatherChange={(newValues) => {
                        setOriginOptions({
                            ...originOptions,
                            recipes: newValues,
                        })}}
                    cardName={'处方'}
                    saveAll={saveAll}
                />,
        },
        {
            key: '20',
            label: "耗材明细",
            children:
                <ConsumableDetailConfig
                    defaultConfig={originOptions.consumableDetails}
                    onChange={v => {
                        setOriginOptions({
                            ...originOptions,
                            consumableDetails: {
                                ...originOptions.consumableDetails,
                                ...v
                            }
                        })
                    }}
                    saveAll={saveAll}
                />
        }
    ];

    return (
        <>
            {!loading && (
                <>
                    <Card style={{width:"100%", marginTop:"3vh"}}>
                        {contextHolder}
                        <Tabs
                            defaultActiveKey="1"
                            items={items}
                            onChange={()=> {
                                console.log('切换tabs')
                            }}
                        >
                        </Tabs>
                    </Card>
                </>
            )}
        </>
    )
}
