import {DefaultOptionType} from "antd/es/select";
import {calAge, CalAge} from "./Details/common";
import {BasicResponse} from "../../Http/common";
import handleAxios from "../../Http/request";

/**
 * 用户病例
 */
export const USER_PROFILE:string = "user_profile";

/**
 * 上传新病例
 */
export const UPLOAD_NEW_FILE:string = "upload_new_file";

/**
 * 设置页面
 */
export const CONFIGS:string = "configs";

/**
 * 手术安全核查表
 */
export const OPERATION = "operation";

export interface Draft {
    "id": number,
    "downLoadOutPatientRequest": OutPatientProfile,
    "downLoadDocxRequest": TotalProfile
}

/**
 * 系统导出的用户身份信息。
 */
export interface UserProfile {
    "id":number
    "userId":string,
    "profileId":string,
    "name":string,
    "birthday":string,
    "gender":string,
    "ext":string,
    "createAt"?: string,
    "updateAt"?: string,
    "isDeleted"?: number,
    "draft"?: Draft
}

/**
 * 完整的病例数据，交付给后端生成word
 */
export interface TotalProfile extends UserProfile{
    age: number
    startTime: string
    endTime : string,
    beforeDiagnosis: string
    afterDiagnosis: string
    anestheticMode: string
    strategyName: string
    operator: string
    firstAssistant: string
    anesthesiologist: string    //麻醉师
    handWashingNurse: string
    rovingNurse: string
    steps: StrategySteps //步骤
    recordTime: string
}

/**
 * 手术名称以及步骤
 */
export interface StrategySteps {
    name: string    //手术名称
    steps: string[]     //手术步骤
}

/**
 * 编辑选项
 */
export interface EditOptions {
    beforeDiagnosis: string[],
    afterDiagnosis: string[],
    strategyName: string[],
    anestheticMode: string[],
    operator: string[],
    firstAssistant: string[],
    anesthesiologist: string[],
    handWashingNurse: string[],
    rovingNurse: string[],
    steps: StrategySteps[],
    principalComplaint:string[],
    presentDiseaseHistory: string[],
    pastDiseaseHistory: string[],
    allergicHistory: string[],
    personHistory: string[],
    familyHistory: string[],
    physicalExamination: string[],
    treatmentAdvice: string[],
    recipes: StrategySteps[],
    consumableDetails: ConsumableDetailConfig,  //耗材明细配置项
}

/**
 * 耗材明细配置项
 */
export interface ConsumableDetailConfig {
        glovesSevenPointFiveArray?:Detail[];
        glovesSevenArray?: Detail[];
        glovesSixPointFiveArray?: Detail[];
        syringeArray?:Detail[];
        bladeElevenArray?:Detail[];
        bladeFifteenArray?:Detail[];
        syringeOneMArray?:Detail[];
        syringeTwoPointFiveMArray?:Detail[];
        syringeFiveMArray?:Detail[];
        syringeTwentyMArray?:Detail[];
        syringeFiftyMArray?:Detail[];
        lueLockSyringeOneArray?:Detail[];
        lueLockSyringeTwentyArray?:Detail[];
        lueLockSyringeFiftyArray?:Detail[];
        needleTwentyArray?:Detail[];
        oxygenTubeArray?:Detail[];
        surgicalSutureArray?:Detail[];
        goodNamesArray?:string[];       // 商品名称选项
        specificationNameArray?:string[];  // 规格选项
        dynamicRowArray?:Detail[];
}

/**
 * 门诊病历选项
 */
export interface OutPatientProfile extends UserProfile {
    age: number,
    nation: string,
    clinicalDiagnosis: string,  //门诊诊断
    doctor: string, //医生
    createAt: string,    //时间
    principalComplaint:string,
    presentDiseaseHistory: string,
    pastDiseaseHistory: string,
    allergicHistory: string,
    personHistory: string,
    familyHistory: string,
    physicalExamination: string,
    treatmentAdvice: string,
    recipes: StrategySteps, //处方笺中使用
}

/**
 * 病人所有的基础信息，包含新增
 */
export interface AllBaseInfo extends UserProfile {
    weight: number, // 体重
    bloodPressure: string, // 血压
    pulse: number, // 脉搏
    beforeDiagnosis: string, //术前诊断
    strategyName: string, // 手术名称
    operator: string, //主刀医生
}

/**
 * 过敏史接口
 */
export interface AllergyHistory {
    exist?: boolean;
    others?: string;
    preparationOfSkin?: string;
};

/**
 * 入室时间相关接口
 */
export interface EntryTime {
    entryTime?: string;
    room?: string;
    startTime?: string;
    endTime?: string;
    leftTime?: string;
}

/**
 * 局麻用药
 */
export interface LocalanesthesiaDrugs {
    ropivacaineChecked?: boolean;
    lidocaineChecked?: boolean;
    adrenalineChecked?: boolean;
    bodyPosition?: string;
    skinCondition?: string;
}
//	术中使用
export interface UsedDuringStrategy {
    bipolarElectrocoagulationChecked?: boolean;
    penShapedElectrocoagulationChecked?: boolean;
    electricIonChecked?: boolean;
    electrosurgicalKnifeChecked?: boolean;
    negativePosition?: string;
}

// 	术区消毒液
export interface SurgicalSiteDisinfectant {
    zeroPointFiveIodophorChecked?: boolean;
    bromogeramineChecked?: boolean;
    others?: string;
}

/**
 * 引流管
 */
export interface Drainagetube {
    existChecked?: boolean;
    name?: string;
    position?: string;
}

export interface IntakeAndOutput {
    bloodLoss?: number;
    bloodTransfusion?: number;
    fluidVolume?: number;
    urineVolume?: number;
}

/**
 * 	出室生命体征
 */
export interface VitalSigns {
    bloodPressure?: string;
    pulse?: number;
    respiratoryRate?: number;
}

/**
 * 植入物
 */
export interface Implant {
    position?: string;
    manufacturerBrand?: string;
    nameOrSize?: string;
}

export interface IrrigationSolution {
    irrigationSolution?: string;
    fillerOrPosition?: string;
}

export interface SpecialRecord {
    nasalFilterChecked?: boolean;
    others?: string;
}

/**
 * 手术清点物品
 */
export interface InventoryItem {
    beforeNum?: number;
    duringAdditionNum?: number;
    beforeCloseNum?: number;
    afterNum?: number;
}

export interface InventoryTable {
    knifeHandle: InventoryItem;
    scissors: InventoryItem;
    forceps: InventoryItem;
    needleHolder: InventoryItem;
    curvedHemostat: InventoryItem;
    spongeClamp: InventoryItem;
    tissueForceps: InventoryItem;
    ovalForceps: InventoryItem;
    nasalPack: InventoryItem;
    liposuctionKit: InventoryItem;
    specialInstruments: InventoryItem;
    retractor: InventoryItem;
    stopper: InventoryItem;
    adapter: InventoryItem;
    lkNeedle: InventoryItem;
    largeGauze: InventoryItem;
    smallGauze: InventoryItem;
    cottonBall: InventoryItem;
    brainCottonPad: InventoryItem;
    sutureNeedle: InventoryItem;
    blade: InventoryItem;
    needle: InventoryItem;
}

// 手术护理记录单
export interface NursingRecord {
    id?: number;
    profileId: string;
    name: string;
    gender: string;
    birthday: string;
    age: number;
    weight: number;
    bloodPressure: string;
    pulse: number;
    allergyHistory: AllergyHistory;
    beforeDiagnosis: string;
    strategyName: string;
    entryTime: EntryTime;
    localanesthesiaDrugs: LocalanesthesiaDrugs;
    usedDuringStrategy: UsedDuringStrategy;
    surgicalSiteDisinfectant: SurgicalSiteDisinfectant;
    drainagetube:Drainagetube;
    intakeAndOutput: IntakeAndOutput;
    vitalSigns: VitalSigns;
    implant: Implant;
    irrigationSolution: IrrigationSolution;
    inventoryTable: InventoryTable;
    specialRecord: SpecialRecord;
    operator: string;
    firstAssistant: string;
    secondAssistant: string;
    handWashingNurse: string;
    anestheticMode: string
    anesthesiologist: string    //麻醉师
    steps?: StrategySteps //步骤
    rovingNurse: string;
    submitType?: number;
    updateAt?: string;
    updateBy?: string;
    createBy?: string;
    createAt?: string;
}

/**
 * 名称与其它值对象
 */
export interface NameOtherCheck {
    checked?: boolean;
    name?: string;
    others?: string;
}

export interface Detail {
    specificationChecked?: boolean;
    num?: number;
    manufacturer?: string;
    productionBatchNumStr?: string;
    productionDateStr?: string;
    expirationDateStr?: string;
}

/**
 * 耗材明细表一行完整信息
 */
export interface FullDetail extends Detail {
    uniqueKey?: number; //唯一key，时间戳
    goodNames?: string;
    specificationName?: string,
}

/**
 * 初始化一条详情配置
 * @constructor
 */
export function InitDetail() : Detail {
    return {
        expirationDateStr: undefined,
        manufacturer: undefined,
        productionBatchNumStr: undefined,
        productionDateStr: undefined,
        num: undefined,
        specificationChecked: undefined
    }
}


/**
 * 无菌包部分对象
 */
export interface SterilePack {
    liposuctionKit?: NameOtherCheck;
    generalKit?:NameOtherCheck;
    nasalKit?:NameOtherCheck;
    facialLipidKit?:NameOtherCheck;
    surgicalGownChecked?: boolean;
    surgicalDrapeChecked?: boolean;
    underPadChecked?: boolean;
    surgicalApertureDrapeChecked?: boolean;
    fatCanister?:NameOtherCheck;
    tissueDissector?:NameOtherCheck;
    reTractor?:NameOtherCheck;
    specialKitChecked?: boolean;
    syringeHolder?:NameOtherCheck;
    tube?:NameOtherCheck;
    filterChecked?: boolean;
    filterLargeChecked?: boolean;
    filterSmallChecked?: boolean;
    swellingCanister?:NameOtherCheck;
    others1?:NameOtherCheck;
    liposuctionNeedle?:NameOtherCheck;
    facialLiposuctionNeedle?:NameOtherCheck;
    needleHolder?:NameOtherCheck;
    bandage?:NameOtherCheck;
    toothForceps?:NameOtherCheck;
    fineScissors?:NameOtherCheck;
    others2?:NameOtherCheck;
    disinfectant?:NameOtherCheck;
    others3?:NameOtherCheck;
}

export interface DetailPack {
    glovesChecked?: boolean;
    glovesSevenPointFive?: Detail;
    glovesSeven?:Detail;
    glovesSixPointFive?:Detail;
    drainageTubeChecked?: boolean;
    syringe?:Detail;
    bladeChecked?: boolean;
    bladeEleven?:Detail;
    bladeFifteen?:Detail;
    syringeChecked?: boolean;
    syringeOneM?:Detail;
    syringeTwoPointFiveM?:Detail;
    syringeFiveM?:Detail;
    syringeTwentyM?:Detail;
    syringeFiftyM?:Detail;
    lueLockSyringeChecked?: boolean;
    lueLockSyringeOne?:Detail;
    lueLockSyringeTwenty?:Detail;
    lueLockSyringeFifty?:Detail;
    needleChecked?: boolean;
    needleTwenty?:Detail;
    oxygenTubeChecked?: boolean;
    oxygenTube?:Detail;
    surgicalSutureChecked?: boolean;
    surgicalSuture?:Detail;
    dynamicRowArray?:FullDetail[];  //动态行
}

/**
 * 初始化详情表的详情部分
 * @constructor
 */
export function InitDetailPack(): DetailPack {
    return {
        bladeChecked: false,
        bladeEleven: undefined,
        bladeFifteen: undefined,
        drainageTubeChecked: false,
        glovesChecked: false,
        glovesSeven: undefined,
        glovesSevenPointFive: undefined,
        glovesSixPointFive: undefined,
        lueLockSyringeChecked: false,
        lueLockSyringeFifty: undefined,
        lueLockSyringeOne: undefined,
        lueLockSyringeTwenty: undefined,
        needleChecked: false,
        needleTwenty: undefined,
        oxygenTube: undefined,
        oxygenTubeChecked: false,
        surgicalSuture: undefined,
        surgicalSutureChecked: false,
        syringe: undefined,
        syringeChecked: false,
        syringeFiftyM: undefined,
        syringeFiveM: undefined,
        syringeOneM: undefined,
        syringeTwentyM: undefined,
        syringeTwoPointFiveM: undefined,
        dynamicRowArray: InitDetailDynamicRows()
    }
}

/**
 * 初始化无菌包部分
 * @constructor
 */
export function InitSterilePack() : SterilePack {
    return {
        bandage: InitNameOthersCheck("绷带"),
        disinfectant: InitNameOthersCheck("碘伏消毒液"),
        facialLipidKit: InitNameOthersCheck("面脂包"),
        facialLiposuctionNeedle: InitNameOthersCheck("面吸针"),
        fatCanister: InitNameOthersCheck("脂肪罐"),
        filterChecked: false,
        filterLargeChecked: false,
        filterSmallChecked: false,
        fineScissors: InitNameOthersCheck("精剪"),
        generalKit: InitNameOthersCheck("普包"),
        liposuctionKit: InitNameOthersCheck("吸脂包"),
        liposuctionNeedle: InitNameOthersCheck("吸脂针"),
        nasalKit: InitNameOthersCheck("鼻包"),
        needleHolder: InitNameOthersCheck("针持"),
        others1: InitNameOthersCheck("其它"),
        others2: InitNameOthersCheck("其它"),
        others3: InitNameOthersCheck("其它"),
        reTractor: InitNameOthersCheck("拉钩"),
        specialKitChecked: false,
        surgicalApertureDrapeChecked: false,
        surgicalDrapeChecked: false,
        surgicalGownChecked: false,
        swellingCanister: InitNameOthersCheck("肿胀管"),
        syringeHolder: InitNameOthersCheck("空针架"),
        tissueDissector: InitNameOthersCheck("剥离子"),
        toothForceps: InitNameOthersCheck("齿镊"),
        tube: InitNameOthersCheck("管子"),
        underPadChecked: false
    }
}

/**
 * 消耗明细接口
 */
export interface Consumables{
    id?: number;
    profileId?: string;
    name?: string;
    gender?: string;
    age?: number;
    beforeDiagnosis?: string;
    strategyName?: string;
    sterilepack?: SterilePack;
    details?: DetailPack;
    createAt?: string;
    createBy?: string;
    updateAt?: string;
    updateBy?: string;
    draft?: Consumables;
    submitType?: number;
}

/**
 * 根据Detail列表生成制造商的可选项
 * @param details
 * @constructor
 */
export function GenerateManufacturerOptions(details:Detail[] | undefined): DefaultOptionType[] {
    let retOptions: DefaultOptionType[] = [];
    let manufacturerSet = new Set<string>();
    if (details !== undefined) {
        details.forEach(v => {
            if (v.manufacturer !== undefined) {
                manufacturerSet.add(v.manufacturer)
            }
        })
    }
    manufacturerSet.forEach(v => {
        retOptions.push({
            label: v,
            value: v
        })
    })
    return retOptions;
}

/**
 * 根据已选择的制造商过滤出批号可用的选项
 * @param manufacturer 已选择的制造商
 * @param detailOptions 可选项列表
 * @constructor
 */
export function GenerateProductionBatchNumOptionsByManufacturer(manufacturer: string| undefined, detailOptions: Detail[] | undefined):DefaultOptionType[] {
    let retOptions: DefaultOptionType[] = [];
    let retSet = new Set<string>();
    if (manufacturer !== undefined && detailOptions !== undefined) {
        detailOptions.forEach(v => {
            if (v.manufacturer === manufacturer && v.productionBatchNumStr !== undefined) {
                retSet.add(v.productionBatchNumStr);
            }
        })
    }
    retSet.forEach(v => {
        retOptions.push({
            label: v,
            value: v
        });
    })
    return retOptions;
}

/**
 * 根据已选择的制造商、生产批号过滤出是选中了哪个选项，如果命中了多个选项，那么只返回第一个。
 * @param manufacturer
 * @param productionBatchNum
 * @param options
 * @constructor
 */
export function HandleProductionBatchNumChange(manufacturer: string | undefined,
                                               productionBatchNum: string | undefined,
                                               options: Detail[] | undefined):Detail| undefined {
    if (manufacturer !== undefined && productionBatchNum !== undefined && options !== undefined) {
        let filterResArray = options.filter(v => {
            return v.manufacturer === manufacturer && v.productionBatchNumStr === productionBatchNum;
        })
        // 只取第一个选项
        if (filterResArray.length >= 1) {
            return filterResArray[0];
        }
        return;
    }
    return undefined;
}

/**
 * 初始化名称
 * @param initName
 * @constructor
 */
export function InitNameOthersCheck(initName?:string): NameOtherCheck {
    if (initName === undefined) {
        return {
            checked: undefined,
            name: undefined,
            others: undefined
        }
    } else {
        return {
            checked: undefined,
            name: initName,
            others: undefined
        }
    }
}

/**
 * 动态初始化10行占位
 * @constructor
 */
export function InitDetailDynamicRows():FullDetail[] {
    let retArray:FullDetail[] = [];
    for(let i= 0; i< 10; i++) {
        retArray.push(InitDetailDynamicRow())

    }
    return retArray;
}

export function InitDetailDynamicRow(): FullDetail {
    return {
        uniqueKey: Date.now() + Math.floor(Math.random() * 9999999)
    }
}

/**
 * 初始化所用材料明细表
 * @constructor
 */
export function InitConsumablesRecord(baseInfo?: AllBaseInfo) : Consumables {
    let retObj: Consumables = {
        age: 0,
        beforeDiagnosis: "",
        createAt: undefined,
        createBy: undefined,
        details: InitDetailPack(),
        draft: undefined,
        gender: "",
        id: 0,
        name: "",
        profileId: "",
        sterilepack: InitSterilePack(),
        strategyName: undefined,
        submitType: 0,
        updateAt: undefined,
        updateBy: undefined
    }
    if (baseInfo !== undefined) {
        retObj.profileId = baseInfo.profileId;
        retObj.name= baseInfo.name;
        retObj.gender= baseInfo.gender;
        retObj.age= calAge(baseInfo.birthday);
        retObj.beforeDiagnosis= baseInfo.beforeDiagnosis;
        retObj.strategyName= baseInfo.strategyName;
    }
    return retObj;
}


/**
 * 过敏史初始化
 * @constructor
 */
export function InitAllergyHistory():AllergyHistory {
    return {
        exist: undefined,
        others: undefined,
        preparationOfSkin: undefined
    }
}

/**
 * 初始化表格详情项
 * @constructor
 */
export function InitInventoryItem(beforeNum?: number, duringAdditionNum?:number,
                                  beforeCloseNum?: number,afterNum?: number) : InventoryItem {
    return {
        afterNum: afterNum ,
        beforeCloseNum: beforeCloseNum,
        beforeNum: beforeNum,
        duringAdditionNum: duringAdditionNum
    }
}

/**
 * 初始化护理记录单的手术用品清点
 * @constructor
 */
export function InitNursingRecordInventoryTable(): InventoryTable {
    return {
        brainCottonPad: InitInventoryItem(),
        adapter: InitInventoryItem(),
        blade: InitInventoryItem(1, undefined, 1, 1),
        cottonBall: InitInventoryItem(),
        curvedHemostat: InitInventoryItem(2, undefined, 2, 2),
        forceps: InitInventoryItem(3, undefined, 3, 3),
        knifeHandle: InitInventoryItem(1, undefined, 1, 1),
        largeGauze: InitInventoryItem(),
        liposuctionKit: InitInventoryItem(),
        lkNeedle: InitInventoryItem(),
        nasalPack: InitInventoryItem(),
        needle: InitInventoryItem(3, undefined, 3, 3),
        needleHolder: InitInventoryItem(1, undefined, 1, 1),
        ovalForceps: InitInventoryItem(),
        retractor: InitInventoryItem(),
        scissors: InitInventoryItem(2, undefined, 2, 2),
        smallGauze: InitInventoryItem(10, undefined, 10, 10),
        specialInstruments: InitInventoryItem(),
        spongeClamp: InitInventoryItem(3,undefined, 3, 3),
        stopper: InitInventoryItem(),
        sutureNeedle: InitInventoryItem(),
        tissueForceps: InitInventoryItem(2, undefined, 2, 2)
    }
}

/**
 * 初始化时间
 * @constructor
 */
export function InitEntryTime(): EntryTime {
    return {
        endTime: undefined,
        entryTime: undefined,
        leftTime: undefined,
        room: "",
        startTime: undefined
    }
}

/**
 * 初始化居麻用药
 */

export function InitLocallanesthesiaDrugs() : LocalanesthesiaDrugs {
    return {
        adrenalineChecked: false,
        bodyPosition: "平卧位",
        lidocaineChecked: false,
        ropivacaineChecked: false,
        skinCondition: "完好"
    }
}

/**
 * 初始化出入量
 * @constructor
 */
export function InitInputAndOutput() : IntakeAndOutput {
    return {
        bloodLoss: 1,
        bloodTransfusion: 0,
        fluidVolume: 0,
        urineVolume: 0
    }
}

/**
 * 初始化植入物
 * @constructor
 */
export function InitImplant(): Implant {
    return {
        manufacturerBrand: "/",
        nameOrSize: "/",
        position: "/"
    }
}

/**
 * 初始化引流管
 * @constructor
 */
export function InitDrainagetube() : Drainagetube {
    return {
        existChecked: undefined,
        name: "/",
        position: "/"
    }
}

/**
 * 初始化冲洗溶液和填充物
 * @constructor
 */
export function InitIrrigationSolution() : IrrigationSolution {
    return {
        fillerOrPosition: "/",
        irrigationSolution: "/"
    }
}

/**
 *
 * @constructor
 */
export function InitSurgicalSiteDisinfectant() : SurgicalSiteDisinfectant {
    return {
        bromogeramineChecked: undefined,
        others: "/",
        zeroPointFiveIodophorChecked: undefined
    }
}

/**
 * 初始化术中使用
 * @constructor
 */
export function InitUsedDuringStrategy() : UsedDuringStrategy {
    return {
        bipolarElectrocoagulationChecked: undefined,
        electricIonChecked: undefined,
        electrosurgicalKnifeChecked: undefined,
        negativePosition: "/",
        penShapedElectrocoagulationChecked:undefined
    }
}

/**
 * 初始化护理记录单
 * @constructor
 */
export function InitNursingRecord(baseInfo?: AllBaseInfo):NursingRecord {
    let retObj: NursingRecord = {
        age: 0,
        allergyHistory: InitAllergyHistory(),
        beforeDiagnosis: "",
        birthday: "",
        bloodPressure: "",
        createAt: "",
        createBy: "",
        drainagetube: InitDrainagetube(),
        entryTime: InitEntryTime(),
        firstAssistant: "",
        gender: "",
        handWashingNurse: "",
        id: 0,
        implant: InitImplant(),
        intakeAndOutput: InitInputAndOutput(),
        inventoryTable: InitNursingRecordInventoryTable(),
        irrigationSolution: InitIrrigationSolution(),
        localanesthesiaDrugs: InitLocallanesthesiaDrugs(),
        name: "",
        operator: "",
        profileId: "",
        pulse: 0,
        rovingNurse: "",
        secondAssistant: "",
        specialRecord: {},
        strategyName: "",
        submitType: 0,
        surgicalSiteDisinfectant: InitSurgicalSiteDisinfectant(),
        updateAt: "",
        updateBy: "",
        anesthesiologist: "",
        anestheticMode: "",
        steps: undefined,
        usedDuringStrategy: InitUsedDuringStrategy(),
        vitalSigns: {},
        weight: 0
    }
    if (baseInfo !== undefined) {
        retObj.profileId = baseInfo.profileId;
        retObj.name= baseInfo.name;
        retObj.birthday= baseInfo.birthday;
        retObj.age= CalAge(baseInfo.birthday);
        retObj.weight= baseInfo.weight;
        retObj.gender= baseInfo.gender;
        retObj.bloodPressure= baseInfo.bloodPressure;
        retObj.pulse= baseInfo.pulse;
        retObj.strategyName= baseInfo.strategyName;
        retObj.beforeDiagnosis= baseInfo.beforeDiagnosis;
        retObj.operator = baseInfo.operator;
    }
    return retObj;
}

// 默认时间格式
export const DATE_FORMAT:string = "YYYY-MM-DD HH:mm:ss";

// 提交默认操作
export enum SUBMIT_TYPE {
    TYPE_CREATE = 0,
    TYPE_SAVE_DRAFT = 1,
    TYPE_MODIFY = 2,
}

/**
 * 提交前校验每个字段是否都填写了
 * @param item
 */
export function CheckAllBaseInfo(item:AllBaseInfo) {
    if (item === undefined) {
        return "";
    }
    if (item.userId === "") {
        return "用户ID未填写";
    }
    if (item.profileId === "") {
        return "病例ID未填写"
    }
    if (item.name === "") {
        return "姓名不能为空"
    }
    if (item.birthday === "") {
        return "生日不能为空"
    }
    if (item.gender === "") {
        return "性别不能为空"
    }
    if (item.weight === 0) {
        return "体重不能为0"
    }
    // if (item.bloodPressure === "") {
    //     return "血压必填"
    // }
    if (item.pulse === 0) {
        return "脉搏不能为0"
    }
    if (item.beforeDiagnosis === "") {
        return "术前诊断必填"
    }
    if (item.strategyName === "") {
        return "手术名称必填";
    }
    if (item.operator === "") {
        return "主刀医生必填";
    }
    return "";
}


/**
 * 上传单个病例到云端
 * @param uploadItems
 */
export async function UploadOneBaseInfo(uploadItems: AllBaseInfo[]) : Promise<string> {
    // 上传的数据为空，不处理。
    if (uploadItems.length === 0) {
        return "";
    }
    let ret: Promise<BasicResponse> = handleAxios.post("/api/uploadProfiles", uploadItems);
    await ret.then((res) => {
        if (res.code === 0) {
            return res.data
        } else {
            throw new Error(res.data)
        }
    }).catch(error => {
        // 只要发生异常，就返回重定向页面，大概率是session过期了
        throw new Error("新建数据失败")
    })
    return "";
}

/**
 * 上传单个病例到云端
 * @param uploadItems
 */
export async function UpdateOneBaseInfo(uploadItems: AllBaseInfo) : Promise<string> {
    // 上传的数据为空，不处理。
    if (uploadItems.id === 0) {
        return "";
    }
    let ret: Promise<BasicResponse> = handleAxios.post("/api/updateOneProfile", uploadItems);
    await ret.then((res) => {
        if (res.code === 0) {
            return res.data
        } else {
            throw new Error(res.data)
        }
    }).catch(error => {
        // 只要发生异常，就返回重定向页面，大概率是session过期了
        throw new Error("新建数据失败")
    })
    return "";
}