import { Col, Row, Input, Button, Divider } from 'antd';
import React, {useEffect, useState} from 'react';

const { TextArea } = Input;

interface P {
    values: string[]
    fatherChange: (newValues:string[]) => void
    saveAll: () => void
}
export default ({values, fatherChange, saveAll}:P) => {

    const [numCount, setNumCount] = useState(1);

    useEffect(() => {
        setNumCount(numCount + values.length + 1);
    }, [values]);

    return (
        <>
            {values.map((item, index) => {
                return (
                    <>
                        <Row style={{marginTop:"10px"}} justify="center" align="middle" key={index + numCount}>
                            <Col span={1}>
                                <p>
                                    选项{index + 1}：
                                </p>
                            </Col>
                            <Col span={10} style={{textAlign:"left"}}>
                                <TextArea
                                    allowClear
                                    autoSize
                                    defaultValue={item}
                                    onChange={(e) => {
                                        let newArray = values;
                                        newArray[index] = e.target.value;
                                        fatherChange(newArray);
                                    }}
                                />
                            </Col>
                            <Col span={1} >
                                <Button
                                    danger
                                    type="dashed"
                                    onClick={()=>{
                                        let newArray = values.filter((item, arrayIndex) => index !== arrayIndex);
                                        fatherChange(newArray)
                                    }}
                                >删除</Button>
                            </Col>
                        </Row>
                    </>
                )
            })}
            <Divider />
            <Button onClick={() => {
                let newArray = values;
                newArray.push("");
                fatherChange(newArray);
            }}>新增选项</Button>
            <Button
                style={{
                    marginLeft: "20px",
                    color:"green"
                }}
                onClick={() => {saveAll()} } >
                保存所有配置
            </Button>
        </>
    )
}
