import {
    ConsumableDetailConfig,
    Consumables,
    Detail,
    DetailPack,
    EditOptions,
    FullDetail,
    GenerateManufacturerOptions,
    GenerateProductionBatchNumOptionsByManufacturer,
    HandleProductionBatchNumChange,
    InitConsumablesRecord,
    InitDetail,
    InitDetailDynamicRow,
    InitDetailDynamicRows,
    InitSterilePack,
    SterilePack
} from "../../common";
import {AutoComplete, Col, Flex, Input, InputNumber, Row, Select, Typography, Divider, Checkbox,} from "antd";
import React, {useCallback, useMemo, useRef, useState} from "react";
import NameOtherCheck from "../../../../componts/NameOtherCheck";
import SelectAdd from "../../Details/ProfileView/SelectAdd";

import {spanGood, spanFormat, spanNum, spanManufacturer, spanProductionBatchNumStr, spanProductionDateStr, spanExpirationDateStr} from "../common";
import DynamicDetailRow from "../../../../componts/DynamicDetailRow";

const { Text, Link } = Typography;

interface ConsumableInterface {
    defaultValue?: Consumables,
    options: EditOptions,
    onChange(newValue:Partial<Consumables>): void,
}

interface SterilePackInterface {
    defaultValue?: SterilePack,
    onChange(newValue: Partial<SterilePack>) :void
}

interface DetailPackInterface {
    defaultValue?: DetailPack,
    consumableDetailOptions?: ConsumableDetailConfig,
    onChange(newValue: DetailPack): void        //作为子对象的一部分，传递给父组件应该三完整的子对象内容。
}

interface detailPackRowInterface {
    title: string,
    goodPlaceHold?: boolean,
    defaultValue?: Detail,
    options?: Detail[],
    onChange(newValue: Detail): void,
}

/**
 *
 */
interface dynamicInterface {
    defaultValue?: FullDetail[],
    goodNamesOptions?: string[],
    specificationOptions?: string[],
    detailOptions?: Detail[],
    onChange(newValue: FullDetail[]): void,
}

/**
 * 动态行列表
 * @param defaultValue
 * @param goodNamesOptions
 * @param specificationOptions
 * @param detailOptions
 * @param onChange
 * @constructor
 */
export function DynamicDetailRowModule({defaultValue, goodNamesOptions, specificationOptions, detailOptions, onChange}: dynamicInterface) {

    /**
     * 当前模块中的值
     */
    const moduleValue = useRef<Detail[] | undefined>(defaultValue);

    /**
     * 本组建内部一条数据发生改变
     * @param newValue
     * @param index
     */
    const oneLineChange = (newValue: Detail, index: number) => {
        let newModuleValue: Detail[] | undefined = moduleValue.current;
        if (newModuleValue === undefined) {
            newModuleValue = InitDetailDynamicRows();
        }
        newModuleValue[index] = newValue;
        moduleValue.current = newModuleValue;
        onChange(newModuleValue);
    }


    return (
        <>
            <Col span={24}>
                <Typography>动态行</Typography>
            </Col>
            {defaultValue?.map((item, index) => {
                return (
                    <DynamicDetailRow
                        key={index}
                        defaultValue={item}
                        goodNamesOptions={goodNamesOptions}
                        specificationOptions={specificationOptions}
                        detailOptions={detailOptions}
                        onChange={v => {
                            oneLineChange(v, index)
                        }}
                    />
                )
            })}
        </>
    )
}

/**
 * 一行配置
 * @param title
 * @param goodPlaceHold
 * @param defaultValue
 * @param options
 * @param onChange
 * @constructor
 */
function DetailPackRow({title, goodPlaceHold, defaultValue, options, onChange}: detailPackRowInterface) {

    /**
     * 记录每次渲染周至父组件的最新值，必要时刷新本组建
     */
    const [moduleValue, setModelValue] = useState<undefined | Detail>(defaultValue);

    // 周知父组件最新的值
    const partChange = (partValue: Partial<Detail>) => {
        let newValue: Detail;
        if (moduleValue === undefined) {
            newValue = {
                ...InitDetail(),
                ...partValue,
            }
        } else {
            newValue = {
                ...moduleValue,
                ...partValue
            }
        }
        // 更新本组建内的值并刷新本自组建
        setModelValue(newValue);
        // 周知父组件本自组建内值发生改变
        onChange(newValue)
    }

    return (
        <>
            {goodPlaceHold && (
                <Col span={spanGood} >

                </Col>
            )}
            <Col span={spanFormat}>
                <Checkbox
                    defaultChecked={moduleValue?.specificationChecked}
                    onChange={v => partChange({specificationChecked: v.target.checked})}
                >{title}</Checkbox>
            </Col>
            <Col span={spanNum}>
                <InputNumber
                    disabled={!(moduleValue?.specificationChecked === true)}
                    style={{
                        width: "80%"
                    }}
                    min={0}
                    changeOnWheel
                    defaultValue={moduleValue?.num}
                    onChange={v => partChange({num : v=== null ? undefined : v})}
                />
            </Col>
            <Col span={spanManufacturer}>
                <AutoComplete
                    disabled={!(true === moduleValue?.specificationChecked)}
                    style={{
                        width: "90%"
                    }}
                    options={GenerateManufacturerOptions(options)}
                    defaultValue={moduleValue?.manufacturer}
                    onSearch={v => {
                        partChange({
                            manufacturer: v,
                            productionBatchNumStr: undefined,
                            productionDateStr: undefined,
                            expirationDateStr: undefined
                        })
                    }}
                    onSelect={(value: string) => {
                        partChange({
                            manufacturer: value,
                            productionBatchNumStr: undefined,
                            productionDateStr: undefined,
                            expirationDateStr: undefined
                        })
                    }}
                />
            </Col>
            <Col span={spanProductionBatchNumStr}>
                <AutoComplete
                    style={{
                        width: "90%"
                    }}
                    disabled={!(true === moduleValue?.specificationChecked) || moduleValue.manufacturer === undefined}
                    options={GenerateProductionBatchNumOptionsByManufacturer(moduleValue?.manufacturer, options)}
                    value={moduleValue?.productionBatchNumStr}
                    onSearch={(v) => {
                        // 输入搜索批号，那么直接以输入的为准，同时清除生产时间和结束时间
                        partChange({
                            productionBatchNumStr: v,
                            productionDateStr: undefined,
                            expirationDateStr: undefined
                        })
                    }}
                    onSelect={(v) => {
                        let selectedDetail = HandleProductionBatchNumChange(moduleValue?.manufacturer,
                            v, options)
                        if (selectedDetail !== undefined) {
                            partChange({
                                productionBatchNumStr: v,
                                productionDateStr: selectedDetail.productionDateStr,
                                expirationDateStr: selectedDetail.expirationDateStr
                            })
                        } else {
                            partChange({
                                productionBatchNumStr: v,
                                productionDateStr: undefined,
                                expirationDateStr: undefined,
                            })
                        }
                    }}
                />
            </Col>
            <Col span={spanProductionDateStr}>
                <Input
                    style={{
                        width: "90%"
                    }}
                    disabled={!(true === moduleValue?.specificationChecked)
                        || moduleValue.manufacturer === undefined
                        || moduleValue.productionBatchNumStr === undefined
                }
                    defaultValue={moduleValue?.productionDateStr}
                    value={moduleValue?.productionDateStr}
                    onChange={v => {
                        partChange({productionDateStr: v.target.value})
                    }}
                />
            </Col>
            <Col span={spanExpirationDateStr}>
                <Input
                    style={{
                        width: "90%"
                    }}
                    disabled={!(true === moduleValue?.specificationChecked)
                        || moduleValue.manufacturer === undefined
                        || moduleValue.productionBatchNumStr === undefined
                    }
                    defaultValue={moduleValue?.expirationDateStr}
                    value={moduleValue?.expirationDateStr}
                    onChange={v => {
                        partChange({expirationDateStr: v.target.value})
                    }}
                />
            </Col>
        </>
    )
}

/**
 * 物品明细表
 * @param defaultValue
 * @param consumableDetailOptions
 * @param onChange
 * @constructor
 */
function DetailPackDepartment({defaultValue, consumableDetailOptions, onChange}: DetailPackInterface) {

    const valueRef = useRef<DetailPack | undefined>(defaultValue);

    /**
     * 周知父组件此部分数据发生变化，同时记录下组建内最新的值
     * @param value
     */
    const partChange = (value: Partial<DetailPack>) => {
        let newValue: DetailPack;
        if (valueRef.current === undefined) {
            newValue = {
                ...InitSterilePack(),
                ...value
            }
        } else {
            newValue = {
                ...valueRef.current,
                ...value
            }
        }
        valueRef.current = newValue;
        onChange(newValue);
    }

    return (
        <>
            <Divider>物品明细</Divider>
            <Row gutter={[16, 16]} >
                <Col span={spanGood} >
                    物品
                </Col>
                <Col span={spanFormat}>
                    规格
                </Col>
                <Col span={spanNum}>
                    数量
                </Col>
                <Col span={spanManufacturer}>
                    厂家
                </Col>
                <Col span={spanProductionBatchNumStr}>
                    生产批号
                </Col>
                <Col span={spanProductionDateStr}>
                    生产日期
                </Col>
                <Col span={spanExpirationDateStr}>
                    失效日期
                </Col>
                <Col span={spanGood}>
                    <Checkbox
                        defaultChecked={defaultValue?.glovesChecked}
                        onChange={v => partChange({glovesChecked: v.target.checked})}
                    >
                        手套</Checkbox>
                </Col>
                <DetailPackRow
                    title={"7.5#"}
                    options={consumableDetailOptions?.glovesSevenPointFiveArray}
                    defaultValue={defaultValue?.glovesSevenPointFive}
                    goodPlaceHold={false}
                    onChange={v => partChange({
                        glovesSevenPointFive: {...v}
                    })}
                />
                <DetailPackRow
                    title={"7#"}
                    options={consumableDetailOptions?.glovesSevenArray}
                    defaultValue={defaultValue?.glovesSeven}
                    goodPlaceHold={true}
                    onChange={v => partChange({
                        glovesSeven: {...v}
                    })}
                />
                <DetailPackRow
                    title={"6.5#"}
                    options={consumableDetailOptions?.glovesSixPointFiveArray}
                    defaultValue={defaultValue?.glovesSixPointFive}
                    goodPlaceHold={true}
                    onChange={v => partChange({
                        glovesSixPointFive: {...v}
                    })}
                />
                <Col span={spanGood}>
                    <Checkbox
                        defaultChecked={defaultValue?.drainageTubeChecked}
                        onChange={v => partChange({
                            drainageTubeChecked: v.target.checked
                        })}
                    >引流管</Checkbox>
                </Col>
                <DetailPackRow
                    title={"输液器"}
                    options={consumableDetailOptions?.syringeArray}
                    defaultValue={defaultValue?.syringe}
                    goodPlaceHold={false}
                    onChange={v => partChange({
                        syringe: {...v}
                    })}
                />
                <Col span={spanGood}>
                    <Checkbox
                        defaultChecked={defaultValue?.bladeChecked}
                        onChange={v => onChange({
                            bladeChecked: v.target.checked
                        })}
                    >刀片</Checkbox>
                </Col>
                <DetailPackRow
                    title={"11#"}
                    options={consumableDetailOptions?.bladeElevenArray}
                    defaultValue={defaultValue?.bladeEleven}
                    goodPlaceHold={false}
                    onChange={v => partChange({
                        bladeEleven: {
                            ...v
                        }
                    })}
                />
                <DetailPackRow
                    title={"15#"}
                    options={consumableDetailOptions?.bladeFifteenArray}
                    defaultValue={defaultValue?.bladeFifteen}
                    goodPlaceHold={true}
                    onChange={v => partChange({
                        bladeFifteen: {
                            ...v
                        }
                    })}
                />
                <Col span={spanGood}>
                    <Checkbox
                        defaultChecked={defaultValue?.syringeChecked}
                        onChange={v => partChange({
                            syringeChecked: v.target.checked
                        })}
                    >注射器</Checkbox>
                </Col>
                <DetailPackRow
                    title={"1ml"}
                    options={consumableDetailOptions?.syringeOneMArray}
                    defaultValue={defaultValue?.syringeOneM}
                    goodPlaceHold={false}
                    onChange={v => partChange({
                        syringeOneM: {
                            ...v
                        }
                    })}
                />
                <DetailPackRow
                    title={"2.5ml"}
                    options={consumableDetailOptions?.syringeTwoPointFiveMArray}
                    defaultValue={defaultValue?.syringeTwoPointFiveM}
                    goodPlaceHold={true}
                    onChange={v => partChange({
                        syringeTwoPointFiveM: {
                            ...v
                        }
                    })}
                />
                <DetailPackRow
                    title={"5ml"}
                    options={consumableDetailOptions?.syringeFiveMArray}
                    defaultValue={defaultValue?.syringeFiveM}
                    goodPlaceHold={true}
                    onChange={v => partChange({
                        syringeFiveM: {
                            ...v
                        }
                    })}
                />
                <DetailPackRow
                    title={"20ml"}
                    options={consumableDetailOptions?.syringeTwentyMArray}
                    defaultValue={defaultValue?.syringeTwentyM}
                    goodPlaceHold={true}
                    onChange={v => partChange({
                        syringeTwentyM: {
                            ...v
                        }
                    })}
                />
                <DetailPackRow
                    title={"50ml"}
                    options={consumableDetailOptions?.syringeFiftyMArray}
                    defaultValue={defaultValue?.syringeFiftyM}
                    goodPlaceHold={true}
                    onChange={v => partChange({
                        syringeFiftyM: v
                    })}
                />
                <Col span={spanGood}>
                    <Checkbox
                        defaultChecked={defaultValue?.lueLockSyringeChecked}
                        onChange={v => partChange({
                            lueLockSyringeChecked: v.target.checked
                        })}
                    >螺旋注射器</Checkbox>
                </Col>
                <DetailPackRow
                    title={"1ml"}
                    options={consumableDetailOptions?.lueLockSyringeOneArray}
                    defaultValue={defaultValue?.lueLockSyringeOne}
                    goodPlaceHold={false}
                    onChange={v => partChange({
                        lueLockSyringeOne: v
                    })}
                />
                <DetailPackRow
                    title={"20ml"}
                    options={consumableDetailOptions?.lueLockSyringeTwentyArray}
                    defaultValue={defaultValue?.lueLockSyringeTwenty}
                    goodPlaceHold={true}
                    onChange={v => partChange({
                        lueLockSyringeTwenty: v
                    })}
                />
                <DetailPackRow
                    title={"50ml"}
                    options={consumableDetailOptions?.lueLockSyringeFiftyArray}
                    defaultValue={defaultValue?.lueLockSyringeFifty}
                    goodPlaceHold={true}
                    onChange={v => partChange({
                        lueLockSyringeFifty: v
                    })}
                />
                <Col span={spanGood}>
                    <Checkbox
                        defaultChecked={defaultValue?.needleChecked}
                        onChange={v => partChange({
                            needleChecked: v.target.checked
                        })}
                    >针头</Checkbox>
                </Col>
                <DetailPackRow
                    title={"20ml"}
                    options={consumableDetailOptions?.needleTwentyArray}
                    defaultValue={defaultValue?.needleTwenty}
                    goodPlaceHold={false}
                    onChange={v => partChange({
                        needleTwenty: v
                    })}
                />
                <Col span={spanGood}>
                    <Checkbox
                        defaultChecked={defaultValue?.oxygenTubeChecked}
                        onChange={v => partChange({
                            oxygenTubeChecked: v.target.checked
                        })}
                    >氧气管</Checkbox>
                </Col>
                <DetailPackRow
                    title={""}
                    options={consumableDetailOptions?.oxygenTubeArray}
                    defaultValue={defaultValue?.oxygenTube}
                    goodPlaceHold={false}
                    onChange={v => partChange({
                        oxygenTube: v
                    })}
                />
                <Col span={spanGood}>
                    <Checkbox
                        defaultChecked={defaultValue?.surgicalSutureChecked}
                        onChange={v => partChange({
                            surgicalSutureChecked: v.target.checked
                        })}
                    >线材</Checkbox>
                </Col>
                <DetailPackRow
                    title={""}
                    options={consumableDetailOptions?.surgicalSutureArray}
                    defaultValue={defaultValue?.surgicalSuture}
                    goodPlaceHold={false}
                    onChange={v => partChange({
                        surgicalSuture: v
                    })}
                />
                {
                    useMemo(() => {
                        return (
                            <>
                                <DynamicDetailRowModule
                                    defaultValue={defaultValue?.dynamicRowArray}
                                    goodNamesOptions={consumableDetailOptions?.goodNamesArray}
                                    specificationOptions={consumableDetailOptions?.specificationNameArray}
                                    detailOptions={consumableDetailOptions?.dynamicRowArray}
                                    onChange={v => partChange({dynamicRowArray: v})} />
                            </>
                        )
                    }, [defaultValue])
                }
            </Row>
        </>
    )
}

/**
 * 无菌包部分属性
 * @param defaultValue
 * @param onChange
 * @constructor
 */
function SterilePackDepartment({defaultValue, onChange}:SterilePackInterface) {

    const moduleValue = useRef<SterilePack | undefined>(defaultValue);

    /**
     * 模块部分值发生改变
     * @param partValue
     */
    const partChange = (partValue: Partial<SterilePack>) => {
        let newModuleValue: SterilePack;
        if (moduleValue.current === undefined) {
            newModuleValue = {
                ...InitSterilePack(),
                ...partValue
            }
        } else {
            newModuleValue = {
                ...moduleValue.current,
                ...partValue
            }
        }
        moduleValue.current = newModuleValue;
        onChange(newModuleValue);
    }

    return (
        <>
            <Divider>无菌包</Divider>
            <Row gutter={[16, 16]} >
                <Col span={6}>
                    <NameOtherCheck
                        defaultValue={defaultValue?.liposuctionKit}
                        onChange={v => partChange({liposuctionKit: v})}
                    />
                </Col>
                <Col span={6}>
                    <NameOtherCheck
                        defaultValue={defaultValue?.generalKit}
                        onChange={v => partChange({generalKit: v})}
                    />
                </Col>
                <Col span={6}>
                    <NameOtherCheck
                        defaultValue={defaultValue?.nasalKit}
                        onChange={v => partChange({nasalKit: v})}
                    />
                </Col>
                <Col span={6}>
                    <NameOtherCheck
                        defaultValue={defaultValue?.facialLipidKit}
                        onChange={v => partChange({
                            facialLipidKit: v
                        })}
                    />
                </Col>
                <Col span={6}>
                    <Checkbox
                        defaultChecked={defaultValue?.surgicalGownChecked}
                        onChange={e => partChange({surgicalGownChecked: e.target.checked})}
                    >手术衣</Checkbox>
                    <Checkbox
                        defaultChecked={defaultValue?.surgicalDrapeChecked}
                        onChange={e => partChange({surgicalDrapeChecked: e.target.checked})}
                    >治疗衣</Checkbox>
                    <Checkbox
                        defaultChecked={defaultValue?.underPadChecked}
                        onChange={e => partChange({underPadChecked: e.target.checked})}
                    >中单</Checkbox>
                    <Checkbox
                        defaultChecked={defaultValue?.surgicalApertureDrapeChecked}
                        onChange={e => partChange({surgicalApertureDrapeChecked: e.target.checked})}
                    >洞巾</Checkbox>
                </Col>
                <Col span={6}>
                    <NameOtherCheck
                        defaultValue={defaultValue?.fatCanister}
                        onChange={v => partChange({fatCanister: v})}
                    />
                </Col>
                <Col span={6}>
                    <NameOtherCheck
                        defaultValue={defaultValue?.tissueDissector}
                        onChange={v => partChange({tissueDissector: v})}
                    />
                </Col>
                <Col span={6}>
                    <NameOtherCheck
                        defaultValue={defaultValue?.reTractor}
                        onChange={v => partChange({reTractor: v})}
                    />
                </Col>
                <Col span={6}>
                    <Checkbox
                        defaultChecked={defaultValue?.specialKitChecked}
                        onChange={v => partChange({specialKitChecked: v.target.checked})}
                    >特殊包</Checkbox>
                </Col>
                <Col span={6}>
                    <NameOtherCheck
                        defaultValue={defaultValue?.syringeHolder}
                        onChange={v => partChange({syringeHolder: v})}
                    />
                </Col>
                <Col span={6}>
                    <NameOtherCheck
                        defaultValue={defaultValue?.tube}
                        onChange={v => partChange({tube: v})}
                    />
                </Col>
                <Col>
                    <Checkbox
                        defaultChecked={defaultValue?.filterChecked}
                        onChange={v => partChange({filterChecked: v.target.checked})}
                    >过滤器</Checkbox>
                    <Checkbox
                        defaultChecked={defaultValue?.filterLargeChecked}
                        onChange={v => partChange({filterLargeChecked: v.target.checked})}
                    >
                        大
                    </Checkbox>
                    <Checkbox
                        defaultChecked={defaultValue?.filterSmallChecked}
                        onChange={v => partChange({filterSmallChecked: v.target.checked})}
                    >
                        小
                    </Checkbox>
                </Col>
                <Col span={6}>
                    <NameOtherCheck
                        defaultValue={defaultValue?.swellingCanister}
                        onChange={v => partChange({swellingCanister: v})}
                    />
                </Col>
                <Col span={6}>
                    <NameOtherCheck
                        defaultValue={defaultValue?.others1}
                        onChange={v => partChange({others1: v})}
                    />
                </Col>
                <Divider></Divider>
                <Col span={6}>
                    <NameOtherCheck
                        defaultValue={defaultValue?.liposuctionNeedle}
                        onChange={v => partChange({liposuctionNeedle: v})}
                    />
                </Col>
                <Col span={6}>
                    <NameOtherCheck
                        defaultValue={defaultValue?.facialLiposuctionNeedle}
                        onChange={v => partChange({facialLiposuctionNeedle: v})}
                    />
                </Col>
                <Col span={6}>
                    <NameOtherCheck
                        defaultValue={defaultValue?.needleHolder}
                        onChange={v => partChange({needleHolder: v})}
                    />
                </Col>
                <Col span={6}>
                    <NameOtherCheck
                        defaultValue={defaultValue?.bandage}
                        onChange={v => partChange({bandage: v})}
                    />
                </Col>
                <Col span={6}>
                    <NameOtherCheck
                        defaultValue={defaultValue?.fineScissors}
                        onChange={v => partChange({fineScissors: v})}
                    />
                </Col>
                <Col span={6}>
                    <NameOtherCheck
                        defaultValue={defaultValue?.others2}
                        onChange={v => partChange({others2: v})}
                    />
                </Col>
                <Divider />
                <Col span={6}>
                    <NameOtherCheck
                        defaultValue={defaultValue?.disinfectant}
                        onChange={v => partChange({disinfectant: v})}
                    />
                </Col>
                <Col span={6}>
                    <NameOtherCheck
                        defaultValue={defaultValue?.others3}
                        onChange={v => partChange({others3: v})}
                    />
                </Col>
            </Row>
        </>
    )
}

/**
 * 默认返回组建
 * @param defaultValue
 * @param options
 * @param onChange
 */
export default function ({defaultValue, options, onChange} : ConsumableInterface) {

    const consumableModuleValue = useRef<Consumables | undefined>(defaultValue);

    /**
     * 部分值改变
     * @param newValue
     */
    const partChange = (newValue: Consumables) => {
        let newConsumableModuleValue: Consumables;
        if (consumableModuleValue.current === undefined) {
            newConsumableModuleValue = {
                ...InitConsumablesRecord(),
                ...newValue,
            }
        }else {
            newConsumableModuleValue = {
                ...consumableModuleValue.current,
                ...newValue
            }
        }
        consumableModuleValue.current = newConsumableModuleValue;
        onChange(newConsumableModuleValue);
    }

    /**
     * 无菌包部分
     */
    const sterilePackDepartment = useMemo(() => {
        return (
            <>
                <SterilePackDepartment
                    defaultValue={defaultValue?.sterilepack}
                    onChange={v => {
                        partChange({
                            sterilepack: v
                        })}}
                />
            </>
        )
    }, [defaultValue?.sterilepack]);

    /**
     * 详情页组成部分
     */
    const detailDepartment = useMemo(() => {

        return (
            <>
                <DetailPackDepartment
                    defaultValue={defaultValue?.details}
                    consumableDetailOptions={options.consumableDetails}
                    onChange={v => {
                        partChange({details:v})
                    }} />
            </>
        )
    }, [defaultValue?.details, options.consumableDetails])

    if (defaultValue == undefined) {
        return (
            <>
                请选择病例
            </>
        )
    } else {
        return (
            <>
                <Flex vertical justify={'space-around'} gap={'large'}>
                    <Row justify="start"
                         align="middle"
                         style={{
                             marginTop: "10px",
                             marginBottom: "10px"
                         }}
                    >
                        <Col span={2}>
                            姓名
                        </Col>
                        <Col span={3}>
                            <Input
                                defaultValue={defaultValue.name}
                                onChange={(e) => {
                                    partChange({name: e.target.value})
                                }
                                }
                            />
                        </Col>
                        <Col span={2}>
                            性别
                        </Col>
                        <Col span={2}>
                            <Select
                                style={{
                                    width: "100%"
                                }}
                                defaultValue={defaultValue.gender}
                                onChange={(v: string) => {
                                    partChange({gender: v})
                                }}
                                options={[
                                    {value: "男", label:"男"},
                                    {value: "女", label: "女"}
                                ]}
                            />
                        </Col>
                        <Col span={2}>
                            年龄
                        </Col>
                        <Col span={2}>
                            <InputNumber
                                min={0}
                                changeOnWheel
                                style={{
                                    width: "100%"
                                }}
                                defaultValue={defaultValue.age}
                                onChange={(e:number | null) => {
                                    if (e !== null ) {
                                        partChange({age: e})
                                    } else {
                                        partChange({age: 0})
                                    }
                                }}
                                addonAfter={"岁"}
                            />
                        </Col>
                    </Row>
                    <Row justify={'start'} align={'middle'}>
                        <Col
                            span={2}
                        >
                            <Text>术前诊断</Text>
                        </Col>
                        <Col span={14}>
                            <AutoComplete
                                options={options.beforeDiagnosis.map((item) => ({value: item}))}
                                defaultValue={defaultValue.beforeDiagnosis}
                                style={{width: "100%"}}
                                onSelect={(e) => {
                                    partChange({beforeDiagnosis:e})
                                }}
                                onSearch={(v) => {
                                    partChange({beforeDiagnosis: v})
                                }}
                            />
                        </Col>
                    </Row>
                    <Row justify={'start'} align={'middle'}>
                        <Col
                            span={2}
                        >
                            <Text>手术名称</Text>
                        </Col>
                        <Col span={14}>
                            <AutoComplete
                                options={options.strategyName.map((item) => ({value: item}))}
                                defaultValue={defaultValue.strategyName}
                                style={{width: "100%"}}
                                onSelect={(e) => {
                                    partChange({strategyName:e})
                                }}
                                onSearch={(v) => {
                                    partChange({strategyName: v})
                                }}
                            />
                        </Col>
                    </Row>

                    {sterilePackDepartment}

                    {/*性能优化部分组建渲染*/}
                    {detailDepartment}

                </Flex>
            </>
        )
    }
}