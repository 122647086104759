import {Col, Input, Row} from "antd";
import React from "react";

interface P {
    keyText: string, // 默认key的值
    defaultValue: string, // 默认value
    placeHolder? : string,
    suffix?:string, //后缀，可以不存在
    onChange: (newValue:string) => void
}


/**
 * @param keyText key展示值
 * @param defaultValue 默认值
 * @param placeHolder
 * @param suffix
 * @param onChange
 */
export default function ({keyText, defaultValue, placeHolder, suffix, onChange}: P) {
    const keyWidth = 4;
    const valueWidth = 16;
    return (
        <Row align={'middle'}
             justify={'center'}
             style={{marginTop: '10px', marginBottom: '10px'}}>
            <Col span={keyWidth}>
                {keyText}
            </Col>
            <Col span={valueWidth}>
                <Input
                    placeholder={placeHolder}
                    defaultValue={defaultValue}
                    onChange={e => {
                        onChange(e.target.value)
                    }}
                    suffix={suffix}
                />
            </Col>
        </Row>
    )
}