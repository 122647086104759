import { Col, Row, Input, Button, Typography, Divider, Card} from 'antd';
import React, {useEffect, useState} from 'react';
import Key from "../Key"
import {StrategySteps} from "../../common";

const {Paragraph} = Typography;

interface P {
    values: StrategySteps[]
    fatherChange: (newValues:StrategySteps[]) => void
    cardName: string
    saveAll: () => void
}
export default ({values, fatherChange, cardName, saveAll}:P) => {

    const [numCount, setNumCount] = useState(1);

    useEffect(() => {
        setNumCount(numCount + values.length + 1);
    }, [values]);

    return (
        <>
            {values.map((strategyStep, index) => {

                return (
                    <>
                        <Card
                            bodyStyle={{backgroundColor: "#d9d9d9"}}
                            headStyle={{backgroundColor: "#bfbfbf"}}
                            style={{
                                marginTop:"20px"
                            }}
                            extra={
                            <>
                                <Button
                                    danger
                                    onClick={() => {
                                        let newSteps = values.filter((steps, stepIndex) => stepIndex != index);
                                        fatherChange(newSteps)
                                    }}
                                >删除{cardName}</Button>
                            </>
                        }
                            type="inner"
                            key={index + numCount}
                            title={cardName + ` ${index + 1}：` + strategyStep.name}
                        >
                            <Row style={{marginTop:"10px"}} justify="center" align="middle">
                                <Col span={1}>
                                    <p>{cardName}名称：</p>
                                </Col>
                                <Col span={10}>
                                    <Input
                                        allowClear
                                        defaultValue={strategyStep.name}
                                        onChange={(e) => {
                                            let newStepsArray = values;
                                            newStepsArray[index].name = e.target.value;
                                            fatherChange(newStepsArray);
                                        }}
                                    >

                                    </Input>
                                </Col>
                                <Col span={1} >

                                </Col>
                            </Row>
                           <Key
                               values={strategyStep.steps}
                               fatherChange={(newSteps) => {
                                    let newStepsArray = values;
                                    newStepsArray[index].steps = newSteps;
                                    fatherChange(newStepsArray)
                               }}
                               saveAll={saveAll}
                           />
                        </Card>
                    </>
                )
            })}
            <Divider />
            <Button onClick={() => {
                let newArray = values;
                let newStrategySteps: StrategySteps = {
                    name: "",
                    steps: []
                }
                newArray.push(newStrategySteps);
                fatherChange(newArray);
            }}>新增{cardName}</Button>
            <Button
                style={{
                    marginLeft: "20px",
                    color:"green"
                }}
                onClick={() => {saveAll()} } >
                保存所有{cardName}
            </Button>
        </>
    )
}
