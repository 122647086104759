import {InitNameOthersCheck, NameOtherCheck} from "../../pages/Admin/common";
import {Checkbox, Col, Input, Row, Typography} from "antd";
import {useRef, useState} from "react";
import {cursorTo} from "readline";
const { Text} = Typography;
interface P {
    defaultValue? : NameOtherCheck,
    onChange(newValue: NameOtherCheck) : void
}

export default function ({defaultValue, onChange}: P) {

    const [fresh, setFresh] = useState(false);

    const moduleValue = useRef<NameOtherCheck | undefined>(defaultValue);

    // 部分值发生改变
    const partChange = (newValue: Partial<NameOtherCheck>) => {
        let newModuleValue: NameOtherCheck;
        if (moduleValue.current === undefined) {
            newModuleValue = {
                ...InitNameOthersCheck(),
                ...newValue
            }
        }else {
            newModuleValue = {
                ...moduleValue.current,
                ...newValue
            }
        }
        moduleValue.current = newModuleValue;
        setFresh(!fresh);
        onChange(newModuleValue);
    }

    return (
        <>
            <Row justify={'start'} align={'middle'}>
                <Col span={1} offset={1}>
                    <Checkbox
                        defaultChecked={defaultValue?.checked}
                        onChange={v => partChange({checked: v.target.checked})}
                    />
                </Col>
                <Col span={5}>
                    <Text>{defaultValue?.name}</Text>
                </Col>
                <Col >
                    <Input
                        defaultValue={defaultValue?.others}
                        disabled={!moduleValue.current?.checked}
                        onChange={v => partChange({others: v.target.value})}
                    />
                </Col>
            </Row>
        </>
    )
}