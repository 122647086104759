import {DATE_FORMAT, EditOptions, InitNursingRecord, InventoryTable, NursingRecord} from "../../common";
import {
    AutoComplete, Checkbox,
    Col,
    DatePicker, Divider,
    Flex,
    Input,
    InputNumber,
    Radio,
    Row,
    Select,
    TimePicker,
    Typography
} from "antd";
import React, {useRef} from "react";
import RadioInput from "../../../../componts/RadioInput";
import InventoryShowItem from "../../../../componts/InventoryShowItem";
import InventoryShowTitle from "../../../../componts/InventoryShowTitle";
import TextArea from "antd/es/input/TextArea";
import SelectAdd from "../../Details/ProfileView/SelectAdd";
import {formatDateStrInput} from "../common";
import StrategyStepsComponents from "../../../../componts/StrategyStepsComponents";
const { Text, Link } = Typography;

interface P {
    defaultValue?: NursingRecord    // 记录初始值
    onChange(newValue: NursingRecord) : void // 告诉父组件值已经改变
    options: EditOptions
}

export default function ({defaultValue, onChange, options}:P) {

    // 模板值
    const moduleValue = useRef<NursingRecord | undefined>(defaultValue);

    /**
     * 模块值发生改变
     * @param partValue
     */
    const partChange = (partValue: Partial<NursingRecord>) : void => {
        let newValue: NursingRecord;
        if (moduleValue.current === undefined) {
            newValue = InitNursingRecord()
        } else {
            // 合并新的值并周知父组件改变
            newValue = {
                ...moduleValue.current,
                ...partValue
            }
        }
        moduleValue.current = newValue;
        // 周知父组件
        onChange(newValue);
    }

    /**
     * 手术用品清点表值发生改变
     * @param partValue
     */
    const inventoryTablePartChange = (partValue: Partial<InventoryTable>) : void => {
        // module未初始化的要先初始化
        if (moduleValue.current === undefined) {
            partChange({
                ...InitNursingRecord(),
                ...partValue
            })
        } else {
            partChange(
                {
                    inventoryTable : {
                        ...moduleValue.current.inventoryTable,
                        ...partValue
                    }
                })
        }
    }

    if (moduleValue.current === undefined || defaultValue === undefined) {
        return (
            <>
                加载中
            </>
        )
    } else {
        return (
            <>
                <Row justify="start"
                     align="middle"
                     style={{
                         marginTop: "10px",
                         marginBottom: "10px"
                     }}
                >
                    <Col span={2}>
                        姓名
                    </Col>
                    <Col span={2}>
                        <Input
                            defaultValue={defaultValue.name}
                            onChange={(e) => {
                                partChange({name: e.target.value})
                            }
                            }
                        />
                    </Col>
                    <Col span={2}>
                        性别
                    </Col>
                    <Col span={1}>
                        <Select
                            style={{
                                width: "100%"
                            }}
                            defaultValue={defaultValue.gender}
                            onChange={(v: string) => partChange({gender: v})}
                            options={[
                                {value: "男", label:"男"},
                                {value: "女", label: "女"}
                            ]}
                        />
                    </Col>
                    <Col span={2}>
                        年龄
                    </Col>
                    <Col span={2}>
                        <InputNumber
                            min={0}
                            changeOnWheel
                            style={{
                                width: "100%"
                            }}
                            defaultValue={defaultValue.age}
                            onChange={(e:number | null) => {
                                if (e !== null ) {
                                    partChange({age: e})
                                } else {
                                    partChange({age: 0})
                                }
                            }}
                            addonAfter={"岁"}
                        />
                    </Col>
                    <Col span={2}>
                        体重
                    </Col>
                    <Col span={2}>
                        <InputNumber
                            min={0}
                            changeOnWheel
                            defaultValue={defaultValue.weight}
                            onChange={(e:number|null) => {
                                if (e !== null) {
                                    partChange({weight:e})
                                } else {
                                    partChange({weight:0})
                                }
                            }}
                            addonAfter={"kg"}
                        />
                    </Col>
                    <Col span={2}>
                        血压
                    </Col>
                    <Col span={3}>
                        <Input
                            defaultValue={defaultValue.bloodPressure}
                            onChange={(e) => {
                                    partChange({bloodPressure: e.target.value})
                            }}
                            addonAfter={"mmHg"}
                        />
                    </Col>
                    <Col span={2}>
                        脉搏
                    </Col>
                    <Col span={2}>
                        <InputNumber
                            min={0}
                            changeOnWheel
                            defaultValue={defaultValue.pulse}
                            onChange={(e:number|null) => {
                                if (e !== null) {
                                    partChange({pulse: e})
                                } else {
                                    partChange({pulse: 0})
                                }
                            }}
                            addonAfter={"次/分钟"}
                        />
                    </Col>
                </Row>
                <Flex vertical justify={'space-around'} gap={'large'}>
                    <Row justify={'space-around'} align={'middle'}>
                        <Col span={4} >
                            <RadioInput
                                title={"药物过敏史"}
                                value={defaultValue.allergyHistory.exist}
                                fatherChange={v =>
                                    partChange({
                                            allergyHistory:
                                                {
                                                    ...moduleValue.current!.allergyHistory,
                                                    exist: v
                                                }
                                        }
                                    )}
                            />
                        </Col>
                        <Col span={8}>
                            <Input
                                style={{width: '100%'}}
                                defaultValue={defaultValue.allergyHistory.others}
                                onChange={e=> partChange({
                                    allergyHistory: {
                                        ...moduleValue.current!.allergyHistory,
                                        others: e.target.value
                                    }
                                })}
                            />
                        </Col>
                        <Col span={10}>
                            <Text>术前皮肤准备情况</Text>
                            <Radio.Group
                                style={{marginLeft:"10px"}}
                                defaultValue={defaultValue.allergyHistory.preparationOfSkin}
                                onChange={e => partChange({
                                    allergyHistory: {
                                        ...moduleValue.current!.allergyHistory,
                                        preparationOfSkin: e.target.value
                                    }
                                })}
                            >
                                <Radio value={"备皮"}>备皮</Radio>
                                <Radio value={"未备皮"}>未备皮</Radio>
                            </Radio.Group>
                        </Col>
                        <Col flex={"auto"}>

                        </Col>
                    </Row>
                    <Row justify={'start'} align={'middle'}>
                        <Col
                            span={2}
                        >
                            <Text>术前诊断</Text>
                        </Col>
                        <Col span={14}>
                            <AutoComplete
                                options={options.beforeDiagnosis.map((item) => ({value: item}))}
                                defaultValue={defaultValue.beforeDiagnosis}
                                style={{width: "100%"}}
                                onSelect={(e) => {
                                    partChange({beforeDiagnosis:e})
                                }}
                                onSearch={(v) => {
                                    partChange({beforeDiagnosis: v})
                                }}
                            />
                        </Col>
                    </Row>
                    <Row justify={'start'} align={'middle'}>
                        <Col
                            span={2}
                        >
                            <Text>手术名称</Text>
                        </Col>
                        <Col span={14}>
                            <AutoComplete
                                options={options.strategyName.map((item) => ({value: item}))}
                                defaultValue={defaultValue.strategyName}
                                style={{width: "100%"}}
                                onSelect={(e) => {
                                    partChange({strategyName:e})
                                }}
                                onSearch={(v) => {
                                    partChange({strategyName: v})
                                }}
                            />
                        </Col>
                    </Row>
                    <Row justify={'start'} align={'middle'}>
                        <Col
                            span={2}
                        >
                            <Text strong>1.入室时间：</Text>
                        </Col>
                        <Col span={4}>
                            <DatePicker
                                defaultValue={formatDateStrInput(defaultValue.entryTime.entryTime, DATE_FORMAT)}
                                style={{width:"100%"}}
                                showTime={{format: "HH:mm"}}
                                onChange={(v, dateStr: string | string[]) => {
                                    let newDate;
                                    if (typeof dateStr === 'string') {
                                        newDate = dateStr;
                                    } else {
                                        newDate = dateStr[0]
                                    }
                                    partChange({entryTime: {
                                            ...moduleValue.current!.entryTime,
                                            entryTime: newDate
                                        }})
                                }}
                            />
                        </Col>
                        <Col
                            span={2}
                        >
                            <Text>手术间</Text>
                        </Col>
                        <Col span={3}>
                            <Input
                                defaultValue={defaultValue.entryTime.room}
                                onChange={e => partChange({
                                    entryTime: {
                                        ...moduleValue.current!.entryTime,
                                        room: e.target.value
                                    }})}
                            />
                        </Col>
                        <Col
                            span={2}
                        >
                            手术开始时间
                        </Col>
                        <Col>
                            <TimePicker
                                defaultValue={formatDateStrInput(defaultValue.entryTime.startTime, "HH:mm")}
                                format={'HH:mm'}
                                onChange={(time, timeStr) => {
                                    if (typeof timeStr === 'string') {
                                        partChange({entryTime: {...moduleValue.current?.entryTime, startTime: timeStr}})
                                    } else {
                                        partChange({entryTime: {...moduleValue.current?.entryTime, startTime: timeStr[0]}})
                                    }
                                }}
                            />
                        </Col>
                        <Col
                            span={2}
                        >
                            手术结束时间
                        </Col>
                        <Col>
                            <TimePicker
                                multiple={false}
                                defaultValue={formatDateStrInput(defaultValue.entryTime.endTime, "HH:mm")}
                                format={'HH:mm'}
                                onChange={(time, timeStr) => {
                                    if (typeof timeStr === 'string') {
                                        partChange({entryTime: {...moduleValue.current?.entryTime, endTime: timeStr}})
                                    } else {
                                        partChange({entryTime: {...moduleValue.current?.entryTime, endTime: timeStr[0]}})
                                    }
                                }}
                            />
                        </Col>
                        <Col
                            span={2}
                        >
                            离室时间
                        </Col>
                        <Col>
                            <TimePicker
                                defaultValue={formatDateStrInput(defaultValue.entryTime.leftTime, "HH:mm")}
                                format={'HH:mm'}
                                onChange={(time, timeStr) => {
                                    if (typeof timeStr === 'string') {
                                        partChange({entryTime: {...moduleValue.current!.entryTime, leftTime: timeStr}})
                                    } else {
                                        partChange({entryTime: {...moduleValue.current!.entryTime, leftTime: timeStr[0]}})
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <Row justify={'start'} align={'middle'}>
                        <Col
                            span={2}
                        >
                            <Text strong>2.居麻用药：</Text>
                        </Col>
                        <Col span={6}>
                            <Flex align={"center"} justify={"space-evenly"}>
                                <Checkbox
                                    defaultChecked={defaultValue.localanesthesiaDrugs.ropivacaineChecked}
                                    onChange={e => {
                                        partChange({
                                            localanesthesiaDrugs: {
                                                ...moduleValue.current!.localanesthesiaDrugs,
                                                ropivacaineChecked: e.target.checked
                                            }
                                        })
                                    }}
                                >
                                    罗哌卡因
                                </Checkbox>
                                <Checkbox
                                    defaultChecked={defaultValue.localanesthesiaDrugs.lidocaineChecked}
                                    onChange={e => {
                                        partChange({
                                            localanesthesiaDrugs: {
                                                ...moduleValue.current!.localanesthesiaDrugs,
                                                lidocaineChecked: e.target.checked
                                            }
                                        })
                                    }}
                                >
                                    利多卡因
                                </Checkbox>
                                <Checkbox
                                    defaultChecked={defaultValue.localanesthesiaDrugs.adrenalineChecked}
                                    onChange={e => {
                                        partChange({
                                            localanesthesiaDrugs: {
                                                ...moduleValue.current!.localanesthesiaDrugs,
                                                adrenalineChecked: e.target.checked
                                            }
                                        })
                                    }}
                                >
                                    肾上腺素
                                </Checkbox>
                            </Flex>
                        </Col>
                        <Col span={2}>
                            <Text strong>体位:</Text>
                        </Col>
                        <Col span={4}>
                            <Input
                                defaultValue={defaultValue.localanesthesiaDrugs.bodyPosition}
                                onChange={e=>partChange({
                                    localanesthesiaDrugs: {
                                        ...moduleValue.current!.localanesthesiaDrugs,
                                        bodyPosition: e.target.value
                                    }
                                })}
                            />
                        </Col>
                        <Col span={2}>
                            <Text strong>术毕皮肤情况:</Text>
                        </Col>
                        <Col span={4}>
                            <Input
                                defaultValue={defaultValue.localanesthesiaDrugs.skinCondition}
                                onChange={e=>partChange({
                                    localanesthesiaDrugs: {
                                        ...moduleValue.current!.localanesthesiaDrugs,
                                        skinCondition: e.target.value
                                    }
                                })}
                            />
                        </Col>
                    </Row>
                    <Row justify={'start'} align={'middle'}>
                        <Col
                            span={2}
                        >
                            <Text strong>3.术中使用：</Text>
                        </Col>
                        <Col span={8}>
                            <Flex align={"center"} justify={"space-evenly"}>
                                <Checkbox
                                    defaultChecked={defaultValue.usedDuringStrategy.bipolarElectrocoagulationChecked}
                                    onChange={e => {
                                        partChange({
                                            usedDuringStrategy: {
                                                ...moduleValue.current!.usedDuringStrategy,
                                                bipolarElectrocoagulationChecked: e.target.checked
                                            }
                                        })
                                    }}
                                >
                                    双极电凝
                                </Checkbox>
                                <Checkbox
                                    defaultChecked={defaultValue.usedDuringStrategy.penShapedElectrocoagulationChecked}
                                    onChange={e =>
                                        partChange({
                                            usedDuringStrategy: {
                                                ...moduleValue.current!.usedDuringStrategy,
                                                penShapedElectrocoagulationChecked: e.target.checked
                                            }
                                        })
                                    }
                                >
                                    笔式电凝
                                </Checkbox>
                                <Checkbox
                                    defaultChecked={defaultValue.usedDuringStrategy.electricIonChecked}
                                    onChange={e => partChange({
                                        usedDuringStrategy: {
                                            ...moduleValue.current!.usedDuringStrategy,
                                            electricIonChecked: e.target.checked
                                        }
                                    })}
                                >
                                    电离子
                                </Checkbox>
                                <Checkbox
                                    defaultChecked={defaultValue.usedDuringStrategy.electrosurgicalKnifeChecked}
                                    onChange={e => partChange({
                                        usedDuringStrategy: {
                                            ...moduleValue.current!.usedDuringStrategy,
                                            electrosurgicalKnifeChecked: e.target.checked
                                        }
                                    })}
                                >
                                    电刀
                                </Checkbox>
                            </Flex>
                        </Col>
                        <Col span={2}>
                            <Text>贴负极板位置:</Text>
                        </Col>
                        <Col span={4}>
                            <Input
                                defaultValue={defaultValue.usedDuringStrategy.negativePosition}
                                onChange={e=>partChange({
                                    usedDuringStrategy: {
                                        ...moduleValue.current!.usedDuringStrategy,
                                        negativePosition: e.target.value
                                    }
                                })}
                            />
                        </Col>
                    </Row>
                    <Row justify={'start'} align={'middle'}>
                        <Col
                            span={2}
                        >
                            <Text strong>4.术区消毒液：</Text>
                        </Col>
                        <Col span={4}>
                            <Flex align={"center"} justify={"space-evenly"}>
                                <Checkbox
                                    defaultChecked={defaultValue.surgicalSiteDisinfectant.zeroPointFiveIodophorChecked}
                                    onChange={e => partChange({
                                        surgicalSiteDisinfectant: {
                                            ...moduleValue.current!.surgicalSiteDisinfectant,
                                            zeroPointFiveIodophorChecked: e.target.checked
                                        }
                                    })}
                                >
                                    0.5%碘伏
                                </Checkbox>
                                <Checkbox
                                    defaultChecked={defaultValue.surgicalSiteDisinfectant.bromogeramineChecked}
                                    onChange={e => partChange({
                                        surgicalSiteDisinfectant: {
                                            ...moduleValue.current!.surgicalSiteDisinfectant,
                                            bromogeramineChecked: e.target.checked
                                        }
                                    })}
                                >
                                    0.1%新洁尔灭
                                </Checkbox>
                            </Flex>
                        </Col>
                        <Col span={2}>
                            <Text>其它</Text>
                        </Col>
                        <Col span={6}>
                            <Input
                                defaultValue={defaultValue.surgicalSiteDisinfectant.others}
                                onChange={e=> partChange({
                                    surgicalSiteDisinfectant : {
                                        ...moduleValue.current!.surgicalSiteDisinfectant,
                                        others: e.target.value
                                    }
                                })}
                            />
                        </Col>
                    </Row>
                    <Row justify={'start'} align={'middle'}>
                        <Col
                            span={2}
                        >
                            <Text strong>5.引流管：</Text>
                        </Col>
                        <Col span={4}>
                            <Flex align={"center"} justify={"space-evenly"}>
                                <RadioInput
                                    title={""}
                                    value={defaultValue.drainagetube.existChecked}
                                    fatherChange={v => partChange({
                                        drainagetube: {
                                            ...moduleValue.current!.drainagetube,
                                            existChecked: v
                                        }
                                    })}
                                />
                            </Flex>
                        </Col>
                        <Col span={2}>
                            <Text>引流管名称：</Text>
                        </Col>
                        <Col span={4}>
                            <Input
                                defaultValue={defaultValue.drainagetube.name}
                                onChange={e => {
                                    partChange({
                                        drainagetube: {
                                            ...moduleValue.current!.drainagetube,
                                            name: e.target.value
                                        }
                                    })
                                }}
                            />
                        </Col>
                        <Col span={2}>
                            <Text>部位：</Text>
                        </Col>
                        <Col span={4}>
                            <Input
                                defaultValue={defaultValue.drainagetube.position}
                                onChange={e => {
                                    partChange({
                                        drainagetube: {
                                            ...moduleValue.current!.drainagetube,
                                            position: e.target.value
                                        }
                                    })
                                }}
                            />
                        </Col>
                    </Row>
                    <Row justify={'start'} align={'middle'}>
                        <Col
                            span={2}
                        >
                            <Text strong>6.出入量：</Text>
                        </Col>
                        <Col span={2}>
                            <Text>术中出血</Text>
                        </Col>
                        <Col span={3}>
                            <InputNumber
                                min={0}
                                changeOnWheel
                                style={{width:"100%"}}
                                defaultValue={defaultValue.intakeAndOutput.bloodLoss}
                                onChange={e => partChange({
                                    intakeAndOutput: {
                                        ...moduleValue.current!.intakeAndOutput,
                                        bloodLoss: e != null ? e : 0
                                    }
                                })}
                                addonAfter={"ml"}
                            />
                        </Col>
                        <Col span={2}>
                            <Text>输血</Text>
                        </Col>
                        <Col span={3}>
                            <InputNumber
                                changeOnWheel
                                style={{width:"100%"}}
                                defaultValue={defaultValue.intakeAndOutput.bloodTransfusion}
                                onChange={e => partChange({
                                    intakeAndOutput: {
                                        ...moduleValue.current!.intakeAndOutput,
                                        bloodTransfusion: e != null ? e : 0
                                    }
                                })}
                                addonAfter={"ml"}
                            />
                        </Col>
                        <Col span={2}>
                            <Text>输液</Text>
                        </Col>
                        <Col span={3}>
                            <InputNumber
                                changeOnWheel
                                style={{width:"100%"}}
                                defaultValue={defaultValue.intakeAndOutput.fluidVolume}
                                onChange={e => partChange({
                                    intakeAndOutput: {
                                        ...moduleValue.current!.intakeAndOutput,
                                        fluidVolume: e != null ? e : 0
                                    }
                                })}
                                addonAfter={"ml"}
                            />
                        </Col>
                        <Col span={2}>
                            <Text>尿量</Text>
                        </Col>
                        <Col span={3}>
                            <InputNumber
                                changeOnWheel
                                style={{width:"100%"}}
                                defaultValue={defaultValue.intakeAndOutput.urineVolume}
                                onChange={e => partChange({
                                    intakeAndOutput: {
                                        ...moduleValue.current!.intakeAndOutput,
                                        urineVolume: e != null ? e : 0
                                    }
                                })}
                                addonAfter={"ml"}
                            />
                        </Col>
                    </Row>
                    <Row justify={'start'} align={'middle'}>
                        <Col
                            span={2}
                        >
                            <Text strong>7.出室生命体征：</Text>
                        </Col>
                        <Col
                            span={2}
                        >
                            <Text>血压</Text>
                        </Col>
                        <Col span={3}>
                            <Input
                                style={{width:"100%"}}
                                defaultValue={defaultValue.vitalSigns.bloodPressure}
                                onChange={e => partChange({
                                    vitalSigns: {
                                        ...moduleValue.current!.vitalSigns,
                                        bloodPressure: e.target.value
                                    }
                                })}
                                addonAfter={"mmHg"}
                            />
                        </Col>
                        <Col span={2}>
                            <Text>脉搏</Text>
                        </Col>
                        <Col span={3}>
                            <InputNumber
                                min={0}
                                changeOnWheel
                                style={{width:"100%"}}
                                defaultValue={defaultValue.vitalSigns.pulse}
                                onChange={e => partChange({
                                    vitalSigns: {
                                        ...moduleValue.current!.vitalSigns,
                                        pulse: e != null ? e : 0
                                    }
                                })}
                                addonAfter={"次/分"}
                            />
                        </Col>
                        <Col span={2}>
                            <Text>呼吸</Text>
                        </Col>
                        <Col span={3}>
                            <InputNumber
                                min={0}
                                changeOnWheel
                                style={{width:"100%"}}
                                defaultValue={defaultValue.vitalSigns.respiratoryRate}
                                onChange={e => partChange({
                                    vitalSigns: {
                                        ...moduleValue.current!.vitalSigns,
                                        respiratoryRate: e != null ? e : 0
                                    }
                                })}
                                addonAfter={"次/分"}
                            />
                        </Col>
                    </Row>
                    <Row justify={'start'} align={'middle'}>
                        <Col
                            span={2}
                        >
                            <Text strong>8.植入物：</Text>
                        </Col>
                        <Col span={2}>
                            <Text>使用部位：</Text>
                        </Col>
                        <Col span={3}>
                            <Input
                                style={{width:"100%"}}
                                defaultValue={defaultValue.implant.position}
                                onChange={e=>partChange({
                                    implant:{
                                        ...moduleValue.current!.implant,
                                        position: e.target.value
                                    }
                                })}
                            />
                        </Col>
                        <Col span={2}>
                            <Text>厂牌：</Text>
                        </Col>
                        <Col span={3}>
                            <Input
                                style={{width:"100%"}}
                                defaultValue={defaultValue.implant.manufacturerBrand}
                                onChange={e=>partChange({
                                    implant:{
                                        ...moduleValue.current!.implant,
                                        manufacturerBrand: e.target.value
                                    }
                                })}
                            />
                        </Col>
                        <Col span={2}>
                            <Text>名称/规格：</Text>
                        </Col>
                        <Col span={3}>
                            <Input
                                style={{width:"100%"}}
                                defaultValue={defaultValue.implant.nameOrSize}
                                onChange={e=>partChange({
                                    implant:{
                                        ...moduleValue.current!.implant,
                                        nameOrSize: e.target.value
                                    }
                                })}
                            />
                        </Col>
                    </Row>
                    <Row justify={'start'} align={'middle'}>
                        <Col
                            span={2}
                        >
                            <Text strong>9. 冲洗溶液：</Text>
                        </Col>
                        <Col
                            span={10}
                        >
                            <Input
                                defaultValue={defaultValue.irrigationSolution.irrigationSolution}
                                onChange={e=>partChange({
                                    irrigationSolution:{
                                        ...moduleValue.current!.irrigationSolution,
                                        irrigationSolution: e.target.value
                                    }
                                })}
                            />
                        </Col>
                        <Col
                            span={2}
                        >
                            <Text strong>填塞物/部位：</Text>
                        </Col>
                        <Col
                            span={10}
                        >
                            <Input
                                defaultValue={defaultValue.irrigationSolution.fillerOrPosition}
                                onChange={e=>partChange({
                                    irrigationSolution:{
                                        ...moduleValue.current!.irrigationSolution,
                                        fillerOrPosition: e.target.value
                                    }
                                })}
                            />
                        </Col>
                    </Row>
                    <Divider>手术用品清点</Divider>
                    <Row justify={'start'} align={'middle'}>
                        <Col span={12}>
                            <Flex vertical justify={'space-around'} gap={'large'}>
                                <InventoryShowTitle />
                                <InventoryShowItem
                                    title={"刀柄"}
                                    defaultValue={defaultValue.inventoryTable.knifeHandle}
                                    onChange={v =>
                                        inventoryTablePartChange({knifeHandle: v})
                                    }
                                />
                                <InventoryShowItem
                                    title={"剪刀"}
                                    defaultValue={defaultValue.inventoryTable.scissors}
                                    onChange={v =>
                                        inventoryTablePartChange({scissors: v})
                                    }
                                />
                                <InventoryShowItem
                                    title={"镊子"}
                                    defaultValue={defaultValue.inventoryTable.forceps}
                                    onChange={v =>
                                        inventoryTablePartChange({forceps: v})
                                    }
                                />
                                <InventoryShowItem
                                    title={"持针钳"}
                                    defaultValue={defaultValue.inventoryTable.needleHolder}
                                    onChange={v =>
                                        inventoryTablePartChange({needleHolder: v})}
                                />
                                <InventoryShowItem
                                    title={"弯止血钳"}
                                    defaultValue={defaultValue.inventoryTable.curvedHemostat}
                                    onChange={v =>
                                        inventoryTablePartChange({curvedHemostat: v})
                                    }
                                />
                                <InventoryShowItem
                                    title={"巾钳"}
                                    defaultValue={defaultValue.inventoryTable.spongeClamp}
                                    onChange={v =>
                                        inventoryTablePartChange({spongeClamp: v})
                                    }
                                />
                                <InventoryShowItem
                                    title={"组织钳"}
                                    defaultValue={defaultValue.inventoryTable.tissueForceps}
                                    onChange={v =>
                                        inventoryTablePartChange({tissueForceps: v})
                                    }
                                />
                                <InventoryShowItem
                                    title={"卵圆钳"}
                                    defaultValue={defaultValue.inventoryTable.ovalForceps}
                                    onChange={v =>
                                        inventoryTablePartChange({ovalForceps: v})
                                    }
                                />
                                <InventoryShowItem
                                    title={"鼻包"}
                                    defaultValue={defaultValue.inventoryTable.nasalPack}
                                    onChange={v =>
                                        inventoryTablePartChange({nasalPack: v})
                                    }
                                />
                                <InventoryShowItem
                                    title={"吸脂包"}
                                    defaultValue={defaultValue.inventoryTable.liposuctionKit}
                                    onChange={v =>
                                        inventoryTablePartChange({liposuctionKit: v})
                                    }
                                />
                                <InventoryShowItem
                                    title={"特殊器械"}
                                    defaultValue={defaultValue.inventoryTable.specialInstruments}
                                    onChange={v =>
                                        inventoryTablePartChange({specialInstruments: v})
                                    }
                                />
                            </Flex>
                        </Col>
                        <Col span={12}>
                            <Flex vertical justify={'space-around'} gap={'large'}>
                                <InventoryShowTitle />
                                <InventoryShowItem
                                    title={"拉钩"}
                                    defaultValue={defaultValue.inventoryTable.retractor}
                                    onChange={v =>
                                        inventoryTablePartChange({retractor: v})
                                    }
                                />
                                <InventoryShowItem
                                    title={"堵头"}
                                    defaultValue={defaultValue.inventoryTable.stopper}
                                    onChange={v =>
                                        inventoryTablePartChange({stopper: v})
                                    }
                                />
                                <InventoryShowItem
                                    title={"转换头"}
                                    defaultValue={defaultValue.inventoryTable.adapter}
                                    onChange={v =>
                                        inventoryTablePartChange({adapter: v})
                                    }
                                />
                                <InventoryShowItem
                                    title={"LK吸脂针"}
                                    defaultValue={defaultValue.inventoryTable.lkNeedle}
                                    onChange={v =>
                                        inventoryTablePartChange({lkNeedle: v})
                                    }
                                />
                                <InventoryShowItem
                                    title={"大纱"}
                                    defaultValue={defaultValue.inventoryTable.largeGauze}
                                    onChange={v =>
                                        inventoryTablePartChange({largeGauze: v})
                                    }
                                />
                                <InventoryShowItem
                                    title={"小纱"}
                                    defaultValue={defaultValue.inventoryTable.smallGauze}
                                    onChange={v =>
                                        inventoryTablePartChange({smallGauze: v})
                                    }
                                />
                                <InventoryShowItem
                                    title={"棉球"}
                                    defaultValue={defaultValue.inventoryTable.cottonBall}
                                    onChange={v =>
                                        inventoryTablePartChange({cottonBall: v})
                                    }
                                />
                                <InventoryShowItem
                                    title={"脑棉片"}
                                    defaultValue={defaultValue.inventoryTable.brainCottonPad}
                                    onChange={v =>
                                        inventoryTablePartChange({brainCottonPad: v})
                                    }
                                />
                                <InventoryShowItem
                                    title={"缝针"}
                                    defaultValue={defaultValue.inventoryTable.sutureNeedle}
                                    onChange={v =>
                                        inventoryTablePartChange({sutureNeedle: v})
                                    }
                                />
                                <InventoryShowItem
                                    title={"刀片"}
                                    defaultValue={defaultValue.inventoryTable.blade}
                                    onChange={v =>
                                        inventoryTablePartChange({blade: v})
                                    }
                                />
                                <InventoryShowItem
                                    title={"针头"}
                                    defaultValue={defaultValue.inventoryTable.needle}
                                    onChange={v =>
                                        inventoryTablePartChange({needle: v})
                                    }
                                />
                            </Flex>
                        </Col>
                    </Row>
                    <Divider>手术记录</Divider>
                    <Row justify={'start'} align={'middle'}>
                        <Col
                            span={2}
                        >
                            <Text>术中特殊记录</Text>
                        </Col>
                        <Col span={6}>
                            <RadioInput
                                title={"隆鼻手术鼻腔填塞物是否取出"}
                                value={defaultValue.specialRecord.nasalFilterChecked}
                                fatherChange={v => {
                                    partChange({
                                        specialRecord: {
                                            ...moduleValue.current!.specialRecord,
                                            nasalFilterChecked: v
                                        }
                                    })
                                }}
                            />
                        </Col>
                        <Col span={14}>
                            <TextArea
                                rows={1}
                                defaultValue={defaultValue.specialRecord.others}
                                onChange={v => partChange({
                                    specialRecord: {
                                        ...moduleValue.current!.specialRecord,
                                        others: v.target.value
                                    }
                                })}
                            />
                        </Col>
                    </Row>
                    <Row justify={'start'} align={'middle'}>
                        <Col span={2}>
                            主刀医生
                        </Col>
                        <Col span={2}>
                            <SelectAdd
                                style={{width: 150}}
                                options={options.operator}
                                defaultValue={defaultValue.operator}
                                fatherChange={v => partChange({
                                    operator: v
                                })}
                            />
                        </Col>
                        <Col span={2}>
                            第一助手
                        </Col>
                        <Col span={3}>
                            <SelectAdd
                                style={{width: 150}}
                                options={options.firstAssistant}
                                defaultValue={defaultValue.firstAssistant}
                                fatherChange={v => partChange({
                                    firstAssistant: v
                                })}
                            />
                        </Col>
                        <Col span={2}>
                            第二助手
                        </Col>
                        <Col span={3}>
                            <SelectAdd
                                style={{width: 150}}
                                options={options.firstAssistant}
                                defaultValue={defaultValue.secondAssistant}
                                fatherChange={v => partChange({
                                    secondAssistant: v
                                })}
                            />
                        </Col>
                        <Col span={1}>
                            洗手护士
                        </Col>
                        <Col span={3}>
                            <SelectAdd
                                style={{width: 150}}
                                options={options.handWashingNurse}
                                defaultValue={defaultValue.handWashingNurse}
                                fatherChange={v => partChange({
                                    handWashingNurse: v
                                })}
                            />
                        </Col>
                        <Col span={2}>
                            巡回护士
                        </Col>
                        <Col span={3}>
                            <SelectAdd
                                options={options.rovingNurse}
                                defaultValue={defaultValue.rovingNurse}
                                fatherChange={v => partChange({
                                    rovingNurse: v
                                })}
                            />
                        </Col>
                    </Row>
                    <Row justify={'start'} align={'middle'}>
                        <Col span={2}>
                            麻醉医师
                        </Col>
                        <Col span={3}>
                            <SelectAdd
                                options={options.anesthesiologist}
                                defaultValue={moduleValue.current.anesthesiologist}
                                fatherChange={(v) => {
                                    partChange({anesthesiologist: v})
                                }}
                            />
                        </Col>
                        <Col span={2}>
                            麻醉方式
                        </Col>
                        <Col span={5}>
                            <AutoComplete
                                options={options.anestheticMode.map((item) => ({value: item}))}
                                defaultValue={defaultValue.anestheticMode}
                                style={{width: "100%"}}
                                onSelect={(e) => {
                                    partChange({anestheticMode: e})
                                }}
                                onSearch={(e) => {
                                    partChange({anestheticMode: e})
                                }}
                            />
                        </Col>
                        <Col span={1}>
                            手术步骤
                        </Col>
                        <Col span={11}>
                            <StrategyStepsComponents
                                defaultValue={moduleValue.current?.steps}
                                options = {options.steps}
                                onChange={(v) => {
                                    partChange({steps: v})
                                }}
                            />
                        </Col>
                    </Row>
                </Flex>
            </>
        )
    }
}