import {
    Detail,
    FullDetail,
    GenerateManufacturerOptions,
    GenerateProductionBatchNumOptionsByManufacturer, HandleProductionBatchNumChange, InitDetailDynamicRow
} from "../../pages/Admin/common";
import {AutoComplete, Col, Input, InputNumber} from "antd";
import {
    spanExpirationDateStr,
    spanFormat,
    spanGood,
    spanManufacturer,
    spanNum,
    spanProductionBatchNumStr, spanProductionDateStr
} from "../../pages/Admin/Operation/common";
import SelectAdd from "../../pages/Admin/Details/ProfileView/SelectAdd";
import React, {useState} from "react";


interface dynamicInterface {
    defaultValue?: FullDetail,
    goodNamesOptions?: string[],
    specificationOptions?: string[],
    detailOptions?: Detail[],
    onChange(newValue: FullDetail): void,
}

export default function ({defaultValue, goodNamesOptions, specificationOptions, detailOptions, onChange}: dynamicInterface) {

    /**
     * 记录本组建内部的值
     */
    const [moduleValue, setModuleValue] = useState<FullDetail | undefined>(defaultValue)

    /**
     * 组装组建内部值变化，并周知父组件
     * @param newValue
     */
    const partChange = (newValue: Partial<FullDetail>) => {
        let newModuleValue: FullDetail | undefined = moduleValue;
        if (newModuleValue === undefined) {
            newModuleValue =
                {
                    ...InitDetailDynamicRow(),
                    ...newValue
                }
        } else {
            newModuleValue = {
                ...moduleValue,
                ...newValue
            }
        }
        setModuleValue(newModuleValue);
        onChange(newModuleValue);
    }

    if (moduleValue === undefined) {
        return (
            <>
                加载中
            </>
        )
    } else {
        return (
            <>
                <Col span={spanGood}>
                    <SelectAdd
                        style={{
                            width: "90%"
                        }}
                        options={goodNamesOptions!}
                        defaultValue={moduleValue.goodNames === undefined ? "" : moduleValue.goodNames}
                        value={moduleValue.goodNames}
                        fatherChange={(v) => {
                            partChange({goodNames: v})
                        }}
                    />
                </Col>
                <Col span={spanFormat}>
                    <SelectAdd
                        style={{
                            width: "90%"
                        }}
                        options={specificationOptions!}
                        defaultValue={moduleValue.specificationName === undefined ? "" : moduleValue.specificationName}
                        value={moduleValue.specificationName}
                        fatherChange={(v) => {
                            partChange({specificationName: v})
                        }}
                    />
                </Col>
                <Col span={spanNum}>
                    <InputNumber
                        style={{
                            width: "80%"
                        }}
                        disabled={moduleValue.specificationName === undefined}
                        min={0}
                        changeOnWheel
                        defaultValue={moduleValue.num}
                        onChange={v => {
                            partChange({num: v === null ? undefined : v})
                        }}
                    />
                </Col>
                <Col span={spanManufacturer}>
                    <AutoComplete
                        style={{
                            width: "90%"
                        }}
                        disabled={moduleValue.specificationName === undefined}
                        options={GenerateManufacturerOptions(detailOptions)}
                        defaultValue={moduleValue?.manufacturer}
                        onSearch={v => {
                            partChange({
                                manufacturer: v,
                                productionBatchNumStr: undefined,
                                productionDateStr: undefined,
                                expirationDateStr: undefined
                            })
                        }}
                        onSelect={(value: string) => {
                            partChange({
                                manufacturer: value,
                                productionBatchNumStr: undefined,
                                productionDateStr: undefined,
                                expirationDateStr: undefined
                            })
                        }
                        }
                    />
                </Col>
                <Col span={spanProductionBatchNumStr}>
                    <AutoComplete
                        style={{
                            width: "90%"
                        }}
                        disabled={moduleValue.manufacturer === undefined}
                        options={GenerateProductionBatchNumOptionsByManufacturer(moduleValue.manufacturer, detailOptions)}
                        value={moduleValue?.productionBatchNumStr}
                        onSearch={v => {
                            partChange({
                                productionBatchNumStr: v,
                                productionDateStr: undefined,
                                expirationDateStr: undefined
                            })
                        }}
                        onSelect={(value: string) => {
                            let newValue: FullDetail;
                            // 寻找选中了哪个选项
                            let selectedDetail = HandleProductionBatchNumChange(moduleValue.manufacturer,
                                value, detailOptions);
                            if (selectedDetail !== undefined) {
                                newValue = {
                                    ...moduleValue,
                                    productionBatchNumStr: selectedDetail.productionBatchNumStr,
                                    productionDateStr: selectedDetail.productionDateStr,
                                    expirationDateStr: selectedDetail.expirationDateStr,
                                }
                            } else {
                                newValue = {
                                    ...moduleValue,
                                    productionBatchNumStr: undefined,
                                    productionDateStr: undefined,
                                    expirationDateStr: undefined,
                                }
                            }
                            partChange(newValue);
                        }}
                    />
                </Col>
                <Col span={spanProductionDateStr}>
                    <Input
                        style={{
                            width: "90%"
                        }}
                        disabled={moduleValue.manufacturer === undefined || moduleValue.productionBatchNumStr === undefined}
                        defaultValue={moduleValue.productionDateStr}
                        value={moduleValue.productionDateStr}
                        onChange={v => {
                            partChange({
                                productionDateStr: v.target.value
                            })
                        }}
                    />
                </Col>
                <Col span={spanExpirationDateStr}>
                    <Input
                        style={{
                            width: "90%"
                        }}
                        disabled={moduleValue.manufacturer === undefined || moduleValue.productionBatchNumStr === undefined}
                        defaultValue={moduleValue.expirationDateStr}
                        value={moduleValue.expirationDateStr}
                        onChange={v => {
                            partChange({
                                expirationDateStr: v.target.value
                            })
                        }}
                    />
                </Col>
            </>
        )
    }


}