import {EditOptions, TotalProfile} from "../../common";
import {AutoComplete, Button, Col, DatePicker, Input, InputNumber, Row, Select, Tooltip, Typography} from 'antd';
import React, {useEffect, useState} from "react";
import SelectAdd from "./SelectAdd";
import "./index.css"
import {fileDownLoad} from "../common";

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Text, Link } = Typography

interface P {
    totalProfiles: TotalProfile,
    options: EditOptions,
    fatherChange: (newValue:TotalProfile) => void
    saveDraft: () => void
}

export default function ({totalProfiles, options, fatherChange, saveDraft}:P) {


    /**
     * 设置术后诊断和术前诊断一致
     */
    const [afterDiagnosisStr, setAfterDiagnosisStr] = useState(totalProfiles.afterDiagnosis);

    useEffect(() => {
        setAfterDiagnosisStr(totalProfiles.afterDiagnosis)
    }, [totalProfiles.afterDiagnosis])

    /**
     * 格式化日期，转换成病例需要的样式
     * @param dateStr
     */
    function dateFormat(dateStr:string|string[]):string {
        let tempDateStr;
        if (typeof dateStr === 'string') {
            tempDateStr = dateStr;
        } else {
            tempDateStr = dateStr[0];
        }
        let date = new Date(tempDateStr);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let hour = date.getHours();
        let minutes = date.getMinutes();

        // 0~9月前面加0
        let monthStr = String(month);
        if (monthStr.length < 2) {
            monthStr = "0" + monthStr;
        }

        // 0~9小时的前面加零
        let hourStr = String(hour);
        if (hour < 10) {
            hourStr = "0" + hourStr;
        }

        // 0~9分钟前面加0
        let minutesStr: string = String(minutes);
        if (minutes < 10) {
            minutesStr = "0" + minutesStr;
        }

        return String(year) + "年" + monthStr + "月" + String(day) + "日 " + hourStr + "时" + minutesStr + "分";
    }

    return (
        <>
            <Row className="rowClass" justify="start" align="middle">
                <Col span={1}>
                    <div>
                        姓名：
                    </div>
                </Col>
                <Col span={3}>
                    <Input
                        style={{maxWidth:"10vw"}}
                        defaultValue={totalProfiles.name}
                        value={totalProfiles.name}
                        onChange={(e) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.name = e.target.value;
                            fatherChange(newTotalProfile)
                        }}
                    />
                </Col>
                <Col span={1} >
                    性别
                </Col>
                <Col span={3} >
                    <Input
                        style={{maxWidth:"10vw"}}
                        defaultValue={totalProfiles.gender}
                        onChange={(e) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.gender = e.target.value;
                            fatherChange(newTotalProfile)
                        }}
                    />
                </Col>
                <Col span={1} >
                    年龄
                </Col>
                <Col span={3} >
                    <InputNumber
                        changeOnWheel
                        value={totalProfiles.age}
                        style={{maxWidth:"10vw"}}
                        // defaultValue={calAge(totalProfiles.birthday, new Date())}
                        onChange={(e:number | null) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.age = e === null ? 0 : e;
                            fatherChange(newTotalProfile)
                        }}
                    />
                </Col>
                <Col span={1} >
                    病历号
                </Col>
                <Col span={3} >
                    <Input
                        style={{maxWidth:"10vw"}}
                        defaultValue={totalProfiles.profileId}
                        onChange={(e) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.profileId = e.target.value;
                            fatherChange(newTotalProfile)
                        }}
                    />
                </Col>
            </Row>
            <Row className="rowClass" justify="start" align="middle">
                <Col span={1}>
                    手术时间
                </Col>
                <Col span={3}>
                    <Text >
                        {totalProfiles.startTime}
                    </Text>
                </Col>
                <Col span={1}>
                    -
                </Col>
                <Col span={3}>
                    <Text >
                        {totalProfiles.endTime}
                    </Text>
                </Col>
                <Col span={7}>
                    <RangePicker
                        showTime={{format: 'HH:mm'}}
                        format="YYYY-MM-DD HH:mm"
                        onChange={(value, dateStr) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.startTime = dateFormat(dateStr[0]);
                            newTotalProfile.endTime = dateFormat(dateStr[1]);
                            fatherChange(newTotalProfile);
                        }}
                     />
                </Col>
            </Row>
            <Row
                className="rowClass"
                align="middle"
                justify="start"
            >
                <Col span={1}>
                    术前诊断
                </Col>
                <Col span={10}>
                    <AutoComplete
                        options={options.beforeDiagnosis.map((item) => ({value: item}))}
                        defaultValue={totalProfiles.beforeDiagnosis}
                        value={totalProfiles.beforeDiagnosis}
                        style={{width: "100%"}}
                        onSelect={(e) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.beforeDiagnosis = e;
                            newTotalProfile.afterDiagnosis = e; //术前术后诊断保持一致
                            setAfterDiagnosisStr(e);
                            fatherChange(newTotalProfile);
                        }}
                        onSearch={(value) => {
                            setAfterDiagnosisStr(value);
                            fatherChange({...totalProfiles, beforeDiagnosis:value, afterDiagnosis:value})
                        }}
                    />
                </Col>
            </Row>
            <Row
                className="rowClass"
                justify="start"
                align="middle"
            >
                <Col span={1}>
                    术后诊断
                </Col>
                <Col span={10} >
                    <AutoComplete
                        options={options.afterDiagnosis.map((item) => ({value: item}))}
                        // defaultValue={totalProfiles.afterDiagnosis}
                        style={{width: "100%"}}
                        value={afterDiagnosisStr}
                        onSelect={(e) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.afterDiagnosis = e;
                            setAfterDiagnosisStr(e);
                            fatherChange(newTotalProfile);
                        }}
                        onSearch={(value) =>  {
                            setAfterDiagnosisStr(value);
                            fatherChange({...totalProfiles, afterDiagnosis: value})
                        }}
                    />
                </Col>
            </Row>
            <Row
                className="rowClass"
                justify="start"
                align="middle"
            >
                <Col span={1}>
                    手术名称
                </Col>
                <Col span={10} >
                    <AutoComplete
                        options={options.strategyName.map((item) => ({value: item}))}
                        defaultValue={totalProfiles.strategyName}
                        value={totalProfiles.strategyName}
                        style={{width: "100%"}}
                        onSelect={(e) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.strategyName = e;
                            fatherChange(newTotalProfile);
                        }}
                        onSearch={(value) => {
                            fatherChange({...totalProfiles, strategyName: value})
                        }}
                    />
                </Col>
            </Row>
            <Row
                className="rowClass"
                justify="start"
                align="middle"
            >
                <Col span={1}>
                    麻醉方式
                </Col>
                <Col span={10} >
                    <AutoComplete
                        options={options.anestheticMode.map((item) => ({value: item}))}
                        defaultValue={totalProfiles.anestheticMode}
                        value={totalProfiles.anestheticMode}
                        style={{width: "100%"}}
                        onSelect={(e) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.anestheticMode = e;
                            fatherChange(newTotalProfile);
                        }}
                    />
                </Col>
            </Row>
            <Row
                className="rowClass"
                justify="start"
                align="middle"
            >
                <Col span={1}>
                    手术医师
                </Col>
                <Col span={3} >
                    <SelectAdd
                        options={options.operator}
                        defaultValue={totalProfiles.operator}
                        value={totalProfiles.operator}
                        fatherChange={(v) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.operator = v;
                            fatherChange(newTotalProfile)
                        }}
                    />
                </Col>
                <Col span={1}>
                    一助
                </Col>
                <Col span={3} >
                    <SelectAdd
                        options={options.firstAssistant}
                        defaultValue={totalProfiles.firstAssistant}
                        value={totalProfiles.firstAssistant}
                        fatherChange={(v) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.firstAssistant = v;
                            fatherChange(newTotalProfile)
                        }}
                    />
                </Col>
            </Row>
            <Row
                className="rowClass"
                justify="start"
                align="middle"
            >
                <Col span={1}>
                    麻醉医师
                </Col>
                <Col span={3} >
                    <SelectAdd
                        options={options.anesthesiologist}
                        defaultValue={totalProfiles.anesthesiologist}
                        value={totalProfiles.anesthesiologist}
                        fatherChange={(v) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.anesthesiologist = v;
                            fatherChange(newTotalProfile)
                        }}
                    />
                </Col>
                <Col span={1}>
                    洗手护士
                </Col>
                <Col span={3} >
                    <SelectAdd
                        options={options.handWashingNurse}
                        defaultValue={totalProfiles.handWashingNurse}
                        value={totalProfiles.handWashingNurse}
                        fatherChange={(v) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.handWashingNurse = v;
                            fatherChange(newTotalProfile)
                        }}
                    />
                </Col>
                <Col span={1}>
                    巡回护士
                </Col>
                <Col span={3} >
                    <SelectAdd
                        options={options.rovingNurse}
                        defaultValue={totalProfiles.rovingNurse}
                        value={totalProfiles.rovingNurse}
                        fatherChange={(v) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.rovingNurse = v;
                            fatherChange(newTotalProfile)
                        }}
                    />
                </Col>
            </Row>
            <Row
                className="rowClass"
                align="middle"
                justify="start"
            >
                <Col span={1}>
                    手术步骤
                </Col>
                <Col span={10} >
                    {totalProfiles.steps.steps.map((e, index) => {
                        return (
                            <TextArea
                                style={{marginTop: "10px"}}
                                defaultValue={e}
                                value={e}
                                rows={1}
                                onChange={(v) => {
                                    let newTotalProfile = totalProfiles;
                                    newTotalProfile.steps.steps[index] = v.target.value;
                                    fatherChange(newTotalProfile)
                                }}
                            />
                        )
                    })}
                </Col>
                <Col span={1} >
                    <Select
                        style={{width:"200px"}}
                        options={options.steps.map(e => ({
                            label: e.name,
                            value: e.name
                        }))}
                        onChange={(v) => {
                            let newTotalProfile = totalProfiles;
                            newTotalProfile.steps = options.steps.find((item) => {
                                return item.name === v
                            })!;
                            fatherChange(newTotalProfile)
                        }}
                    />
                </Col>
            </Row>
            <Row
                className="rowClass"
                justify="start"
                align="middle"
            >
                <Col span={1}>
                    患者签字
                </Col>
                <Col span={1}>
                    记录时间
                </Col>
                <Col span={3}>
                    <Text >
                        {totalProfiles.recordTime}
                    </Text>
                </Col>
                <Col span={5}>
                    {/*<Tooltip title={"修改记录时间会重新计算年龄，新的年龄以记录时间为基准计算。"}>*/}
                        <DatePicker
                            style={{width:"100%"}}
                            showTime={{ format: 'HH:mm' }}
                            onChange={(value, dateStr: string | string[]) => {
                                let newTotalProfile = totalProfiles;
                                newTotalProfile.recordTime = dateFormat(dateStr);
                                // 修改记录时间重新计算年龄
                                let newDate;
                                if (typeof dateStr === 'string') {
                                    newDate = dateStr;
                                } else {
                                    newDate = dateStr[0]
                                }
                                // newTotalProfile.age = CalAge(totalProfiles.birthday, new Date(newDate));
                                fatherChange(newTotalProfile);
                            }}
                        />
                    {/*</Tooltip>*/}
                </Col>
            </Row>
            <Button
                type="primary"
                onClick={() => {
                    fileDownLoad(totalProfiles)
                }}
            >下载手术病例</Button>
            <Button
                style={{
                    marginLeft:"5px",
                    backgroundColor: "#00ff37"
                }}
                onClick={saveDraft}
            >
                保存草稿-{totalProfiles.name}
            </Button>
        </>
    )
}
