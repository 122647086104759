import {Button} from 'antd'
import handleAxios from "../../Http/request";

function handleClick() {
    var ret = handleAxios.get("/api/hello");
    console.log(ret);
}

function handleCookie() {
    var ret =handleAxios.get("/api/setCookie");
    console.log(ret);
}

export default function () {
    return (
        <div>
            <Button onClick={()=> handleClick()}>点击测试</Button>
            <Button onClick={() => {
                handleCookie()
            }} >
                点击种上cookie
            </Button>
        </div>
    )
}
