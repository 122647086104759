import {
    BeforeOperation,
    BeforePatientLeft,
    BeforeAnesthesia,
    InitBeforeAnesthesia, InitBeforeOperation, InitBeforePatientLeft,
    InitSafetyCheckOfOperation,
    SafetyCheckOfOperation,
    YMDFormat
} from "../../Details/common";
import {
    AutoComplete,
    Col,
    DatePicker,
    Input,
    InputNumber,
    Row,
    Select,
    Typography,
    Flex,
    Radio,
    Divider,
    RadioChangeEvent
} from "antd";
import React, {useRef} from "react";
import {DATE_FORMAT, EditOptions} from "../../common";
import SelectAdd from "../../Details/ProfileView/SelectAdd";
import RadioInput from "../../../../componts/RadioInput";
import TextArea from "antd/es/input/TextArea";
import {formatDateStrInput} from "../common";
const { Text} = Typography
interface P {
    defaultValue?:SafetyCheckOfOperation
    options: EditOptions
    fatherChange(newValue: SafetyCheckOfOperation):void
}

/**
 * 手术安全核查表组建
 * @param defaultValue
 * @param options
 * @param fatherChange
 */
export default function ({defaultValue, options, fatherChange}:P) {

    /**
     * 保存模块当前值
     */
    const moduleValue = useRef<SafetyCheckOfOperation | undefined>(defaultValue);

    /**
     * 更新模块中的最新值
     * @param newValue
     */
    const partChange = (newValue: Partial<SafetyCheckOfOperation>) : void => {
        let newModuleValue: SafetyCheckOfOperation;
        if (moduleValue.current === undefined) {
            newModuleValue = {
                ...InitSafetyCheckOfOperation(),
                ...newValue
            }
        } else {
            newModuleValue = {
                ...moduleValue.current,
                ...newValue
            }
        }
        moduleValue.current = newModuleValue;       
        fatherChange(newModuleValue);
    }

    /**
     * 术前核验部分部分变更
     * @param newValue
     */
    const beforeAnesthesiaPartChange = (newValue: Partial<BeforeAnesthesia>) : void => {
        let newBeforeAnesthesia: BeforeAnesthesia;
        if (moduleValue.current?.beforeAnesthesia === undefined) {
            newBeforeAnesthesia = {
                ...InitBeforeAnesthesia(),
                ...newValue
            }
        } else {
            newBeforeAnesthesia = {
                ...moduleValue.current?.beforeAnesthesia,
                ...newValue
            }
        }
        partChange({beforeAnesthesia: newBeforeAnesthesia})
    }

    /**
     * 手术开始前核验值改变
     * @param newValue
     */
    const beforeOperationPartChange = (newValue: Partial<BeforeOperation>) : void => {
        let newBeforeOperation: BeforeOperation;
        if (moduleValue.current?.beforeOperation === undefined) {
            newBeforeOperation = {
                ...InitBeforeOperation(),
                ...newValue
            }
        } else {
            newBeforeOperation = {
                ...moduleValue.current?.beforeOperation,
                ...newValue
            }
        }
        partChange({beforeOperation: newBeforeOperation})
    }

    /**
     * 患者离开前核验
     * @param newValue
     */
    const beforePatientLeftPartChange = (newValue: Partial<BeforePatientLeft>) : void => {
        let newBeforePatientLeft: BeforePatientLeft;
        if (moduleValue.current?.beforePatientLeft === undefined) {
            newBeforePatientLeft = {
                ...InitBeforePatientLeft(),
                ...newValue
            }
        } else {
            newBeforePatientLeft = {
                ...moduleValue.current.beforePatientLeft,
                ...newValue
            }
        }
        partChange({beforePatientLeft: newBeforePatientLeft})
    }

    return (
        <>
            {moduleValue.current !== undefined
                && defaultValue !== undefined && (
                <>
                    <Row justify="start"
                         align="middle"
                         style={{
                             marginTop: "10px",
                             marginBottom: "10px"
                         }}
                    >
                        <Col span={2}>
                            姓名
                        </Col>
                        <Col span={3}>
                            <Input
                                defaultValue={defaultValue.name}
                                onChange={(e) => {
                                    partChange({name: e.target.value})
                                }}
                            />
                        </Col>
                        <Col span={2}>
                            性别
                        </Col>
                        <Col span={2}>
                            <Select
                                style={{
                                    width: "100%"
                                }}
                                defaultValue={defaultValue.gender}
                                onChange={(value: string) => {
                                    partChange({gender: value})
                                }}
                                options={[
                                    {value: "男", label:"男"},
                                    {value: "女", label: "女"}
                                ]}
                            />
                        </Col>
                        <Col span={2}>
                            年龄
                        </Col>
                        <Col span={2}>
                            <InputNumber
                                changeOnWheel
                                style={{
                                    width: "100%"
                                }}
                                defaultValue={defaultValue.age}
                                onChange={(e:number | null) => {
                                    partChange({age: e === null ? undefined : e})
                                }}
                                addonAfter={"岁"}
                            />
                        </Col>
                        <Col span={2}>
                            术者
                        </Col>
                        <Col span={6}>
                            <SelectAdd
                                style={{width:"100%"}}
                                options={options.operator}
                                defaultValue={defaultValue.operator}
                                fatherChange={(v) => partChange({operator: v})}
                            />
                        </Col>
                    </Row>
                    <Row
                        justify="start"
                        align="middle"
                        style={{
                            marginTop: "10px",
                            marginBottom: "10px"
                        }}
                    >
                        <Col span={2}>
                            麻醉医师
                        </Col>
                        <Col span={6}>
                            <SelectAdd
                                defaultValue={moduleValue.current?.anesthesiologist!}
                                options={options.anesthesiologist}
                                style={{width: "100%"}}
                                fatherChange={v => {
                                    partChange({anesthesiologist: v})
                                }}
                            />
                        </Col>
                        <Col span={2}>
                            麻醉方式
                        </Col>
                        <Col span={6}>
                            <SelectAdd
                                style={{width:"100%"}}
                                options={options.anestheticMode}
                                defaultValue={defaultValue.anestheticMode}
                                fatherChange={(v) => partChange({anestheticMode: v})}
                            />
                        </Col>
                        <Col span={2}>
                            记录时间
                        </Col>
                        <Col span={4}>
                            <DatePicker
                                style={{width:"100%"}}
                                showTime={{ format: 'HH:mm' }}
                                defaultValue={formatDateStrInput(moduleValue.current?.recordTime, DATE_FORMAT)}
                                onChange={(value, dateStr: string | string[]) => {
                                    if (typeof dateStr === 'string') {
                                        partChange({recordTime: dateStr})
                                    } else {
                                        partChange({recordTime: dateStr[0]})
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <Row
                        justify="start"
                        align="middle"
                        style={{
                            marginTop: "10px",
                            marginBottom: "10px"
                        }}
                    >
                        <Col span={2}>
                            手术名称
                        </Col>
                        <Col span={6} >
                            <AutoComplete
                                options={options.strategyName.map((item) => ({value: item}))}
                                defaultValue={moduleValue.current?.strategyName}
                                style={{width: "100%"}}
                                onSelect={(e) => partChange({strategyName: e})}
                                onSearch={(value) =>
                                    partChange({strategyName: value})
                                }
                            />
                        </Col>
                        <Col span={2}>
                            手术室护士
                        </Col>
                        <Col span={6}>
                            <SelectAdd
                                defaultValue={moduleValue.current?.rovingNurse!}
                                options={options.rovingNurse}
                                style={{width: "100%"}}
                                fatherChange={v => {
                                    partChange({rovingNurse: v})
                                }}
                            />
                        </Col>
                    </Row>

                    <Flex vertical={false} justify={'space-around'} align={"flex-start"}>
                        <Flex vertical justify={'space-evenly'} gap={"large"} style={{width:"90%"}}>
                            <Divider
                                style={{width:"100%"}}
                            > 麻醉实施前 </Divider>
                            <RadioInput
                                title={"患者姓名、性别、年龄正确"}
                                value={moduleValue.current?.beforeAnesthesia?.nameAgeGenderCheck}
                                fatherChange={(newValue: boolean) => {
                                    beforeAnesthesiaPartChange({nameAgeGenderCheck: newValue})
                                }}
                            />
                            <RadioInput
                                title={"手术方式确认"}
                                value={moduleValue.current?.beforeAnesthesia?.modeOfOperationCheck}
                                fatherChange={(newValue: boolean) => {
                                    beforeAnesthesiaPartChange({modeOfOperationCheck: newValue})
                                }}
                            />
                            <RadioInput
                                title={"手术部位与标识正确"}
                                value={moduleValue.current?.beforeAnesthesia?.locationOfOperationCheck}
                                fatherChange={(newValue: boolean) => {
                                    beforeAnesthesiaPartChange({locationOfOperationCheck: newValue})
                                }}
                            />
                            <RadioInput
                                title={"手术知情同意"}
                                value={moduleValue.current?.beforeAnesthesia?.acknowledgeOfOperationCheck}
                                fatherChange={(newValue: boolean) => {
                                    beforeAnesthesiaPartChange({acknowledgeOfOperationCheck: newValue})
                                }}
                            />
                            <RadioInput
                                title={"麻醉知情同意"}
                                value={moduleValue.current?.beforeAnesthesia?.acknowledgeOfAnestheticCheck}
                                fatherChange={(newValue: boolean) => {
                                    beforeAnesthesiaPartChange({acknowledgeOfAnestheticCheck: newValue})
                                }}
                            />
                            <RadioInput
                                title={"麻醉方式确认"}
                                value={moduleValue.current?.beforeAnesthesia?.modeOfAnestheticCheck}
                                fatherChange={(newValue: boolean) => {
                                    beforeAnesthesiaPartChange({modeOfAnestheticCheck: newValue})
                                }}
                            />
                            <RadioInput
                                title={"皮肤是否完整"}
                                value={moduleValue.current?.beforeAnesthesia?.integrityOfSkinCheck}
                                fatherChange={(newValue: boolean) => {
                                    beforeAnesthesiaPartChange({integrityOfSkinCheck: newValue})
                                }}
                            />
                            <RadioInput
                                title={"术野皮肤准备正确"}
                                value={moduleValue.current?.beforeAnesthesia?.prepareSkinBeforeStrategyCheck}
                                fatherChange={(newValue: boolean) => {
                                    beforeAnesthesiaPartChange({prepareSkinBeforeStrategyCheck: newValue})
                                }}
                            />
                            <RadioInput
                                title={"静脉通路建立完成"}
                                value={moduleValue.current?.beforeAnesthesia?.venousPassageCheck}
                                fatherChange={(newValue: boolean) => {
                                    beforeAnesthesiaPartChange({venousPassageCheck: newValue})
                                }}
                            />
                            <RadioInput
                                title={"患者是否有过敏史"}
                                value={moduleValue.current?.beforeAnesthesia?.allergicHistoryCheck}
                                fatherChange={(newValue: boolean) => {
                                    beforeAnesthesiaPartChange({allergicHistoryCheck: newValue})
                                }}
                            />
                            <RadioInput
                                title={"假体"}
                                value={moduleValue.current?.beforeAnesthesia?.prosthesisCheck}
                                fatherChange={(newValue: boolean) => {
                                    beforeAnesthesiaPartChange({prosthesisCheck: newValue})
                                }}
                            />
                            <RadioInput
                                title={"体内植入物"}
                                value={moduleValue.current?.beforeAnesthesia?.implantCheck}
                                fatherChange={(newValue: boolean) => {
                                    beforeAnesthesiaPartChange({implantCheck: newValue})
                                }}
                            />
                            <RadioInput
                                title={"假体"}
                                value={moduleValue.current?.beforeAnesthesia?.imagingDateCheck}
                                fatherChange={(newValue: boolean) => {
                                    beforeAnesthesiaPartChange({imagingDateCheck: newValue})
                                }}
                            />
                            <Flex
                                gap={'large'}
                                align={'center'}
                                justify={'center'}
                            >
                                <Text>其它</Text>
                                <TextArea
                                    style={{width:"70%"}}
                                    autoSize
                                    allowClear
                                    defaultValue={moduleValue.current?.beforeAnesthesia?.others}
                                    onChange={v => {
                                        beforeAnesthesiaPartChange({others: v.target.value})
                                    }}
                                />
                            </Flex>
                        </Flex>
                        <Flex
                            justify={'space-evenly'}
                            gap={"large"}
                            vertical
                            style={{width:"90%"}}
                        >
                            <Divider> 手术开始前（切开皮肤前） </Divider>
                            <RadioInput
                                title={"患者姓名、性别、年龄正确"}
                                value={moduleValue.current?.beforeOperation?.nameAgeGenderCheck}
                                fatherChange={v => {
                                    beforeOperationPartChange({nameAgeGenderCheck: v})
                                }}
                            />
                            <RadioInput
                                title={"手术方式确认"}
                                value={moduleValue.current?.beforeOperation?.modeOfOperationCheck}
                                fatherChange={v => {
                                    beforeOperationPartChange({modeOfOperationCheck: v})
                                }}
                            />
                            <RadioInput
                                title={"手术部位与标识正确"}
                                value={moduleValue.current?.beforeOperation?.locationOfOperationCheck}
                                fatherChange={v => {
                                    beforeOperationPartChange({locationOfOperationCheck: v})
                                }}
                            />
                            <RadioInput
                                title={"手术护士陈述"}
                                value={moduleValue.current?.beforeOperation?.statementOfNurseCheck}
                                fatherChange={v => {
                                    beforeOperationPartChange({statementOfNurseCheck: v})
                                }}
                            />
                            <RadioInput
                            title={"物品灭菌合格"}
                            value={moduleValue.current?.beforeOperation?.sterilizationCheck}
                            fatherChange={v => {
                                beforeOperationPartChange({sterilizationCheck: v})
                            }}
                        />
                        <RadioInput
                            title={"仪器设备"}
                            value={moduleValue.current?.beforeOperation?.equipmentCheck}
                            fatherChange={v => {
                                beforeOperationPartChange({equipmentCheck: v})
                            }}
                        />
                        <RadioInput
                            title={"需要相关影像资料"}
                            value={moduleValue.current?.beforeOperation?.imagingDataNeedCheck}
                            fatherChange={v => {
                                beforeOperationPartChange({imagingDataNeedCheck: v})
                            }}
                        />
                        <Flex
                            gap={'large'}
                            align={'center'}
                            justify={'center'}
                        >
                            <Text>其它</Text>
                            <TextArea
                                style={{width:"70%"}}
                                allowClear
                                autoSize
                                defaultValue={moduleValue.current?.beforeOperation?.others}
                                onChange={(e) => {
                                    beforeOperationPartChange({others: e.target.value})
                                }}
                            />
                        </Flex>
                    </Flex>
                    <Flex
                        vertical
                        justify={'space-evenly'}
                        gap={"large"}
                        style={{width:"90%"}}
                    >
                        <Divider> 患者离开手术开始前 </Divider>
                        <RadioInput
                            title={"患者姓名、性别、年龄正确"}
                            value={moduleValue.current?.beforePatientLeft?.nameAgeGenderCheck}
                            fatherChange={(newValue: boolean) => {
                                beforePatientLeftPartChange({nameAgeGenderCheck: newValue})
                            }}
                        />
                        <RadioInput
                            title={"实际手术方式确认"}
                            value={moduleValue.current?.beforePatientLeft?.modeOfOperationCheck}
                            fatherChange={(newValue: boolean) => {
                                beforePatientLeftPartChange({modeOfOperationCheck: newValue})
                            }}
                        />
                        <RadioInput
                            title={"实际手术用药核查"}
                            value={moduleValue.current?.beforePatientLeft?.surgicalMedicationCheck}
                            fatherChange={(newValue: boolean) => {
                                beforePatientLeftPartChange({surgicalMedicationCheck: newValue})
                            }}
                        />
                        <RadioInput
                            title={"手术用物清点正确"}
                            value={moduleValue.current?.beforePatientLeft?.surgicalGoodsCheck}
                            fatherChange={(newValue: boolean) => {
                                beforePatientLeftPartChange({surgicalGoodsCheck: newValue})
                            }}
                        />
                        <RadioInput
                            title={"手术标本确认"}
                            value={moduleValue.current?.beforePatientLeft?.surgicalSpecimenCheck}
                            fatherChange={(newValue: boolean) => {
                                beforePatientLeftPartChange({surgicalSpecimenCheck: newValue})
                            }}
                        />
                        <RadioInput
                            title={"皮肤是否完整"}
                            value={moduleValue.current?.beforePatientLeft?.skinIntactCheck}
                            fatherChange={(newValue: boolean) => {
                                beforePatientLeftPartChange({skinIntactCheck: newValue})
                            }}
                        />
                        <Flex
                            gap={'large'}
                            align={'center'}
                            justify={'center'}
                        >
                            <Text> 各种管路</Text>
                            <TextArea
                                style={{width:"70%"}}
                                allowClear
                                autoSize
                                defaultValue={moduleValue.current?.beforePatientLeft?.passage}
                                onChange={(newValue) => {
                                    beforePatientLeftPartChange({passage: newValue.target.value})
                                }}
                            />
                        </Flex>
                        <RadioInput
                            title={"伤口引流"}
                            value={moduleValue.current?.beforePatientLeft?.drainageOfWoundCheck}
                            fatherChange={(newValue: boolean) => {
                                beforePatientLeftPartChange({drainageOfWoundCheck: newValue})
                            }}
                        />
                        <Flex
                            gap={'large'}
                            align={'center'}
                            justify={'center'}
                        >
                            <Text
                            >患者去向</Text>
                            <Radio.Group
                                onChange={(e: RadioChangeEvent) => {
                                    beforePatientLeftPartChange(e.target.value)
                                }}
                                defaultValue={moduleValue.current?.beforePatientLeft?.goingOfPatient}>
                                <Radio value={"留观"}>留观</Radio>
                                <Radio value={"离院"}>离院</Radio>
                            </Radio.Group>
                        </Flex>
                    </Flex>
                </Flex>
            </>
            )
            }
        </>
    )
}