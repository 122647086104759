
// 定义超时时间
export const TIMEOUT = 60000;

// 定义不同环境下的url，开发环境和线上环境不同。
const devBaseUrl = "http://dev.xiebingli.top";
const proBaseUrl = "https://xiebingli.top";
export const baseUrl = process.env.NODE_ENV === 'production' ? proBaseUrl : devBaseUrl;

const isProEvn = process.env.NODE_ENV === 'production' ? true : false;
export const withCredentials = !isProEvn;

/**
 * 基础字符串响应。
 */
export interface BasicResponse {
    code: number,
    data: string
}

/**
 * 基础配置信息响应
 */
export interface BasicDataResponse<T> {
    code: number,
    data: T
}
