import {AllBaseInfo, Consumables, NursingRecord, OutPatientProfile, TotalProfile, UserProfile} from "../common";
import handleAxios from "../../../Http/request";

/**
 * 查询指定条件病例接口
 */
export interface ListCondition {
    userId?: number
    name?: string
    profileId?: string
    gender?: string
    limit?: number
    offset?: number
}

/**
 * 查询结果结构体
 */
export interface ListResponse {
    total: number,
    data: UserProfile[]
}

/**
 * 所有基础信息响应
 */
export interface AllBaseInfoListResponse {
    total: number,
    data: AllBaseInfo[]
}

/**
 * 麻醉前对象结构体
 */
export interface BeforeAnesthesia {
    nameAgeGenderCheck?: boolean;
    modeOfOperationCheck?: boolean;
    locationOfOperationCheck?: boolean;
    acknowledgeOfOperationCheck?: boolean;
    acknowledgeOfAnestheticCheck?: boolean;
    modeOfAnestheticCheck?: boolean;
    integrityOfSkinCheck?: boolean;
    prepareSkinBeforeStrategyCheck?: boolean;
    venousPassageCheck?: boolean;
    allergicHistoryCheck?: boolean;
    prosthesisCheck?: boolean;
    implantCheck?: boolean;
    imagingDateCheck?: boolean;
    others?: string;
}

/**
 * 术前
 */
export interface BeforeOperation {
    nameAgeGenderCheck?: boolean;
    modeOfOperationCheck?: boolean;
    locationOfOperationCheck?: boolean;
    statementOfNurseCheck?: boolean;
    sterilizationCheck?: boolean;
    equipmentCheck?: boolean;
    imagingDataNeedCheck?: boolean;
    others: string;
}

/**
 * 病人离开前
 */
export interface BeforePatientLeft {
    nameAgeGenderCheck?: boolean;
    modeOfOperationCheck?: boolean;
    surgicalMedicationCheck?: boolean;
    surgicalGoodsCheck?: boolean;
    surgicalSpecimenCheck?: boolean;
    skinIntactCheck?: boolean;
    passage: string;
    drainageOfWoundCheck?: boolean;
    goingOfPatient: string;
}

/**
 * 手术安全核查表
 */
export interface SafetyCheckOfOperation {
    id?: number;
    profileId?: string;
    name: string;
    birthday: string;
    age?: number;
    gender?: string;
    operator: string;
    anesthesiologist?: string;  //麻醉师
    rovingNurse?: string;  // 巡回护士
    strategyName: string;
    anestheticMode: string;
    recordTime: string;
    beforeAnesthesia: BeforeAnesthesia;
    beforeOperation: BeforeOperation;
    beforePatientLeft: BeforePatientLeft;
    submitType?: number;
    updateAt: string;
    updateBy: string;
    createBy: string;
    createAt: string;
}

/**
 * 所有表总的记录信息对象
 */
export interface OperationTables {
    safetyCheckOfOperation?: SafetyCheckOfOperation
}

/**
 * 初始化麻醉实施前
 * @constructor
 */
export function InitBeforeAnesthesia() : BeforeAnesthesia {
    return {
        acknowledgeOfAnestheticCheck: undefined,
        acknowledgeOfOperationCheck: undefined,
        allergicHistoryCheck: undefined,
        imagingDateCheck: undefined,
        implantCheck: undefined,
        integrityOfSkinCheck: undefined,
        locationOfOperationCheck: undefined,
        modeOfAnestheticCheck: undefined,
        modeOfOperationCheck: undefined,
        nameAgeGenderCheck: undefined,
        others: "",
        prepareSkinBeforeStrategyCheck: undefined,
        prosthesisCheck: undefined,
        venousPassageCheck: undefined
    }
}

/**
 * 生成手术开始前
 */
export function InitBeforeOperation() : BeforeOperation {
    return {
        equipmentCheck: undefined,
        imagingDataNeedCheck: undefined,
        locationOfOperationCheck: undefined,
        modeOfOperationCheck: undefined,
        nameAgeGenderCheck: undefined,
        others: "",
        statementOfNurseCheck: undefined,
        sterilizationCheck: undefined
    }
}

/**
 * 初始化患者离开手术前
 */
export function InitBeforePatientLeft() : BeforePatientLeft {
    return {
        drainageOfWoundCheck: undefined,
        goingOfPatient: "",
        modeOfOperationCheck: undefined,
        nameAgeGenderCheck: undefined,
        passage: "",
        skinIntactCheck: undefined,
        surgicalGoodsCheck: undefined,
        surgicalMedicationCheck: undefined,
        surgicalSpecimenCheck: undefined
    }
}

/**
 * 初始化手术安全核查表
 */
export function InitSafetyCheckOfOperation(baseInfo?:AllBaseInfo) : SafetyCheckOfOperation {
    let retObj: SafetyCheckOfOperation = {
        age: undefined,
        anestheticMode: "",
        beforeAnesthesia: InitBeforeAnesthesia(),
        beforeOperation: InitBeforeOperation(),
        beforePatientLeft: InitBeforePatientLeft(),
        birthday: "",
        createAt: generateCommonDateStr(),
        createBy: "",
        gender: undefined,
        id: undefined,
        name: "",
        operator: "",
        profileId: undefined,
        recordTime: generateCommonDateStr(),
        strategyName: "",
        submitType: 0,
        updateAt: generateCommonDateStr(),
        updateBy: "",
        anesthesiologist: "",
        rovingNurse: ""
    }
    if (baseInfo !== undefined) {
        retObj.age = calAge(baseInfo.birthday);
        retObj.birthday = baseInfo.birthday;
        retObj.gender = baseInfo.gender;
        retObj.name = baseInfo.name;
        retObj.profileId = baseInfo.profileId;
        retObj.operator = baseInfo.operator;
        retObj.strategyName = baseInfo.strategyName;
    }
    return retObj;

}

/**
 * 生成年月日字符串。
 */
export function generateDateStr() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    // 0~9月前面加0
    let monthStr = String(month);
    if (monthStr.length < 2) {
        monthStr = "0" + monthStr;
    }

    // 天小于9的前面补零
    let dayStr = String(day);
    if (day < 9) {
        dayStr = "0" + dayStr;
    }

    return String(year) + monthStr + dayStr
}

/**
 * 生成常用的当前时间点
 */
export function generateCommonDateStr() {
    let date= new Date()
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();

    // 0~9月前面加0
    let monthStr = String(month);
    if (monthStr.length < 2) {
        monthStr = "0" + monthStr;
    }
    // 0-9天前面加0
    let dayStr = String(day)
    if (dayStr.length < 2) {
        dayStr = "0" + dayStr;
    }

    // 0~9小时的前面加零
    let hourStr = String(hour);
    if (hour < 10) {
        hourStr = "0" + hourStr;
    }

    // 0~9分钟前面加0
    let minutesStr: string = String(minutes);
    if (minutes < 10) {
        minutesStr = "0" + minutesStr;
    }

    let secondsStr = String(seconds);
    if (seconds < 10) {
        secondsStr = "0" + secondsStr;
    }

    return String(year) + "-" + monthStr + "-" + dayStr + " " + hourStr + ":"
        + minutesStr + ":" + secondsStr;
}

/**
 * 上传数据下载生成病例。
 * @param totalProfile
 */
export function fileDownLoad(totalProfile:TotalProfile) {
    const ret:Promise<Blob> = handleAxios.post("/api/downloadDocx", totalProfile, {
        responseType: "blob"
    });

    ret.then(res=> {
        // console.log(res)
        const href = URL.createObjectURL(res);
        const a = document.createElement("a");

        // 生成病例名称。
        a.download = "手术病历_" + totalProfile.name + "_" + generateDateStr() + ".docx";
        a.href = href;
        a.click();
    })
}

/**
 * 下载手术消耗明细表
 * @param consumable
 * @constructor
 */
export function ConsumableFileDownLoad(consumable: Consumables) {
    const ret:Promise<Blob> = handleAxios.post("/api/downloadConsumableDocx", consumable, {
        responseType: "blob"
    });

    ret.then(res=> {
        // console.log(res)
        const href = URL.createObjectURL(res);
        const a = document.createElement("a");

        // 生成病例名称。
        a.download = "消耗明细_" + consumable.name + "_" + generateDateStr() + ".docx";
        a.href = href;
        a.click();
    })
}

/**
 * 下载门诊病历
 * @param totalProfile
 */
export function outPatientDownLoad(totalProfile: OutPatientProfile) {
    const ret:Promise<Blob> = handleAxios.post("/api/downloadOutPatientDocx", totalProfile, {
        responseType: "blob"
    });

    ret.then(res=> {
        // console.log(res)
        const href = URL.createObjectURL(res);
        const a = document.createElement("a");

        // 生成病例名称。
        a.download = "门诊病历_" + totalProfile.name + "_" + generateDateStr() + ".docx";
        a.href = href;
        a.click();
    })
}

/**
 * 下载处方笺
 * @param totalProfile
 */
export function recipesDownload(totalProfile: OutPatientProfile) {
    const ret:Promise<Blob> = handleAxios.post("/api/downloadRecipesDocx", totalProfile, {
        responseType: "blob"
    });

    ret.then(res=> {
        // console.log(res)
        const href = URL.createObjectURL(res);
        const a = document.createElement("a");

        // 生成病例名称。
        a.download = "处方笺_" + totalProfile.name + "_" + generateDateStr() + ".docx";
        a.href = href;
        a.click();
    })
}
/**
 * 下载处方笺
 * @param totalProfile
 */
export function safetyCheckOfOperationDownload(totalProfile: SafetyCheckOfOperation) {
    const ret:Promise<Blob> = handleAxios.post("/api/downloadSafetyCheckDocx", totalProfile, {
        responseType: "blob"
    });

    ret.then(res=> {
        // console.log(res)
        const href = URL.createObjectURL(res);
        const a = document.createElement("a");

        // 生成病例名称。
        a.download = "安全核查表_" + totalProfile.name + "_" + generateDateStr() + ".docx";
        a.href = href;
        a.click();
    })
}

/**
 * 下载手术护理记录单
 * @param totalProfile
 */
export function nursingRecordOfOperationDownload(totalProfile: NursingRecord) {
    const ret:Promise<Blob> = handleAxios.post("/api/downloadNursingRecordDocx", totalProfile, {
        responseType: "blob"
    });

    ret.then(res=> {
        // console.log(res)
        const href = URL.createObjectURL(res);
        const a = document.createElement("a");

        // 生成病例名称。
        a.download = "护理记录单_" + totalProfile.name + "_" + generateDateStr() + ".docx";
        a.href = href;
        a.click();
    })
}

/**
 * 根据出生日期计算年龄。指定比较时间
 * @param dateStr 年龄字符串
 * @param totay 制定的比较时间。
 */
export function calAge(dateStr: string, today = new Date()):number {
    // 待计算的日期为空，那么直接返回-1
    if (dateStr === "") {
        return -1 ;
    }
    let oldDate = new Date(dateStr);
    let oldYear = oldDate.getFullYear();
    let oldMonth = oldDate.getMonth();
    let oldDay = oldDate.getDate();

    // let today = new Date();

    let thisYear = today.getFullYear();
    let thisMonth = today.getMonth();
    let thisDay = today.getDate();

    // 年、月、日的差值
    let yearGap = thisYear - oldYear;
    let monthGap = thisMonth - oldMonth;
    let dayGap = thisDay - oldDay;
    // debugger;
    if (yearGap == 0) {
        return 0;   //同一年，任务0岁
    } else if (yearGap > 0) {
        // 判断月
        if (monthGap == 0) {
            // 判断天的差值
            if (dayGap >= 0) {
                // 生日已经过了，那么返回年差值
                return yearGap;
            } else {
                // 生日还没过，返回年差值减一
                return yearGap - 1;
            }
        } else if (monthGap > 0) {
            // 跨年满周岁，直接返回年差值
            return yearGap;
        } else {
            return yearGap - 1;
        }

    } else {
        // 年的差不可能为-1，输入错误
        return -1;
    }
    // 如果月差值大于0，那么任务今年生日已经过了，可以以年的差值
    if (monthGap > 0){
        if (dayGap >= 0) {
            return yearGap + 1;
        }
    } else {
        return yearGap
    }
}

/**
 * 民族选项列表
 */
export const NationList: string[] = [
    "汉族", "壮族", "满族", "回族", "苗族", "维吾尔族", "土家族", "彝族", "蒙古族", "藏族", "布依族", "侗族", "瑶族", "朝鲜族", "白族", "哈尼族",
    "哈萨克族", "黎族", "傣族", "畲族", "傈僳族", "仡佬族", "东乡族", "高山族", "拉祜族", "水族", "佤族", "纳西族", "羌族", "土族", "仫佬族", "锡伯族",
    "柯尔克孜族", "达斡尔族", "景颇族", "毛南族", "撒拉族", "布朗族", "塔吉克族", "阿昌族", "普米族", "鄂温克族", "怒族", "京族", "基诺族", "德昂族", "保安族",
    "俄罗斯族", "裕固族", "乌孜别克族", "门巴族", "鄂伦春族", "独龙族", "塔塔尔族", "赫哲族", "珞巴族"
]

/**
 * 根据出生日期计算年龄。指定比较时间
 * @param dateStr 年龄字符串
 * @param totay 制定的比较时间。
 */
export function CalAge(dateStr: string, today = new Date()):number {
    // 待计算的日期为空，那么直接返回-1
    if (dateStr === "") {
        return -1;
    }
    let oldDate = new Date(dateStr);
    let oldYear = oldDate.getFullYear();
    let oldMonth = oldDate.getMonth();
    let oldDay = oldDate.getDate();

    // let today = new Date();

    let thisYear = today.getFullYear();
    let thisMonth = today.getMonth();
    let thisDay = today.getDate();

    // 年、月、日的差值
    let yearGap = thisYear - oldYear;
    let monthGap = thisMonth - oldMonth;
    let dayGap = thisDay - oldDay;
    // debugger;
    if (yearGap == 0) {
        return 0;   //同一年，任务0岁
    } else if (yearGap > 0) {
        // 判断月
        if (monthGap == 0) {
            // 判断天的差值
            if (dayGap >= 0) {
                // 生日已经过了，那么返回年差值
                return yearGap;
            } else {
                // 生日还没过，返回年差值减一
                return yearGap - 1;
            }
        } else if (monthGap > 0) {
            // 跨年满周岁，直接返回年差值
            return yearGap;
        } else {
            return yearGap - 1;
        }

    } else {
        // 年的差不可能为-1，输入错误
        return -1;
    }
    // 如果月差值大于0，那么任务今年生日已经过了，可以以年的差值
    if (monthGap > 0) {
        if (dayGap >= 0) {
            return yearGap + 1;
        }
    } else {
        return yearGap
    }
}

/**
 * 格式化日期，转换成病例需要的样式
 * @param dateStr
 */
export function DateFormat(dateStr:string):string {
    let date = new Date(dateStr);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minutes = date.getMinutes();

    // 0~9月前面加0
    let monthStr = String(month);
    if (monthStr.length < 2) {
        monthStr = "0" + monthStr;
    }

    // 0~9小时的前面加零
    let hourStr = String(hour);
    if (hour < 10) {
        hourStr = "0" + hourStr;
    }

    // 0~9分钟前面加0
    let minutesStr: string = String(minutes);
    if (minutes < 10) {
        minutesStr = "0" + minutesStr;
    }

    return String(year) + "年" + monthStr + "月" + String(day) + "日 " + hourStr + "时" + minutesStr + "分";
}

/**
 * 格式化时间，转成年月日
 */
export function YMDFormat(dateStr:string | string[]):string {
    let date;
    if (typeof dateStr === "string") {
        date = new Date(dateStr);
    } else {
        date = new Date(dateStr[0]);
    }
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minutes = date.getMinutes();

    // 0~9月前面加0
    let monthStr = String(month);
    if (monthStr.length < 2) {
        monthStr = "0" + monthStr;
    }

    return String(year) + "年" + monthStr + "月" + String(day) + "日";
}

/**
 * 获取当前时间的字符从形式
 */
export function getCurrentTimeStr():string{
    let date= new Date()
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();

    // 0~9月前面加0
    let monthStr = String(month);
    if (monthStr.length < 2) {
        monthStr = "0" + monthStr;
    }

    return String(year) + "年" + monthStr + "月" + String(day) + "日" + String(hour) + "时"
        + String(minutes) + "分" + String(seconds) + "秒";
}

/**
 * 获取当前毫秒数
 */
export function getCurrentMSeconds(): number {
    return new Date().getTime();
}