import { Divider, Input, Select, Space, Button } from 'antd';
import type { InputRef } from 'antd';
import React, { useState, useRef } from 'react';
import { PlusOutlined } from '@ant-design/icons';

interface P {
    options: string[],
    defaultValue: string,
    value?: string,     //当前值
    style?:React.CSSProperties, //传入样式
    fatherChange:(curSelect:string) => void,    //告诉父组件，当前选中的值
}

export default function ({options, defaultValue, value, style, fatherChange}:P) {

    let index = 0;

    const [moduleValue, setModuleValue] = useState<string | undefined>
    (value === undefined || value === null ? defaultValue : value)
    const [items, setItems] = useState(options);
    const [name, setName] = useState('');
    const inputRef = useRef<InputRef>(null);

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        e.preventDefault();
        setItems([...items, name || `New item ${index++}`]);
        setName('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    return (
        <>
            <Select
                style={{ width: 200, ...style } }
                placeholder=""
                defaultValue={moduleValue}
                value={moduleValue}
                dropdownRender={(menu) => (
                    <>
                        {menu}
                        <Divider style={{ margin: '8px 0' }} />
                        <Space style={{ padding: '0 8px 4px' }}>
                            <Input
                                placeholder="临时新增选项，面板切换后失效"
                                ref={inputRef}
                                value={name}
                                onChange={onNameChange}
                            />
                            <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                                新增
                            </Button>
                        </Space>
                    </>
                )}
                options={items.map((item) => ({ label: item, value: item }))}
                onChange={(e) => {
                    setModuleValue(e)
                    fatherChange(e)
                }}
            />
        </>
    )
}
