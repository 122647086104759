import React, {useState} from 'react';
import { UserOutlined, FileExcelOutlined, SettingOutlined, SnippetsOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Layout, Menu, theme, ConfigProvider, Typography } from 'antd';
import {CONFIGS, OPERATION, UPLOAD_NEW_FILE, USER_PROFILE} from "./common";
import Details from "./Details";
import UploadFile from "./UploadFile";
import Config from "./Config";
import zhCN from 'antd/lib/locale/zh_CN';
import "./index.css"
import Operation from "./Operation";

const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;

export default function () {

    // 可以使用的响应菜单。
    const adminItems: MenuProps['items'] = [
        {
            key: USER_PROFILE,
            icon: React.createElement(UserOutlined),
            label: '病例列表',
            onClick:() => {
                setSelectedOption(USER_PROFILE);
            }
        },
        {
            key: UPLOAD_NEW_FILE,
            icon: React.createElement(FileExcelOutlined),
            label: '上传新病例',
            onClick: () => {
                setSelectedOption(UPLOAD_NEW_FILE)
            }
        },
        {
            key: OPERATION,
            icon: React.createElement(SnippetsOutlined),
            label: '手术表',
            onClick:() => {
                setSelectedOption(OPERATION)
            }
        },
        {
            key: CONFIGS,
            icon: React.createElement(SettingOutlined),
            label: '配置选项',
            onClick:() => {
                setSelectedOption(CONFIGS)
            }
        }
    ]

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    // 默认选中的菜单
    const [selectOption, setSelectedOption] = useState<String>(USER_PROFILE);

    return (
        <ConfigProvider locale={zhCN}>
            <Layout>
                <Header style={{ display: 'flex', alignItems: 'center', justifyItems:"middle" }}>
                    <img src="/logo1.png" width="150" height="45"/>
                    <Title level={2} style={{color:"#159e85", marginLeft:"30px"}}>
                        重庆东上医疗美容门诊部电子化病历系统
                    </Title>
                </Header>
                <Content style={{ height:"80vh"}}>
                    <Layout style={{background: colorBgContainer, minHeight:"90vh" }}>
                        <Sider style={{ background: colorBgContainer, height:"80vh" }} width={200}>
                            <Menu
                                mode="inline"
                                defaultSelectedKeys={['user_profile']}
                                style={{ height: '100%' }}
                                items={adminItems}
                            />
                        </Sider>
                        <Content style={{ padding: '0 24px', minHeight: 280 , backgroundColor: "#f0f0f0"}}>
                            {selectOption === USER_PROFILE && (<Details />)}
                            {selectOption === UPLOAD_NEW_FILE && (<UploadFile />)}
                            {selectOption === CONFIGS && (<Config />)}
                            {selectOption === OPERATION && (<Operation />)}
                            <div
                                style={{
                                    marginTop: "3vh",
                                    marginBottom: "3vh"
                                }}
                            >
                                Copyright © 2022 - {new Date().getFullYear()} All Rights Reserved. 江西赣亚科技文化有限公司©{new Date().getFullYear()} 版权所有
                            </div>
                        </Content>
                    </Layout>
                </Content>
            </Layout>
        </ConfigProvider>

    );
};


