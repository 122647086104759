import {Col, Flex, Radio, RadioChangeEvent, Row, Space, Typography} from "antd";
import React from "react";

interface P {
    title: string,
    value: boolean | undefined,
    fatherChange(newValue: boolean): void,
    style?: React.CSSProperties
}

const { Text, Link } = Typography;

export default function ({title, value, fatherChange, style}:P) {
    if (title === "") {
        return (
            <>
                <Row
                    style={style}
                    justify={'start'}
                    align={'middle'}
                >
                    <Col span={'auto'}>
                        <Radio.Group
                            onChange={(e: RadioChangeEvent) => {
                                fatherChange(e.target.value)
                            }}
                            defaultValue={value}>
                            <Radio value={true}>是</Radio>
                            <Radio value={false}>否</Radio>
                        </Radio.Group>
                    </Col>
                </Row>
            </>
        )
    } else {
        return(
            <>
                <Row
                    style={style}
                    justify={'start'}
                    align={'middle'}
                >
                    <Col span={14}>
                        <Text
                        >{title}</Text>
                    </Col>
                    <Col span={10}>
                        <Radio.Group
                            onChange={(e: RadioChangeEvent) => {
                                fatherChange(e.target.value)
                            }}
                            defaultValue={value}>
                            <Radio value={true}>是</Radio>
                            <Radio value={false}>否</Radio>
                        </Radio.Group>
                    </Col>
                </Row>
            </>
        )
    }
}