import RowInput from "../RowInput";
import {Col, DatePicker, Input, InputNumber, Radio, Row} from "antd";
import React, {useEffect, useState} from "react";
import {AllBaseInfo, EditOptions} from "../../pages/Admin/common";
import {BasicDataResponse} from "../../Http/common";
import handleAxios from "../../Http/request";
import SelectAdd from "../../pages/Admin/Details/ProfileView/SelectAdd";
import dayjs from 'dayjs'

interface P {
    initValue: AllBaseInfo,
    onChange: (newBaseInfo: AllBaseInfo) => void
}

export default function ({initValue, onChange}: P) {
    const [editing, setEditing] = useState<AllBaseInfo>(initValue);
    // 监听数据变化那么周知父组件数据变化
    useEffect(() => {
        onChange(editing)
    }, [editing])
    // 存储部分可选的选项
    const [editOptions, setEditOptions] = useState<EditOptions>();
    // 拉取可选部分的选项
    useEffect(() => {
        let ret: Promise<BasicDataResponse<EditOptions>> = handleAxios.get("/api/getAllConfigs");
        ret.then((res) => {
            if (res.code === 0) {
                setEditOptions(res.data)
            }
        });
    }, [])


    return (
        <>
            {editOptions !== undefined && (
                <>
                    <RowInput
                        keyText={"姓名"}
                        defaultValue={editing.name}
                        placeHolder={"请输入姓名"}
                        onChange={v => {
                            setEditing({...editing, name: v})
                        }}
                    />
                    <Row align={'middle'} justify={'center'} style={{marginTop: '10px', marginBottom: '10px'}}>
                        <Col span={4}>
                            性别
                        </Col>
                        <Col span={16}>
                            <Radio.Group
                                onChange={v => setEditing({...editing, gender: v.target.value})}
                                value={editing.gender}
                            >
                                <Radio value={"男"}>男</Radio>
                                <Radio value={"女"}>女</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row align={'middle'} justify={'center'} style={{marginTop: '10px', marginBottom: '10px'}}>
                        <Col span={4}>
                            出生日期
                        </Col>
                        <Col span={16}>
                            <DatePicker
                                format={"YYYY-MM-DD"}
                                defaultValue={editing.birthday !== '' ? dayjs(editing.birthday) : undefined}
                                onChange={(date, dateStr) => {
                                    let newDate;
                                    if (typeof dateStr === 'string') {
                                        newDate = dateStr;
                                    } else {
                                        newDate = dateStr[0]
                                    }
                                    setEditing({...editing, birthday: newDate})
                                }}
                            />
                        </Col>
                    </Row>
                    <Row align={'middle'} justify={'center'} style={{marginTop: '10px', marginBottom: '10px'}}>
                        <Col span={4}>
                            体重
                        </Col>
                        <Col span={16}>
                            <InputNumber
                                changeOnWheel
                                min={1}
                                addonAfter={'公斤'}
                                defaultValue={editing.weight}
                                onChange={v => setEditing({...editing, weight: v!})}
                            />
                        </Col>
                    </Row>
                    <RowInput
                        keyText={"血压"}
                        defaultValue={editing.bloodPressure}
                        placeHolder={"请输入血压"}
                        onChange={v => setEditing({...editing, bloodPressure: v})}
                        suffix={"mmHg"}
                    />
                    <Row align={'middle'} justify={'center'} style={{marginTop: '10px', marginBottom: '10px'}}>
                        <Col span={4}>
                            脉搏
                        </Col>
                        <Col span={16}>
                            <InputNumber
                                min={1}
                                changeOnWheel
                                addonAfter={"次/分钟"}
                                defaultValue={editing.pulse}
                                onChange={v => setEditing({...editing, pulse: v!})}
                            />
                        </Col>
                    </Row>
                    <Row align={'middle'} justify={'center'} style={{marginTop: '10px', marginBottom: '10px'}}>
                        <Col span={4}>
                            术前诊断
                        </Col>
                        <Col span={16}>
                            <Input
                                defaultValue={editing.beforeDiagnosis}
                                style={{width:"100%"}}
                                onChange={v => {
                                    setEditing({
                                        ...editing,
                                        beforeDiagnosis: v.target.value
                                    })
                                }}
                            />
                        </Col>
                    </Row>
                    <Row align={'middle'} justify={'center'} style={{marginTop: '10px', marginBottom: '10px'}}>
                        <Col span={4}>
                            手术名称
                        </Col>
                        <Col span={16}>
                            <Input
                                defaultValue={editing.strategyName}
                                style={{width:"100%"}}
                                onChange={v => {
                                    setEditing({...editing, strategyName: v.target.value})
                                }}
                            />
                        </Col>
                    </Row>
                    <Row align={'middle'} justify={'center'} style={{marginTop: '10px', marginBottom: '10px'}}>
                        <Col span={4}>
                            手术医师
                        </Col>
                        <Col span={16}>
                            <SelectAdd
                                options={editOptions?.operator}
                                defaultValue={editing.operator}
                                style={{width: '100%'}}
                                fatherChange={(v) => setEditing({...editing, operator: v})}
                            />
                        </Col>
                    </Row>
                    <RowInput
                        keyText={"用户ID"}
                        defaultValue={editing.userId}
                        onChange={v => setEditing({...editing, userId: v})}
                    />
                    <RowInput
                        keyText={"病例号"}
                        defaultValue={editing.profileId}
                        onChange={v => setEditing({...editing, profileId: v})}
                    />
                    <RowInput
                        keyText={"备注"}
                        defaultValue={editing.ext}
                        onChange={v => setEditing({...editing, ext:v})}
                    />
                </>
            )}

        </>
    )
}