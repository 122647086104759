import React from 'react';
import { Layout, Menu, theme, Button, Divider, Typography, Image } from 'antd';
import "./index.css"

const { Header, Footer, Content } = Layout;
const {Title, Paragraph, Text, Link} = Typography;

export default function() {

     const {
         token: { colorBgContainer },
     } = theme.useToken();

     return (
         <Layout className="layout" onClick={() => {
             window.location.href="/login"
         }}>
             <Header>
                 <div className="logo" />
                 <Menu
                     theme="dark"
                     mode="horizontal"
                     defaultSelectedKeys={['2']}
                 />
             </Header>
             <Content className="body" style={{minHeight: '1600px'}}>
                <Typography>
                    <Paragraph>
                        <Title style={{color: '#ffffff', marginTop:'700px'}}>
                            网站技术学习
                        </Title>
                    </Paragraph>
                    <Paragraph>
                        <Title style={{color: '#ffffff'}}>
                            个人学习网站，测试web技术学习，不对外开放，谢谢！
                        </Title>
                    </Paragraph>
                </Typography>
             </Content>
             <Footer style={{ textAlign: 'center', backgroundColor:'#f5f5f5'}}>
                 <a href="https://beian.miit.gov.cn" target="_blank">沪ICP备2023008958号-1</a>
                 <div style={{width:"300px", margin:"0 auto", padding:"20px 0"}}>
                     <a target="_blank"
                        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011202014688"
                        style={{display:"inline-block", textDecoration:"none", height:"20px", lineHeight:"20px"}}>
                         <img src="./beian.png" style={{float:"left", minHeight:"20px", minWidth:"20px"}} className={"beian"}/>
                         <p style={{float:"left", height:"20px", lineHeight:"20px", margin: "0px 0px 0px 5px", color:"#939393" }}>
                             沪公网安备 31011202014688号
                         </p>
                     </a>
                 </div>
             </Footer>
         </Layout>
    )
 }
