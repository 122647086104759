import * as XLSX from 'xlsx';

export default function () {
    function importExcel(e:any) {
        var files = e.target.files;
        var name = files.name;
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target!.result;
            const wb = XLSX.read(bstr, {type: 'binary'})
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws);
            data.forEach((value, index) => {
                console.log(value);
            })
        }
        reader.readAsBinaryString(files[0]);
    }
    return (
        <h1>
            <input type="file" id="excel-file" onChange={importExcel}/>
        </h1>
    )
}
