import {Switch, Route} from "react-router-dom"
import Index from "../pages/Home"
import TestFunction from "../pages/TestFunction"
import Login from '../pages/LoginPage'
import Upload from "../pages/UploadPage"
import Admin from "../pages/Admin"

export default function () {
    return (
        <Switch>
            <Route exact path="/h1">
                <div>
                    <h1>测试以及标题</h1>
                </div>
            </Route>
            <Route exact path="/test1">
                <TestFunction />
            </Route>
            <Route exact path="/upload">
                <Upload />
            </Route>
            <Route exact path="/login">
                <Login />
            </Route>
            <Route exact path="/admin">
                <Admin />
            </Route>
            <Route exact path="/">
                <Index />
            </Route>
            <Route path="/*">
                <div>
                    <h1>404页面</h1>
                </div>
            </Route>
        </Switch>
    )
}
