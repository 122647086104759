import {Detail, InitDetail} from "../../pages/Admin/common";
import {Button, Card, Input, Table} from "antd";
import {PlusCircleTwoTone} from '@ant-design/icons';
import {ColumnsType} from "antd/es/table";
import React from "react";

interface P {
    title: string,
    config?: Detail[],
    onChange(newConfigs: Detail[]): void
}

export default function ({title, config, onChange} : P) {

    /**
     * 获取当前时间的部分，作为选项的唯一值。
     */
    function generateCurrentDateNum(): number {
        let dateStr = String(Date.now());
        return Number(dateStr.substring(2, dateStr.length - 1));
    }

    const tableHeader: ColumnsType<Detail> = [
        {
            title:"制造商",
            key: 'manufacturer',
            dataIndex: 'manufacturer',
            render: (text, record, index) => {
                return (
                    <>
                        <Input
                            defaultValue={text}
                            placeholder={"请输入制造商"}
                            onChange={v => {
                                let newLine = {
                                    ...record,
                                    manufacturer: v.target.value
                                }
                                let newConfig = [...config!];
                                newConfig[index] = newLine;
                                onChange(newConfig)
                            }}
                        />
                    </>
                )
            }
        },
        {
            title: "生产批号",
            key: "productionBatchNumStr",
            dataIndex: "productionBatchNumStr",
            render: (text, record, index) => {
                return (
                    <>
                        <Input
                            defaultValue={text}
                            placeholder={"请输入生产批号"}
                            onChange={v => {
                                let newLine = {
                                    ...record,
                                    productionBatchNumStr: v.target.value
                                }
                                let newConfig = [...config!];
                                newConfig[index] = newLine;
                                onChange(newConfig)
                            }}
                        />
                    </>
                )
            }
        },
        {
            title:"生产日期",
            key: "productionDateStr",
            dataIndex: "productionDateStr",
            render: (text, record, index) => {
                return (
                    <>
                        <Input
                            defaultValue={text}
                            placeholder={"请输入生产日期"}
                            onChange={v => {
                                let newLine = {
                                    ...record,
                                    productionDateStr: v.target.value
                                }
                                let newConfig = [...config!];
                                newConfig[index] = newLine;
                                onChange(newConfig)
                            }}
                        />
                    </>
                )
            }
        },
        {
            title: "过期日期",
            key: "expirationDateStr",
            dataIndex: "expirationDateStr",
            render: (text, record, index) => {
                return (
                    <>
                        <Input
                            defaultValue={text}
                            placeholder={"请输入过期日期"}
                            onChange={v => {
                                let newLine = {
                                    ...record,
                                    expirationDateStr: v.target.value
                                }
                                let newConfig = [...config!];
                                newConfig[index] = newLine;
                                onChange(newConfig)
                            }}
                        />
                    </>
                )
            }
        },
        {
            title: "操作",
            render: (_, record, index) => {
                return (
                    <>
                        <Button
                            danger
                            type={"link"}
                            onClick={() => {
                                let newConfig = config!.filter((item, arrayIndex) => index !== arrayIndex)
                                onChange(newConfig)
                            }}
                        >删除</Button>
                    </>
                )
            }
        }
    ]

    return (
        <>
            <Card
                style={{
                    marginTop: "10px",
                }}
                title={title}
                extra={
                    <>
                        <PlusCircleTwoTone
                            twoToneColor={"#52c41a"}
                            style={{
                                fontSize: "20px"
                            }}
                            onClick={() => {
                                let newConfigs:Detail[] = [];
                                if (config !== undefined) {
                                    newConfigs.push(...config);
                                }
                                newConfigs.push({
                                    ...InitDetail(),
                                    num: generateCurrentDateNum(),//配置项中数值控制随机整数控制唯一
                                });
                                onChange(newConfigs)
                            }}
                        />
                    </>
                }
                >
                <Table
                    rowKey={'num'}
                    columns={tableHeader}
                    dataSource={config}
                    pagination={false}
                />
            </Card>

        </>
    )
}